import React from 'react';
import {
  Grid,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '20px',
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid #999',
    marginBottom: '2em',
  },

  tab: {
    '&:focus': {
      outline: 'none',
    },
  },
});

export default function ReportsNavbar({ changeToTab, activeTab }) {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Tabs
          style={{ marginLeft: '20px' }}
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          orientation={matchesXs ? 'vertical' : 'horizontal'}
        >
          <Tab
            className={classes.tab}
            label="Usage overview"
            onClick={() => changeToTab('UsageOverview')}
          />

          <Tab
            className={classes.tab}
            label="Readings"
            onClick={() => changeToTab('Readings')}
          />

          {/* <Tab
            label="Per appliances"
            onClick={() => changeToTab('PerAppliances')}
          /> */}

          {/* <Tab
            label="Custom reports"
            onClick={() => changeToTab('CustomReports')}
          /> */}
        </Tabs>
      </Grid>
    </>
  );
}

// this will be the landing page for unauthenticated users
// test VISA card is 4242 4242 4242 4242

// hooks
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

// components
import { Grid, Paper, Box } from '@material-ui/core';
import LoginScreen from '../containers/LoginScreen';
import PublicLandingHeader from '../components/PublicLandingHeader';
import PublicLandingPaymentSuccess from '../components/PublicLandingPaymentSuccess';
import PublicLandingPaymentFailure from '../components/PublicLandingPaymentFailure';
import PublicLandingFooter from './PublicLandingFooter';
import { PublicLandingText as Text } from './shared/typography/PublicLandingText';
import Stripe from './Stripe';
import PublicLandingComputerScreen from './PublicLandingComputerScreen';
import Slideshow from './shared/image_components/Slideshow';
import WavyBackground from './shared/fun_backgrounds/WavyBackground';

// images
import MeterVibeDeviceImg from '../assets/images/metervibe_device_transparent.png';
import MeterVibeLogoImg from '../assets/images/MeterVibe_logo_1444x596.png';    
import WaterfallImg from '../assets/images/waterfall.jpeg';
import MeterImg1 from '../assets/images/meter_1.png';
import MeterImg2 from '../assets/images/meter_2.png';
import MeterImg3 from '../assets/images/meter_3.png';
import MeterImg4 from '../assets/images/meter_4.png';

// utils
import { makeStyles } from '@material-ui/core/styles';
import MeterVibeButton from './shared/buttons/MeterVibeButton';
import 'react-slideshow-image/dist/styles.css';

/* colors from app.css
:root {
  --amplify-primary-color:#015388;  //dark blue
  --amplify-primary-tint:#0272bc;    //medium blue
  --amplify-primary-shade: #3d93cc;  //light blue
}
*/

const PRIMARY_TEXT_COLOR = '#2969B1';

// font-size that scale according to screen size
const LOUD_VOICE_FONT_SIZE = 'clamp(24px,4vw, 40px)';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  mediumBluePaper: {
    backgroundColor: '#0272bc',
  },
  whitePaper: {
    backgroundColor: '#FFFFFF',
  },
  sweetAiranPaper: {
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%)',
    flexGrow: 1,
  },

  pageContent: {
    flexGrow: 1,
  },

  customBorderRadius: {
    borderRadius: 25,
  },

  innerColumn: {
    width: '98%',
    maxWidth: '1100px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',
  },

  pageSection: {
    scrollMarginTop: '2em',
  },

  welcome: {
    scrollMarginTop: '2em',
    minHeight: '75vh',
    width: '98%',
    maxWidth: '1100px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',

    [theme.breakpoints.down('md')]: {
      // show more of first section in medium screens
      minHeight: '80vh',
    },

    [theme.breakpoints.down('sm')]: {
      // show more of first section in phone queries
      minHeight: '85vh',
    },

    [theme.breakpoints.down('xs')]: {
      // show more of first section in phone queries
      minHeight: '89vh',
    },
  },

  pageBreak: {
    flexGrow: 1,
    padding: '20px',
  },

  listMain: {
    color: PRIMARY_TEXT_COLOR,
    fontSize: '18px',
    lineHeight: '25px',
    padding: '5px 20px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '30px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  listAlt: {
    color: '#fff',
    fontSize: '1.2rem',
    lineHeight: '50px',
  },
  installationInfoList: {
    color: PRIMARY_TEXT_COLOR,
    fontSize: '18px',
    lineHeight: '40px',
    margin: '0 5px',
    padding: '0 20px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  deviceImage: {
    width: '100%',
    margin: '10px auto',
    padding: '10px',

    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  sectionImage: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
}));

export default function PublicLanding() {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const history = useHistory();

  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    console.log("pathname:", pathname);
    //for now, if user cancels during payment (back) :{currentURL: "/payment_cancel"} we don't have a seperate page, just go back to /, PublicLanding
    if (pathname === '/payment_cancel') history.push('/');
    // check for this everytime pathname changes.
  }, [pathname, history]);

  // switching to if because we might have an else-if for /payment_failure.
  if (pathname === '/payment_success') {
    return (
      <>
        <PublicLandingHeader />
        <Paper className={classes.sweetAiranPaper}>
          <main className={classes.pageContent}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.innerColumn}>
              <Grid item xs={6}>
                <div style={{ marginTop: 80 }}>
                  <PublicLandingPaymentSuccess />
                </div>
                <Grid item container justifyContent="center">
                  <Box my={4}>
                    <MeterVibeButton
                      onClick={() => history.push('/')}
                      text="Go Back"
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <LoginScreen allowSignUp={false} />
              </Grid>
            </Grid>
          </main>
        </Paper>
      </>
    );
  } else if (pathname === '/payment_failure') {
    return (
      <>
        <PublicLandingHeader />
        <Paper className={classes.sweetAiranPaper}>
          <main className={classes.pageContent}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.innerColumn}>
              <Grid item xs={6}>
                <div style={{ marginTop: 80 }}>
                  <PublicLandingPaymentFailure />
                </div>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Box my={4}>
                <MeterVibeButton
                  onClick={() => history.push('/')}
                  text="Go Back"
                />
              </Box>
            </Grid>
          </main>
          <div className={classes.pageContent} />
        </Paper>
      </>
    );
  }

  return (
    <>
      <Paper className={classes.sweetAiranPaper}>
        <PublicLandingHeader />
        <main className={classes.pageContent}>
          {/* welcome section */}
          <Grid
            container
            className={classes.welcome}
            justifyContent={matchesSm ? 'center' : 'space-between'}
            alignItems="center"
            direction="row"
            wrap="wrap">
            <Grid item container direction="column" md={8}>
              <Text
                component="h2"
                fontSize={matchesXS ? '18px' : 'clamp(20px,3vw, 24px)'}
                gutterBottom>
                MeterVibe Water Monitor - Alpha Edition
                <br />
              </Text>

              <Grid item md={12}>
                <Text component="h1" fontSize="clamp(24px, 4vw, 32px)">
                  Whole house water monitoring and leak detection.
                </Text>

                <Text
                  component="h2"
                  fontSize="clamp(22px, 4vw, 28px)"
                  gutterBottom>
                  "We watch your meter so you don't have to."
                  <br />
                  <br />
                </Text>
              </Grid>
              <Text
                component="h3"
                fontSize={matchesXS ? '18px' : 'clamp(20px,3vw, 22px)'}>
                Wifi connected whole house water monitoring
              </Text>
              <ul className={classes.listMain}>
                <li>easy to install, attaches to your existing water meter</li>
                <li>
                  alerts to your phone, find leaky toilets, spot excessive water usage
                </li>
                <li>
                  plan and budget your water use, no more billing surprises
                </li>
              </ul>
            </Grid>

            <Grid item md={4} sm={12}>
              
              <div className={classes.deviceImage}>
                <img
                  src={MeterVibeLogoImg}
                  width="100%"
                  alt="MeterVibe logo"
                  style={{ objectFit: 'contain' }}
                  //aspectRatio= "1444/596"
                />
              </div>
              <div className={classes.deviceImage}>
                <img
                  src={MeterVibeDeviceImg}
                  width="100%"
                  alt="MeterVibe device"
                  style={{ objectFit: 'contain' }}
                />
              </div>
              <Text variant="h3" fontSize="16px" align="center">
                The MeterVibe Alpha
              </Text>
            </Grid>
          </Grid>

          <WavyBackground height={matchesSm ? '250' : '200'} />

          {/* app section */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.pageSection}
            style={{ backgroundColor: PRIMARY_TEXT_COLOR }}>
            <Grid
              container
              item
              alignItems="center"
              justifyContent={matchesSm ? 'center' : 'space-between'}
              className={classes.innerColumn}>
              <Grid item>
                <ul className={classes.listAlt}>
                  <li>Keeps a history of your water use</li>
                  <li>Easy to navigate, water use at your fingertips</li>
                  <li>Easy to compare water use over time</li>
                  <li>Upgrades will include user customized usage alerts</li>
                </ul>
              </Grid>

              <Grid item>
                <PublicLandingComputerScreen />
              </Grid>
            </Grid>
            <div className={classes.pageBreak} />
          </Grid>

          <div className={classes.pageBreak}>
            <Box marginTop={4} />
          </div>

          {/* meters section (used to be customer reviews in UI design) */}
          <div className={classes.pageSection}>
            <Grid
              container
              className={classes.innerColumn}
              alignItems="center"
              justifyContent={matchesMd ? 'center' : 'space-between'}>
              <Grid item>
                <Text
                  component="h1"
                  fontSize={LOUD_VOICE_FONT_SIZE}
                  align="left">
                  Selling "Alpha" devices now!
                </Text>

                <Text component="h2" fontSize="1rem" align="left">
                  Be an early customer and help us perfect the product!
                </Text>
                <br />
                <Text
                  component="h1"
                  fontSize={matchesXS ? '18px' : 'clamp(20px,3vw, 22px)'}>
                  MeterVibe Alpha is easy to install at your house/condo/office
                </Text>

                <ul className={classes.installationInfoList}>
                  <li>
                    Attaches to the Water Meter located inside your building.
                  </li>
                  <li>Uses wifi to communicate with the Cloud.</li>
                  <li>Powers from a standard 110V outlet.</li>
                </ul>
              </Grid>

              <Grid
                item
                className={classes.sectionImage}
                // for a certain specific medium screen breakpoint when resizing.
                style={{ overflow: 'clip' }}>
                <Box marginLeft={!matchesSm ? 2 : 'inherit'}>
                  <Slideshow
                    slideProps={{
                      easing: 'ease',
                      arrows: false,
                      canSwipe: false,
                    }}
                    containerWidth="314px"
                    imgWidth="314px"
                    imgHeight="314px"
                    images={[MeterImg1, MeterImg2, MeterImg3, MeterImg4]}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>

          <div className={classes.pageBreak} />

          <WavyBackground height="180" />
          <Grid
            className={classes.pageSection}
            style={{ backgroundColor: PRIMARY_TEXT_COLOR }}>
            <Grid
              className={classes.innerColumn}
              container
              direction="row"
              justifyContent="space-between">
              <Grid item sm={6}>
                <Text
                  fontSize={LOUD_VOICE_FONT_SIZE}
                  color="white"
                  gutterBottom>
                  Using technology to make a better world
                </Text>

                <Text color="white" fontSize="16px" component="p">
                  water meters are everywhere, but remote monitoring is not. We
                  have created an easy to use, non intrusive way, to stop
                  wasting water. We are only getting started! join us by being
                  an early customer and help us do our part in making the world
                  a better place!
                </Text>
              </Grid>

              <Grid item sm={6} md={4} className={classes.sectionImage}>
                <img width="100%" src={WaterfallImg} alt="waterfall" />
              </Grid>
            </Grid>
            <div className={classes.pageBreak} />
          </Grid>

          <div className={classes.pageBreak}>
            <Box marginTop={12} />
          </div>

          <Grid
            className={classes.pageSection}
            // giving it an id so react-scroll knows where to go
            id="landing-page-purchase-section">
            <div className={classes.innerColumn}>
              <Text
                fontSize={LOUD_VOICE_FONT_SIZE}
                align="center"
                fontWeight={700}>
                Get MeterVibe Today!
              </Text>
              <Stripe />
            </div>
          </Grid>

          <div className={classes.pageBreak} />
        </main>

        <div className={classes.pageBreak}>
          <Box marginTop={4} />
        </div>

        <PublicLandingFooter classes={classes} />
      </Paper>
    </>
  );
}

/*
<Button onClick={()=> postPaymentIntent(amount) }>
          Checkout Button
      </Button>
      */

import moment from 'moment';

export const periodDates = (startPeriodDate, periodType = 'month') => {
  // TODO: do it for all period types, not just monthly.

  const startDateResult = moment(startPeriodDate).startOf(periodType);
  const endDateResult = moment(startPeriodDate).endOf(periodType);

  return [startDateResult, endDateResult];
};

export const addRealMonth = (d) => {
  let futureMonth = moment(d).add(1, 'M');
  let futureMonthEnd = moment(futureMonth).endOf('month');
  return d.date() !== futureMonth.date() &&
    futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))
    ? futureMonth.add(1, 'd')
    : futureMonth;
};

export const nextPeriodDates = (
  currentStartPeriodDate,
  currentEndPeriodDate,
  periodType = 'month'
) => {
  let nextStartPeriodDate;
  let nextEndPeriodDate;

  if (periodType === 'month') {
    nextStartPeriodDate = addRealMonth(currentStartPeriodDate);
    nextEndPeriodDate = moment(nextStartPeriodDate).endOf('month');
  } else {
    nextStartPeriodDate = moment(currentStartPeriodDate).add(1, periodType);
    nextEndPeriodDate = moment(currentEndPeriodDate).add(1, periodType);
  }

  return [nextStartPeriodDate, nextEndPeriodDate];
};

// latestImagesAtBottom of arr.

//use the point to the left and the right and take average delta
//then add in first point, delta to right, and last point delta to left
//ts = [[timestamp, value] ....[timestamp,value]]
export const computeDeltas = (tSeries) => {
  let result = [];
  const millisecsToHours = 1000 * 60 * 60;
  //first entry is (v(n+1) - v(n))/(t(n+1)-t(n))
  result.push([
    tSeries[0][0], //t(0)
    (millisecsToHours * (tSeries[1][1] - tSeries[0][1])) /
      (tSeries[1][0] - tSeries[0][0]), //
  ]);
  for (let idx = 1; idx < tSeries.length - 1; idx++) {
    result.push([
      tSeries[idx][0], //t(idx)
      (millisecsToHours * (tSeries[idx + 1][1] - tSeries[idx - 1][1])) /
        (tSeries[idx + 1][0] - tSeries[idx - 1][0]),
    ]);
  }
  //now the last entry is delta with next to l
  result.push([
    tSeries[tSeries.length - 1][0], //t(0)
    (millisecsToHours *
      (tSeries[tSeries.length - 1][1] - tSeries[tSeries.length - 2][1])) /
      (tSeries[tSeries.length - 1][0] - tSeries[tSeries.length - 2][0]), //
  ]);
  return result;
};

//remove outliers from this sublist
//outlier is great than 2X the first and last quartile
//[[timestamp,value].....[timestamp,value]]
export const removeOutliers = (sublistArray) => {
  const len = sublistArray.length;
  const lowerQuartileIdx = parseInt(len / 4);
  const upperQuartileIdx = parseInt((3 * len) / 4);
  //sort by the second 'value' element
  sublistArray.sort(function (a, b) {
    return a[1] - b[1];
  });
  const lowerBound = 0.5 * sublistArray[lowerQuartileIdx][1];
  const upperBound = 2 * sublistArray[upperQuartileIdx][1];
  // console.log(
  //   'sublist filtering, sublist, len, lowerBound, upperBound:',
  //   sublistArray,
  //   len,
  //   lowerBound,
  //   upperBound
  // );
  //sort back to timestamp order (works without function arg)
  sublistArray.sort(function (a, b) {
    return a[0] - b[0];
  });
  //remove outliers if not in bound range (2X)
  const filtered = sublistArray.filter(function (value, index, arr) {
    return value[1] > lowerBound && value[1] < upperBound;
  });
  return filtered;
};

export const createDeltaProperties = (imgList) => {
  for (let i = 0; i < imgList.length - 1; i++) {
    //if (isNaN(imgList[i].digits)) {  //looks like chart ignore null values
    //  imgList[i].digits = 100; //temporary solution so no Null data
    //}
    imgList[i].deltaDigits = imgList[i].digits - imgList[i + 1].digits;
    imgList[i].deltaTimeMS =
      imgList[i].timestampSaved - imgList[i + 1].timestampSaved;
  }

  return imgList;
};

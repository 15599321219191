import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// https://reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTopOnMount() {
  const { pathname } = useLocation();
  // scroll to top on mount or page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Header from '../components/Header';
import CameraDropdown from '../components/CameraDropdown';
import { Link, useLocation } from 'react-router-dom';
import protectedRoute from '../utils/protectedRoute';

// icons
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinkedCameraIcon from '@material-ui/icons/LinkedCamera';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddBoxIcon from '@material-ui/icons/AddBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ReportsIcon from '@material-ui/icons/Assessment';
import AlertsIcon from '@material-ui/icons/ErrorOutline';
import PersonIcon from '@material-ui/icons/Person';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  iconButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    background: '#015388',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    // color currently defaults to white - commenting this back in makes it dark
    // backgroundColor:
    //   theme.palette.mode === "light"
    //     ? theme.palette.grey[100]
    //     : theme.palette.grey[900],
    flexGrow: 1,
    // height: '100vmax', // this is what makes the page scroll way more down than the content that it has
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    // make it eggshell white in the dashboard page
    background: ({ pathname }) => pathname.includes('/dashboard') && '#fafafa',
  },
  fixedHeight: {
    height: 240,
  },
  boldText: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#01070F',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
}));

const navLinks = [
  {
    title: 'Dashboard',
    Icon: DashboardIcon,
    path: '/dashboard',
  },
  {
    title: 'Activate',
    Icon: AddBoxIcon,
    path: '/activate',
    isAdminLink: true,
  },
  {
    title: 'Device',
    Icon: LinkedCameraIcon,
    path: '/device',
  },
  {
    title: 'Admin',
    Icon: VpnKeyIcon,
    path: '/admin',
    isAdminLink: true,
  },
  {
    title: 'Reports',
    Icon: ReportsIcon,
    path: '/reports',
  },
  {
    title: 'Alerts',
    Icon: AlertsIcon,
    path: '/alerts',
    isAdminLink: true,
  },
  {
    title: 'User Settings',
    Icon: PersonIcon,
    path: '/user-settings',
    isAdminLink: true, // if is admin link, only admin can see this link
  },
];

const customLayoutPaths = [/^\/reports$/, /^\/alerts$/, /^\/user-settings$/];

function MeterVibeAppLayout({ children }) {
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const classes = useStyles({ pathname });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // you can destructure multiple state from store like this
  const { admin, userEmail, cameras, userSelectedCamera } = useSelector(
    ({ userReducer, camerasReducer }) => ({
      admin: userReducer.admin,
      userEmail: userReducer.userEmail,
      cameras: camerasReducer.cameras,
      userSelectedCamera: camerasReducer.userSelectedCamera,
    })
  );

  // can also do this
  // const [admin, userEmail] = useSelector(({ userReducer }) => [
  //   userReducer.admin,
  //   userReducer.userEmail,
  // ]);

  const renderPaperWrapper = useMemo(() => {
    for (let url of customLayoutPaths) {
      // this is to avoid the paper wrapper for components that have extra navbar
      if (url.test(pathname)) return false;
    }
    return true; // render the container and paper wrapper if its not in these paths array
  }, [pathname]);

  return (
    <>
      <Header userEmail={userEmail} />

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={toggleDrawer} className={classes.iconButton}>
            {open ? (
              <ChevronLeftIcon style={{ color: 'white' }} />
            ) : (
              <ChevronRightIcon style={{ color: 'white' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navLinks.map(({ path, Icon, title, isAdminLink }, key) =>
            // if the link is an admin link, only render if user is admin
            isAdminLink ? (
              admin && (
                <DrawerListLink
                  path={path}
                  Icon={Icon}
                  title={title}
                  key={key}
                />
              )
            ) : (
              // else if the link isn't an admin link, just render as it is
              <DrawerListLink path={path} Icon={Icon} title={title} key={key} />
            )
          )}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar
          className={classes.root}
          style={{
            // border: '2px solid black',
            marginTop: '3px',
            background: '#fff',
            boxShadow: '0 4px 2px -2px #999',
            zIndex: 5,
          }}>
          {/* show something else in dashboard page */}
          {!pathname.includes('/dashboard') ? (
            <CameraDropdown
              showMore={pathname === '/reports'}
              cameras={cameras}
              userSelectedCamera={userSelectedCamera}
            />
          ) : (
            <Grid item>
              <Typography className={classes.boldText}>
                Device List ({cameras.length || 0})
              </Typography>
            </Grid>
          )}
        </Toolbar>

        {/* doing this because of the Container component making reports navbar not stick to top */}
        {renderPaperWrapper ? (
          <PaperWrapper classes={classes}>{children}</PaperWrapper>
        ) : (
          children
        )}
      </main>
    </>
  );
}

//  wrap the parent layout with protectedRoute to check if user is authenticated, no need to wrap the children.
export default protectedRoute(MeterVibeAppLayout);

const DrawerListLink = ({ path, title, Icon }) => (
  <ListItem>
    <IconButton component={Link} to={path}>
      <Icon style={{ color: 'white' }} />
    </IconButton>
    <NavLink
      to={path}
      style={{ color: 'white', fontSize: '18px', marginLeft: '8px' }}
      activeStyle={{
        fontWeight: 'bold',
      }}>
      {title}
    </NavLink>
  </ListItem>
);

const PaperWrapper = ({ children, classes }) => (
  <Container maxWidth="lg" className={classes.container}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* <div className={classes.appBarSpacer} /> */}
        {/* page content goes here */}
        <Paper className={classes.paper}>{children}</Paper>
      </Grid>
    </Grid>
  </Container>
);

import React from 'react';
//import { Button} from '@material-ui/core/Button';
import { Box,  Button } from '@material-ui/core';
//import { useNavigate } from "react-router-dom";  need v6

// services
//import { postStripe } from './../../services/stripe.services';

//const PRIMARY_COLOR = '#2969B1';

/*
const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const CheckoutForm = ({ onSuccessfulCheckout }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value
      }
    };

    setProcessingTo(true);

    const cardElement = elements.getElement("card");
    const amount = 5000;

    try {
      const apiName = "dev-iotvid-app"; // in development (dev), backend: -iotvid-app
      const path = `/post/stripe/${amount}` //serverless.yml http path, method: post
      const myInit = {
        body: billingDetails,  //user entered data sent to stripe backend processing
        headers: {},  //optional, not used
      }
      const { data: clientSecret } = await API.post(apiName, path, billingDetails);

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }

      onSuccessfulCheckout();
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  // Learning
  // A common ask/bug that users run into is:
  // How do you change the color of the card element input text?
  // How do you change the font-size of the card element input text?
  // How do you change the placeholder color?
  // The answer to all of the above is to use the `style` option.
  // It's common to hear users confused why the card element appears impervious
  // to all their styles. No matter what classes they add to the parent element
  // nothing within the card element seems to change. The reason for this is that
  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: "#fff",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#87bbfd"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Row>
        <BillingDetailsFields />
      </Row>
      <Row>
        <CardElementContainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementContainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
  {/ * TIP always disable your submit button while processing payments * /}
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? "Processing..." : "Pay $50"}
        </SubmitButton>
      </Row>
    </form>
  );
};

//export default CheckoutForm;

*/

//***********************  THIS IS THE NEW CODE FROM THE EXAMPLE STIPE PAYMENTS USING EXTERNAL STRIPE PAYMENT SERVICE */
//**   1/28/22 This worked in test mode, but could not get it to work in production, so now replacing this with payment url below */

//External payment via stripe, then go to success or failure page via backend
//https://stripe.com/docs/payments/accept-a-payment

/*
export default function StripePayment({ quantity }) {
  const handleClick = async () => {
    // Get Stripe.js instance
    //console.log("in StripePayment quantity", quantity)
    const result = await postStripe(quantity);  //for now quantity is always 1 in the OrderCard.js as provided by stripe.js

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  return (
    <Button color="primary" size="small" role="link" onClick={handleClick}>
      Purchase
    </Button>
  );
}

*/

//********************************************************************************************************************* */

//*************************    NOW TRYING THIS WITH A PAYMENT URL DEFINED IN STRIPE *************************************/

//This calls the url of the payment defined at stripe>payments>payment links, using a product at stripe>product
function StripePayment({ quantity }) {

  const handleClick = async () => {

    //const result = window.location.replace('https://buy.stripe.com/test_7sI02Tg4Y5Akb0keUU');
    //const result = window.location.assign('https://buy.stripe.com/test_7sI02Tg4Y5Akb0keUU', '_blank');
    const result = window.open('https://buy.stripe.com/dR69DA7NZf6f7Mk001', '_blank');  //try opening in a new tab, also could 
    // or <a href="https://www.geeksforgeeks.org" target="_blank">GeeksforGeeks</a>  or target="_top"
    console.log ("window.location.assign:", result);
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  //goto external stripe defined url to purchase MeterVibe with subscription
  //ERROR caused by CLOUDFRONT: checkout-app-init-4f64b720f0ebbe99e72b2bb408efbd61.js:1 Stripe Checkout is not able to run in an iFrame. Please redirect to Checkout at the top level.
  return (

  
      <Box textAlign='center'>
        <Button   variant="contained" 
                onClick={handleClick}
                //href=
                  //"https://buy.stripe.com/9AQdTQb0b9LV6IgeUU"
                //  "https://buy.stripe.com/test_7sI02Tg4Y5Akb0keUU"
                style={{
                  borderRadius: 35,
                  backgroundColor: '#5C76B7',  //PRIMARY_COLOR,
                  padding: "18px 36px",
                  fontSize: "18px",
                  fontWeight: "bold"
              }}>
          Purchase Now
        </Button>
      </Box>
  );
}

export default StripePayment;
import { API } from 'aws-amplify';

/**
 * @method beginCalibration
 * the starts off the calibration of a device
 * First try at this is to add into the device params a turk created rotation
 * This code breaks if a device has not already been selected
 * @param {String} imageURL // the image url to be passed to the params body.
 * @param {String} cameraId //the cameraId to be passed to the params body
 * @returns {Promise<String>} // a success message, for eexample: 'Created a rotation HIT ID:{hitId}, for device:{cameraId}'
 */
export const beginCalibration = async (imageURL, cameraId) => {
  try {
    //console.log ("Begin Calibration, cameraProps.cameraId: ", cameraProps.cameraId, "userSelectedTimestamp", userSelectedTimestamp, " imageURL", imageURL);
    const noSignatureUrl = imageURL.substr(0, imageURL.indexOf('?'));
    //console.log('noSignatureUrl:',noSignatureUrl)
    let params = {
      body: {
        //imageURL : "https://erm2.s3.amazonaws.com/test/1579277254797.jpg"
        image_url: noSignatureUrl,
        cameraId,
      },
    };
    //console.log("parameters:", params)
    const response = await API.post(
      'dev-iotvid-ml',
      `/createhit/rotation`,
      params
    );

    return response;
  } catch (error) {
    throw error;
  }
};

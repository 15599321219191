import { useState } from 'react';
import React from 'react';
import Layout from './stripe/Layout';
import Row from './stripe/prebuilt/Row';
import OrderCard from './OrderCard';

export default function Stripe() {
  const [numDevices, setNumDevices] = useState(1);

  // not needed, we have 2 radio buttons each with a value, we just set numDonuts to the value.
  // const addDonut = () => setNumDonuts((num) => Math.min(2, num + 1)); //max order 2 for beta
  // const remDonut = () => setNumDonuts((num) => Math.max(1, num - 1));

  return (
    <>
      <Layout title="Purchase MeterVibe">
        <Row responsive>
          <OrderCard setNumDevices={setNumDevices} numDevices={numDevices} />
        </Row>
        {/*
            <CheckoutForm
            onSuccessfulCheckout={() => Router.push("/success")}
            />  */}
      </Layout>
    </>
  );
}

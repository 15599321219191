export const USER_ACTIONS = {
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_USER: 'SET_USER',
  SET_ADMIN: 'SET_ADMIN',
};

const { SET_USER, SET_ADMIN, SET_USER_EMAIL } = USER_ACTIONS;

const initialState = {
  user: null,
  userEmail: '',
  admin: false,
};

export default function userReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_EMAIL: {
      return {
        ...state,
        userEmail: payload,
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }

    case SET_ADMIN: {
      return {
        ...state,
        admin: payload,
      };
    }

    default:
      return state;
  }
}

// this is the amplify supplied auth system screens for logging in to (currently cognito)
// and for resetting password, creating new user account
//this file modifies the text of the amplify supplied screens, and places the modal menus in the center of the grid

import React from "react";
import { Grid } from "@material-ui/core"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import GlobalStyles from "./prebuilt/GlobalStyles"
import Head from "next/head";


//const PUBLISHABLE_KEY = 'pk_test_51Ivpq9FWfFgbm17XwWSsGASWYvgoobbQ4pDvAe98VbbhIK96PTpP0zMOyYLJvZOIBIzUmHVNZHmpFnJd04u5vUlk00680ppoSs';
const PUBLISHABLE_KEY ='pk_live_51Ivpq9FWfFgbm17X4uip1W2cH3jZYdpPSzGHma3yzVK34aVNEHEzJx0Lit5rPg0ZMOYJ5wTuHPTn6hmx93rbAUbC00Ww33rfRz';

const stripePromise = loadStripe(PUBLISHABLE_KEY);

const Layout = ({ children, title }) => {
    return (
        <>
        <Grid container>
            <Grid item xs={2} />

                <GlobalStyles />
                <Head>
                    <title>{title}</title>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>
                <Elements stripe={stripePromise}>{children}</Elements>
          
            <Grid item xs={2} />
                          
        </Grid>
        </>
    )
}

export default Layout;
import { API } from 'aws-amplify';

/**
 * @method snap
 * post snap
 * @param {String} cameraId
 * @param {Object<body: {
 * cameraId: string, flash1: number, flash2: number, flash3: number,
 * frameSize: number, vflip: boolean, hflip: boolean,
 * timestamp: Date, winX: number, winY: number, winWidth: number, winHeight: number
 * }>} params // the request params to be passed.
 *
 * @returns {Promise<{cameraId: String, s3Path: String, timestampSaved: Date }>} // the new  snap path, with the cameraId and timestamp
 */
export const postSnap = async (cameraId, params) => {
  try {
    const newSnap = API.post('dev-iotvid-app', `/snap/${cameraId}`, params); //adding in params
    return newSnap;
  } catch (error) {
    throw error;
  }
};

/**
 * @method postSnap2
 * post snap
 * @param {String} cameraId
 * @param {<{body: {
 * cameraId: string, flash1: number, flash2: number, flash3: number,
 * frameSize: number, vflip: boolean, hflip: boolean,
 * timestamp: Date, winX: number, winY: number, winWidth: number, winHeight: number
 * }}>} params // the request params to be passed.
 *
 * @returns {Promise<{cameraId: String, s3Path: String, timestampSaved: Date }>} // the new  snap path, with the cameraId and timestamp
 */
export const postSnap2 = async (cameraId, params) => {
  try {
    const newSnap2 = await API.post(
      'dev-iotvid-app',
      `/snap2/${cameraId}`,
      params
    ); //adding in params
    return newSnap2;
  } catch (error) {
    throw error;
  }
};

// this dropdown contains the result from list-all-images so a user can select
// an older image from the selected camera.  

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ImagesDropdown = (props) => {
  const allImagesList = props.allImagesList; // comes from App.js state
  const classes = useStyles();

  // sets userSelectedTimestamp in App.js so the API call to get-image can be made using that timestamp
  const handleChange = (event) => {
    const timestamp = event.target.value;
    props.setUserSelectedTimestamp(timestamp);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select native onChange={handleChange}>
        {/* <option disabled selected>
          Change Image
        </option> */}
        {/* dropdown items mapped from all images list */}
        {allImagesList.map((image, i) => (
          <option key={i} value={image.timestampSaved}>
            {dayjs(allImagesList[i].timestampSaved).format(
              "dd, MM/DD/YY, hh:mm:ss A"
            )}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default ImagesDropdown;

import { API } from 'aws-amplify';

/**
 * @method apiCameras
 * Calls list-owned-cameras-v2 AP
 * @returns {Promise<Array>} array of cameras to be returned after promise resolved.
 */
export const apiCameras = async () => {
  try {
    const camerasData = await API.get(
      'dev-iotvid-app',
      '/cameras/list/owner-v2'
    );
    return camerasData;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getCameraVersion
 * get camera version for one camera by id
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<Number>} the ocrVersion number to be returned after promise resolved.
 */
export const getCameraVersion = async (cameraId) => {
  try {
    const cameraVersion = await API.get(
      'dev-iotvid-app',
      `/camera/version/${cameraId}/`
    );

    return cameraVersion;
  } catch (error) {
    throw error;
  }
};

/**
 * @method reassignCamera
 * function reassigns a camera to a different user (as opposed to registering a user for the first time)
 * @param {String} cameraToReassign  the camera to be reassign (technically is cameraId)
 * @returns {Promise<String>} the success message, for example: `${cameraToReassign} reassigned successfully!`
 */
export const reassignCamera = async (cameraToReassign) => {
  try {
    const reassignResult = await API.post(
      'dev-iotvid-app',
      `/post/reassign/${cameraToReassign}`
    );

    return reassignResult;
  } catch (error) {
    console.log('Error reassigning camera:', error);
    throw error;
  }
};

/**
 * @method activateCamera
 * this calls handlers/update-camera-activate.main
 * @param {String} aCode  the user entered activation code.
 * @returns {Promise<object | string>} can be a status message like: `${userSubmittedActivationCode} could not be found.` or an object like: { camera: cameraToActivate, body: "reassign"}
 */
export const activateCamera = async (aCode) => {
  try {
    const response = await API.post(
      'dev-iotvid-app',
      `/post/activate/${aCode}`
    ); //, params);

    return response;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getCameraParms
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<Object>}
 */
export const getCameraParams = async (cameraId) => {
  try {
    let cameraParams = await API.get(
      'dev-iotvid-app',
      `/camera/params/${cameraId}`
    );
    return cameraParams;
  } catch (error) {
    throw error;
  }
};

/**
 * @method updateCameraCostPerUnit
 * update the costPerUnit of a device
 * @param {String} cameraId  the cameraId to be passed (required)
 * @param {Number} userInput  the new costPerUnit to be sent to the back-end.
 * @returns {Promise<Number>}  the updated cost per unit.
 */
export const updateCameraCostPerUnit = async (cameraId, userInput) => {
  try {
    const appName = 'dev-iotvid-app';
    const path = `/camera/costPerUnit/${cameraId}`;
    const eventData = {
      body: { costPerUnit: userInput },
    };

    const response = await API.put(appName, path, eventData);

    const updatedCostPerUnit = response.costPerUnit;

    return updatedCostPerUnit;
  } catch (error) {
    throw error;
  }
};

/**
 * @method deleteTimestamp
 * update the costPerUnit of a device
 * @param {String} cameraId  the cameraId to be passed (required)
 * @param {Date} timestampSaved  the date of timestampSaved (unix format)
 * @returns {Promise{status: boolean}}
 */
export const deleteTimestamp = (cameraId, timestampSaved) => {
  let apiName = 'dev-iotvid-app';
  let path = `/images/${cameraId}/${timestampSaved}`; //call delete in iotvid-app
  console.log('deleteTimestamp: apiName, path', apiName, path);
  return API.del(apiName, path);
};

/**
 * @method updateCameraFlash
 * update the costPerUnit of a device
 * @param {String} cameraId  the cameraId to be passed (required)
 * @param {<{flash1: integer, flash2: integer, flash3: integer}>} body  the integers for flash1(L),flash2(R),flash3 (M) to put in dynamoDb, 0(off)..100(full bright)
 * @returns {Promise{status: boolean}}
 */
export const updateCameraFlash = async (cameraId, body) => {
  try {
    if (body.flash1 === undefined || body.flash2 === undefined || body.flash3 === undefined) {
      throw new Error(
        'flash1, flash2, or flash3 not provided in updateCameraFlash (cameras.services.js)'
      );
    }

    let apiName = 'dev-iotvid-app';
    let path = `/put/flash/${cameraId}`;  

    const eventData = {
      body: { flash1: body.flash1, flash2: body.flash2, flash3: body.flash3 },
    };

    return API.put(apiName, path, eventData);
  } catch (error) {
    throw error;
  }
};

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Container,
  makeStyles,
  Grid,
  CircularProgress,
  Typography,
  Box,
} from '@material-ui/core';
import moment from 'moment';

// core components
import ReportsNavbar from '../components/reports_components/ReportsNavbar';
import ReportsFilters from '../components/reports_components/ReportsFilters';
import ReportsChart from '../components/reports_components/ReportsChart';
import ReportsReadingsTable from '../components/reports_components/ReportsReadingsTable';

// services
import { getReportOnlyParams } from './../services/reports.services';
import { getAllImagesListProc } from '../services/images.services';

// utils
import { REPORTS_ACTIONS } from './../reducers/reports.reducer';
import {
  removeOutliers,
  computeDeltas,
  nextPeriodDates,
  periodDates,
  createDeltaProperties,
} from '../utils/Reports.utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  selectTable: {
    // this does nothing
    '&.rt-tr': {
      fontSize: '10px',
      fontWeight: 'bold',
      fontFamily: 'montserrat',
    },
  },
}));

const PageSection = ({ children, marginBottom, gridItemStyles }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" style={{ marginBottom }}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={gridItemStyles}>
          <Paper className={classes.paper}> {children}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const {
  SET_PAGE_STATE,
  SET_BAR_CHART_DATA,
  SET_IS_LOADING,
  SET_CHART_DATA,
  SET_CAMERA_DATA,
  SET_IMAGES_LIST,
  SET_REPORT_INFO,
  SET_START_DATE,
} = REPORTS_ACTIONS;

export default function Reports() {
  const dispatch = useDispatch();

  const [userSelectedCamera, reportsState] = useSelector(
    ({ camerasReducer, reportsReducer }) => [
      camerasReducer.userSelectedCamera,
      reportsReducer,
    ]
  );

  // using a reports reducer to remove some clutter
  const {
    pageState,
    activeTab,
    barChartData,
    isLoading,
    chartData,
    cameraData,
    reportInfo,
    startDate,
    endDate,
    currentPeriodType,
  } = reportsState;

  const setIsLoading = useCallback(
    (payload) => dispatch({ type: SET_IS_LOADING, payload }),
    [dispatch]
  );

  const classes = useStyles();

  // const [flowData, setFlowData] = useState([]); // we're not using flowData for now.

  const [noData, setNoData] = useState(false);

  const handlePeriodicFilter = useCallback(
    (data, startDate, label = 'Gallons', periodType = 'month') => {
      /* 
    data = [
      ['timestamp', 'value'],
      [Sat Jan 09 2021 22:00:22 GMT-0500 (Eastern Standard Time), 740161.93],
      [Sun Jan 10 2021 03:00:22 GMT-0500 (Eastern Standard Time), 740161.93]
    ]
  */
      const result = [['Date/Time', label, 'Deltas']];

      if (periodType === 'all') {
        for (let i = 1; i < data.length; i++) {
          let [currentTimestamp, currentDigits] = data[i];
          currentTimestamp = moment(currentTimestamp);

          if (currentTimestamp.isAfter(moment(endDate))) {
            break;
          }

          if (!currentTimestamp.isBetween(moment(startDate), moment(endDate))) {
            continue;
          }

          const [, previousDigits] = result[result.length - 1];
          const delta = currentDigits - (previousDigits ?? 0);

          result.push([new Date(currentTimestamp), currentDigits, delta]);
        }

        console.log('periodType all result', result);

        // so we get an expected result but for some reason it's not being reflected on the graph
        return result;
      }

      let [startPeriodDate, endPeriodDate] = periodDates(
        moment(startDate),
        periodType
      );

      for (let i = 1; i < data.length; i++) {
        let [currentTimestamp, currentDigits] = data[i];
        currentTimestamp = moment(currentTimestamp);

        // past the end of our date range so return the answer
        if (currentTimestamp.isAfter(moment(endDate))) {
          // return result;
          break;
        }

        // found a date to use in this period
        if (currentTimestamp.isBetween(startPeriodDate, endPeriodDate)) {
          // result.push([new Date(currentTimestamp), currentDigits]);

          const [, previousDigits] = result[result.length - 1];

          const delta = currentDigits - (previousDigits ?? 0);

          result.push([new Date(startPeriodDate), currentDigits, delta]);

          [startPeriodDate, endPeriodDate] = nextPeriodDates(
            startPeriodDate,
            endPeriodDate,
            periodType
          );

          continue;
        }

        // no date date found within our current period, advanced into the next period
        if (currentTimestamp.isAfter(endPeriodDate)) {
          if (i - 1 > 0) {
            const [, prevResultDigits] = result[result.length - 1];

            const delta = currentDigits - (prevResultDigits ?? 0);

            const [, previousDigits] = data[i - 1];
            // result.push(data[i - 1]); // push previous timestamp and digits
            result.push([new Date(startPeriodDate), previousDigits, delta]);
          }

          [startPeriodDate, endPeriodDate] = nextPeriodDates(
            startPeriodDate,
            endPeriodDate,
            periodType
          ); //  next time through loop look for the next period entry
        }
      }

      return result;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, barChartData, currentPeriodType]
  );

  //removes outlier data before diplaying the chart
  const cleanData = useCallback((imagesList) => {
    // console.log('cleaning data', imagesList, 'length list:', imagesList.length);
    let noZerosList = imagesList
      //reverse the order to earliest time to latest
      //also reverses input list imagesList
      .reverse()
      //filter out no digits value, or value=0
      .filter((elt) => 'digits' in elt && parseInt(elt.digits) > 0)
      //replce the digits string with integer
      .map((elt) => [elt.timestampSaved, parseInt(elt.digits)]);

    // if (noZerosList <= 0) {
    //   setNoData(true);
    //   return imagesList;
    // }

    console.log('with no Zeros, length is:', noZerosList.length);
    //group in groups of 20 data points
    let lIdx = 0;
    let cleanedData = [];
    for (let rIdx = 20; rIdx < noZerosList.length - 20; rIdx = rIdx + 20) {
      // console.log('concatenating from/to:', lIdx, rIdx);
      cleanedData = cleanedData.concat(
        removeOutliers(noZerosList.slice(lIdx, rIdx))
      );
      lIdx += 20;
    }
    //do last one with left over elemnts at end >=20 in length
    // console.log('concatenating from/to:', lIdx, noZerosList.length);
    cleanedData = cleanedData.concat(
      removeOutliers(noZerosList.slice(lIdx, noZerosList.length))
    );
    // console.log('cleanedData', cleanedData);

    return cleanedData;
  }, []);

  //user chose a camera, so set state, and also list of images for that camera from server
  //also get and set the report params for this camera
  const handleDeviceSelected = useCallback(async () => {
    setIsLoading(true);
    setNoData(false);

    // const eventKey = event.target.value;
    if (!userSelectedCamera?.cameraId) return;
    const { cameraId } = userSelectedCamera;

    dispatch({ type: SET_CAMERA_DATA, payload: userSelectedCamera });

    //get report params from ddb, and save them to state, and locally for use in this function
    let digitsMultiplier;

    let label;

    getReportOnlyParams(cameraId).then((params) => {
      // console.log('getting params for selected camera:', params);
      dispatch({
        type: SET_REPORT_INFO,
        payload: {
          reportCount: params.costPerUnit,
          reportLabel: params.units,
          reportMult: params.multiplier,
        },
      });
      digitsMultiplier = params.multiplier;

      label = params.units;
    });

    getAllImagesListProc(cameraId).then(async (imgList) => {
      try {
        console.log(
          'before saving off images list, lets modify it with calculations for gpm'
        );

        imgList = createDeltaProperties(imgList);

        console.log('length', imgList.length, imgList);

        dispatch({ type: SET_IMAGES_LIST, payload: imgList });

        console.log('updated to add in deltaDigits and deltaTimeMS', imgList);

        const hasDigitsList = imgList.filter(({ digits }) => Boolean(digits));

        const cleanedData = cleanData(hasDigitsList);

        console.log('cleanedData', cleanedData);
        let chartD = cleanedData.map((elt) => [
          new Date(parseInt(elt[0])),
          parseInt(elt[1]) * digitsMultiplier,
        ]);

        let flowD = computeDeltas(chartD);
        console.log('flowData:', flowD);

        flowD.unshift(['timestamp', 'delta/time']); //add to front of list
        chartD.unshift(['timestamp', 'value']); //add to front of list
        // console.log('imagesList', imagesList);
        // console.log('chartData:', chartD);
        // console.log('flowData:', flowD);
        dispatch({ type: SET_CHART_DATA, payload: chartD }); // the original unfiltered chart data
        // setFlowData(flowD);

        let startDate = chartD[1][0];

        dispatch({
          type: SET_START_DATE,
          payload: moment(startDate).format('YYYY-MM-DD'),
        });

        const result = handlePeriodicFilter(chartD, startDate, label, 'month');

        dispatch({ type: SET_BAR_CHART_DATA, payload: result });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setNoData(true);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedCamera, reportInfo.reportLabel]);

  // Handle transfer between tabs
  const changeToTab = useCallback(
    (tab) => {
      dispatch({ type: SET_PAGE_STATE, payload: tab });
    },
    [dispatch]
  );

  // prevent the re-creation of a function when passed as dep to a useEffect with useCallback
  const onDateFilterChange = useCallback(
    (periodType) => {
      if (!startDate) return;
      let result = handlePeriodicFilter(
        chartData,
        startDate,
        reportInfo.reportLabel,
        periodType
      );
      dispatch({ type: SET_BAR_CHART_DATA, payload: result });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData, startDate, endDate, reportInfo.reportLabel]
  );

  useEffect(() => {
    //  this will also run on mount
    const onDeviceSelected = async () => {
      if (userSelectedCamera?.cameraId) {
        // run this function if there is a device selected when going to this page
        handleDeviceSelected();
      }
    };
    onDeviceSelected();
    /* run this function everytime the user selects a different camera on the dropdown
      no need for a different dropdown component here. */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedCamera]);

  useEffect(() => {
    // filter barchart data by time everytime user changes it
    onDateFilterChange(currentPeriodType);
    // run this everytime these change.
  }, [startDate, endDate, currentPeriodType, onDateFilterChange]);

  if (isLoading) {
    return (
      <>
        <Box marginTop={4} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={2}>
            <Grid item>
              <Typography variant="h4" component="h1">
                Loading...
              </Typography>
            </Grid>
            <Grid item>
              <CircularProgress size={40} />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  if (noData) {
    return (
      <>
        <ReportsNavbar changeToTab={changeToTab} activeTab={activeTab} />
        <Typography style={{ marginLeft: '20px' }} variant="h5">
          No data
        </Typography>
      </>
    );
  }

  return (
    <>
      {/* ****************** Report Top Navigation Bar ****************** */}
      <ReportsNavbar changeToTab={changeToTab} activeTab={activeTab} />

      {/* ****************** Filter Card ****************** */}
      {pageState.showFilter && (
        <Container
          maxWidth="lg"
          className={classes.container}
          style={{ paddingTop: '10px', paddingBottom: '0' }}>
          <Grid item container spacing={3} direction="column" xs={12}>
            <PageSection marginBottom="10px">
              <ReportsFilters cameraId={cameraData.cameraId} />
            </PageSection>
          </Grid>
        </Container>
      )}

      <Container maxWidth="lg" className={classes.container}>
        <Grid item container spacing={3} direction="column" xs={12}>
          {/* ****************** Usage Overview START ****************** */}
          {pageState.showChart && (
            <PageSection>
              {/* Bar chart */}
              <ReportsChart
                unitsLabel={reportInfo.reportLabel}
                data={barChartData}
                chartType="Bar"
                render={cameraData.cameraId !== ''}
              />
            </PageSection>
          )}
        </Grid>

        {/* readings table */}
        {pageState.readingsTabSelected && (
          <PageSection
            gridItemStyles={{
              paddingLeft: 0,
              paddingRight: '20px',
              marginTop: '10px',
            }}>
            {cameraData.cameraId !== '' && <ReportsReadingsTable />}
          </PageSection>
        )}
      </Container>
    </>
  );
}

import React from 'react';
import MeterVibeButton from './MeterVibeButton';

export default function MeterVibeButtonPrimary({
  text,
  textTransform = 'none',
  onClick,
  fontWeight = 700,
  ...rest
}) {
  return (
    <MeterVibeButton
      textTransform={textTransform ?? 'none'}
      text={text}
      onClick={onClick}
      fontWeight={fontWeight}
      background="linear-gradient(90deg, rgba(2,114,188,1) 0%, rgba(1,83,136,1) 100%)"
      border="1px solid #0272BC"
      {...rest}
    />
  );
}

import React, { useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DraggableModal,
} from '../../shared/modal/ModalComponents';

import pluralize from 'pluralize'; // for units label: convert 'gallons' to 'gallon'
import MeterVibeButtonSecondary from '../../shared/buttons/MeterVibeButtonSecondary';
import MeterVibeButtonPrimary from '../../shared/buttons/MeterVibeButtonPrimary';

// right now only contains the input to change costPerUnit
export default function EditCameraModal({ open, onClose, cameraData, onSave }) {
  const { costPerUnit, reportLabel: unitsLabel } = cameraData;

  const [formData, setFormData] = useState({
    costPerUnit,
  });

  const costPerUnitUSD = useMemo(() => {
    return `$${Number(formData.costPerUnit).toFixed(3)}`;
  }, [formData.costPerUnit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: Number(value),
    }));
  };

  const labelSingular = pluralize(unitsLabel, 1); // singularize with pluralize(text, 1)

  return (
    <DraggableModal onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{'Edit Camera'}</DialogTitle>

      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '500px',
          overflowWrap: 'break-word',
          padding: '40px',
        }}>
        <>
          <TextField
            type="number"
            name="costPerUnit"
            onChange={handleChange}
            value={formData.costPerUnit}
            label={`Cost per unit (${costPerUnitUSD}) / ${labelSingular.toLowerCase()}`}
            variant="filled"
          />
        </>
      </DialogContent>

      <DialogActions>
        <MeterVibeButtonSecondary onClick={onClose} text="Close" />

        <MeterVibeButtonPrimary
          onClick={() => onSave(formData.costPerUnit)}
          text="Save"
        />
      </DialogActions>
    </DraggableModal>
  );
}

import React from 'react';
import MeterVibeButton from '../../shared/buttons/MeterVibeButton';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DraggableModal,
} from '../../shared/modal/ModalComponents';

function LeakageDetailsModal({ open, onClose, isLeaking }) {
  return (
    <DraggableModal onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Leakage Details</DialogTitle>

      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '300px',
          overflowWrap: 'break-word',
        }}>
        {isLeaking ? <>leakage Details</> : <>No flow detected</>}
      </DialogContent>

      <DialogActions>
        <MeterVibeButton
          onClick={onClose}
          text="close"
          color="secondary"
          variant="contained"
        />
      </DialogActions>
    </DraggableModal>
  );
}

export default React.memo(LeakageDetailsModal); // only re-render when props change

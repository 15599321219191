// this will be the landing page for unauthenticated users

import React from 'react';
import { PublicLandingText as Text } from './shared/typography/PublicLandingText';
import { Grid } from '@material-ui/core';
const PublicLandingPaymentSuccess = () => {
  return (
    <>
      <title>Thanks for your order!</title>
      <Grid item>
        <Text component="h1" fontSize="2.5rem" align="center">
          Thanks for your order!
        </Text>
      </Grid>
      <Grid item style={{ margin: '0 auto' }} sm={8}>
        <Text component="p" fontSize="14px"> 
          We appreciate your business! If you have any questions, please email
          <a href="mailto:orders@metervibe.com">&nbsp; orders@MeterVibe.com</a>.
          We will send you a confirming email to the email you entered with your purchase.
        </Text>
      </Grid>
    </>
  );
};

export default PublicLandingPaymentSuccess;

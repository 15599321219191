// Device.js shows the most recent snap and lets user take a fresh snap

import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ImagesDropdown from '../components/ImagesDropdown';
import Snap from '../components/Snap';

// services
import {
  getMostRecentImgAPI,
  getAllImagesListAPI,
  getOneImageByTimestamp,
} from '../services/images.services';

import { getCameraVersion } from '../services/cameras.services';

//
//
//
const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  typography: {
    padding: '10px',
    background: '#f5f5f5',
  },
  imageDiv: {
    background: 'black',
    overflow: 'hidden',
    margin: 'auto',

    //  fix console error by checking if height and width are numbers.
    height: ({ subHeight }) => (!isNaN(subHeight / 2) ? subHeight / 2 : 'auto'),
    width: ({ subWidth }) => (!isNaN(subWidth / 2) ? subWidth / 2 : '100%'),
  },

  image: {
    // 100% of parent (imageDiv)
    height: '100%',
    width: '100%',

    // rot passed in props to useStyles
    transform: ({ rot }) => `rotate(${rot}deg)`,
  },
});

const Device = () => {
  const userSelectedCamera = useSelector(
    ({ camerasReducer }) => camerasReducer.userSelectedCamera
  );

  const cameraId = userSelectedCamera?.cameraId;

  const user = useSelector(({ userReducer }) => userReducer.user);

  const subHeight = useMemo(
    () =>
      userSelectedCamera === undefined
        ? 600 * 1.4
        : userSelectedCamera?.subHeight * 1.4,
    [userSelectedCamera]
  ); // check only for this var to change instead of rerendering every time

  const subWidth = useMemo(
    () =>
      userSelectedCamera === undefined
        ? 800 * 1.4
        : userSelectedCamera?.subWidth * 1.4,
    [userSelectedCamera]
  );

  const rot = useMemo(
    () => (userSelectedCamera === undefined ? 0 : userSelectedCamera?.rot4),
    [userSelectedCamera]
  );

  const name = useMemo(
    () =>
      userSelectedCamera === undefined ? ' ' : userSelectedCamera?.cameraName,
    [userSelectedCamera]
  );

  const location = useMemo(
    () =>
      userSelectedCamera === undefined
        ? ' '
        : userSelectedCamera?.cameraLocation,
    [userSelectedCamera]
  );

  const classes = useStyles({ rot, subHeight, subWidth }); // pass props to useStyles

  const [displayImg, setDisplayImg] = useState();
  const [allImagesList, setAllImagesList] = useState();
  const [userSelectedTimestamp, setUserSelectedTimestamp] = useState();
  const [snapped, setSnapped] = useState(false);

  // on mount and on change of selected camera, get most recent image and list of previous images
  useEffect(() => {
    getMostRecentImg(cameraId); //this should also set the userSelectedTimestamp to match this image
    getImgList(cameraId);
    //setDisplayImg(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraId, user]);

  // when user selects a different timestamp, get that image from images/{cameraId}/{timestampSaved}
  // && is an if statement for executing a single line of code
  useEffect(() => {
    userSelectedTimestamp && getImg(cameraId, userSelectedTimestamp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedTimestamp, cameraId]);

  // this is runs when snapped is set to true by the Snap button.
  // data is refreshed in 10 seconds.  snapped is immediately reset to false
  // using if here instead of && because two functions are called, not one
  useEffect(() => {
    if (snapped) {
      setTimeout(() => getMostRecentImg(cameraId), 10000);
      setTimeout(() => getImgList(cameraId), 10000);
    }
    setSnapped(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapped, cameraId]);

  // API call to get list of older images from userSelectedCamera
  const getImgList = async (cameraId) => {
    const imgList = await getAllImagesListAPI(cameraId);
    setAllImagesList(imgList);
  };

  // API call to grab an image based on a specific timestamp
  const getImg = async (cameraId, timestampSaved) => {
    const userSelectedImg = await getOneImageByTimestamp(
      cameraId,
      timestampSaved
    );
    setDisplayImg(userSelectedImg);
  };

  //parse the timestamp, (and could also parse path if needed) from mostRecentImg signedURL
  const parseMostRecentImg = (imgSignedURL) => {
    const imageURL = imgSignedURL.substring(0, imgSignedURL.search('.jpg'));
    console.log('most recent image URL', imageURL);
    const splitResult = imageURL.split('/');
    console.log('splitting:', splitResult);
    const timestamp = splitResult[splitResult.length - 1]; //was going to pass timestamp to calibration, instead pass unsigned URL
    return timestamp;
  };

  // API call to get most recent image from userSelectedCamera (state variable from App.js)
  const getMostRecentImg = async (cameraId) => {
    console.log('cameraId from getMostRecentImg:', cameraId);
    const mostRecentImg = await getMostRecentImgAPI(cameraId);
    console.log('mostRecentImg', mostRecentImg);
    const timestamp = parseMostRecentImg(mostRecentImg); //need the timestamp of the mostRecentImage
    setUserSelectedTimestamp(timestamp); //keep the timestamp in sync with the image displayed
    setDisplayImg(mostRecentImg);
  };

  const consoleLogCameraVersion = async (cameraId) => {
    try {
      let cameraVersion = await getCameraVersion(cameraId);

      console.log(`camera version for ${cameraId} is: ${cameraVersion}`);

      return;
    } catch (error) {
      console.log(`could not get camera version for: ${cameraId}`);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.typography} variant="h4">
          {cameraId || 'None'} :: {name}
        </Typography>
        <Typography className={classes.typography} variant="h5">
          {location}
        </Typography>

        {/* when image comes back from the API call, display it*/}

        {displayImg && (
          // div wraps photo in a rectangular div so rotated images appear more cleanly
          // inherits dimensions from props and divides them in half
          <div className={classes.imageDiv}>
            {/* photo props inherited from props, size divided in half */}
            <img
              src={displayImg}
              className={classes.image}
              alt="Latest Snap Not Available"
            />
          </div>
        )}

        {/* when the list of older images comes back from the API call, render a dropdown with those timestamps*/}
        {allImagesList && (
          <>
            <Typography>Timestamp:</Typography>
            {/* render the dropdown in separate component, passing down props of the image list and function
            to select that timestamp in order to make another API call */}
            <ImagesDropdown
              allImagesList={allImagesList}
              setUserSelectedTimestamp={setUserSelectedTimestamp}
            />
          </>
        )}
        <Snap
          setSnapped={setSnapped}
          userSelectedTimestamp={userSelectedTimestamp}
          //for now sending the signedURL displayImg, and using the signedURL in Turk, later
          // will need to deal with the fact that this signature might expire.
          // imageURL={displayImg && displayImg.substring(0,displayImg.search('.jpg'))}
          imageURL={displayImg}
        />
        <Button onClick={() => consoleLogCameraVersion(cameraId)}>
          Test Button
        </Button>
      </div>
    </>
  );
};

export default Device;

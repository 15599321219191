import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import Activate from './containers/Activate';
import Admin from './containers/Admin';
import Device from './containers/Device';
import NotFound from './components/NotFound';
import PublicLanding from './components/PublicLanding';
import MeterVibeAppLayout from './layouts/MeterVibeAppLayout';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import Reports from './containers/Reports';
import Alerts from './containers/Alerts';
import UserSettings from './containers/UserSettings';

import { useSelector } from 'react-redux';

export default () => {
  const user = useSelector(({ userReducer }) => userReducer.user);

  return (
    <Switch>
      {/* commenting out this <Redirect/> because now after changes everytime going to '/' redirects to dashboard, doesn't seem needed... */}
      {/* <Redirect from="/" to="/dashboard" exact /> */}
      {/* redirects root path to /dashboard so Dashboard NavLink always shows bold in drawer */}

      <Route
        exact
        path={['/', '/payment_success', '/payment_cancel', '/payment_failure']}
        component={PublicLanding}
      />
      <Route exact path="/terms_of_service" component={TermsOfService} />
      <Route exact path="/privacy_policy" component={PrivacyPolicy} />

      {/* wrap the authenticated-section of the app with the app layout */}
      <MeterVibeAppLayout>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/activate" component={Activate} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/device" component={Device} />
        <Route exact path="/alerts" component={Alerts} />
        <Route exact path="/user-settings" component={UserSettings} />

        {/*
      doing this because when the user isn't authenticated the useScript hook in Admin.js will still run
      so I'm blocking this route from existing if we don't have a user
      */}
        {user && <Route exact path="/admin" component={Admin} />}
      </MeterVibeAppLayout>

      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
};

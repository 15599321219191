import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

//for now only allow one sold at a time, quantity limited to "1"
const QuantityRadio = ({ quantity, onChange }) => {
  return (
    <FormControl margin="dense" component="fieldset">
      <FormLabel component="legend">Quantity</FormLabel>
      <RadioGroup
        defaultValue={quantity}
        row
        aria-label="quantity"
        name="deviceQuantity"
        value={quantity}
        onChange={onChange}>
        <FormControlLabel value={1} control={<Radio />} label="1" />
        {/*   <FormControlLabel value={2} control={<Radio />} label="2" />   */}
      </RadioGroup>
    </FormControl>
  );
};

export default QuantityRadio;

import React, { useState } from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles({
  defaultParagraph: {
    fontSize: '14px',
    color: '#363636',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    marginBottom: 0,
  },
});

// leakage system working hours
export default function HoursSection() {
  const classes = useStyles();

  // browser time inputs don't take hh:mm A, they take HH:mm which means 1 PM would be 13:00 (military time)
  const [hours, setHours] = useState({
    min: moment().format('HH:mm'), // left input
    max: moment(Date.now() + 1000 * 60 * 60).format('HH:mm'), // right input
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setHours((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid container direction="column" style={{ padding: '20px' }}>
      <Typography paragraph className={classes.defaultParagraph} gutterBottom>
        Leakage system working hours. This is the time when our system will do
        regular leakage test. You can change this time periods to your
        preference.
      </Typography>

      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <TextField
            name="min"
            type="time"
            value={hours.min}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <TextField
            name="max"
            type="time"
            value={hours.max}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

/**
 * @param {React.FunctionComponent} Component // the component wrapped in the function when exported
 * @param {String} route // the route pushing to on-error (when un-authenticated), defaults to '/' when not provided.
 * @returns {React.FunctionComponent} // the component to render when authenticated
 */
const protectedRoute =
  (Component, route = '/') =>
  (props) => {
    const history = useHistory();
    async function checkAuthState() {
      try {
        // check if user is authenticated
        await Auth.currentAuthenticatedUser();
      } catch (err) {
        // if not authenticated, push to the route (defaults to '/')
        history.push(route);
      }
    }
    useEffect(() => {
      checkAuthState();
      // no dependency array, runs first on mount and then every re-render (acts as both componentDidMount and componentDidUpdate)
    });
    // if is authenticated, return the component as usual.
    return <Component {...props} />;
  };

export default protectedRoute;

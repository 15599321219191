import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import MeterVibeButton from '../../shared/buttons/MeterVibeButton';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DraggableModal,
} from '../../shared/modal/ModalComponents';

function ImageDetailsModal({
  open,
  onClose,
  imageData,
  mostRecentReading,
  label,
  reading,
  title,
}) {
  return (
    <DraggableModal onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{title ?? 'Last Snapshot'}</DialogTitle>

      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '500px',
          overflowWrap: 'break-word',
        }}>
        {mostRecentReading && mostRecentReading !== 'NaN' && (
          <>
            {/* don't show gallons if NaN */}
            <Typography variant="h6" component="h1" gutterBottom>
              Most Recent Reading: {mostRecentReading} {label}
            </Typography>
            <br />
          </>
        )}

        {reading && reading !== 'NaN' && (
          <Typography variant="h6" component="h1" gutterBottom>
            Reading: {reading} {label}
          </Typography>
        )}

        <Tooltip title={title ?? 'Last snapshot'} arrow placement="top">
          <img
            style={{ cursor: 'help' }}
            src={imageData.lastSnapShot}
            alt={title ?? 'Last snapshot'}
          />
        </Tooltip>
      </DialogContent>

      <DialogActions>
        <MeterVibeButton
          onClick={onClose}
          text="close"
          color="secondary"
          variant="contained"
        />
      </DialogActions>
    </DraggableModal>
  );
}

export default React.memo(ImageDetailsModal);

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

// components
import MeterVibeButtonPrimary from '../../shared/buttons/MeterVibeButtonPrimary';
import MeterVibeButtonSecondary from '../../shared/buttons/MeterVibeButtonSecondary';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// icons
import AlertsIcon from '@material-ui/icons/ErrorTwoTone';

const IssueFoundSection = ({
  issueFound,
  closeIssueFound,
  openDetailsModal,
}) => {
  const classes = useStyles();

  const { message, type } = issueFound;

  return (
    <Grid container direction="column" style={{ padding: '20px' }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}>
        <Grid item>
          <AlertsIcon color="error" fontSize="large" />
        </Grid>

        <Grid item container alignItems="flex-start" direction="column" xs={10}>
          <Grid item>
            <Typography className={classes.issueTypeText} component="h2">
              {type}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classes.issueHeaderText} component="h1">
              Potential issue found
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <Typography paragraph className={classes.issueMessageText}>
        {/* message = 'Water has been running for <strong>3 hours</strong>. You have used <strong>240 gallons</strong> of water' */}
        {message}
      </Typography>
      <br />

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <MeterVibeButtonPrimary
            text="View details"
            onClick={() => openDetailsModal(issueFound)}
          />
        </Grid>

        <Grid item>
          <MeterVibeButtonSecondary
            onClick={closeIssueFound}
            text="It's OK, I am aware of that"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  issueTypeText: {
    fontSize: '14px',
    color: '#7A7A7A',
    fontWeight: 700,
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },

  issueHeaderText: {
    fontSize: '18px',
    color: '#363636',
    fontWeight: 700,
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },

  issueMessageText: {
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    fontSize: '14px',
    color: '#363636',
  },

  textBold: {
    fontWeight: 700,
  },
});

export default React.memo(IssueFoundSection);

import React from 'react';
import {
  Grid,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '20px',
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid #999',
    marginBottom: '2em',
  },

  tab: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

export default function AlertsNavbar({ changeToTab, activeTab }) {
  const theme = useTheme();
  const classes = useStyles();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Grid container direction="row" className={classes.container}>
        <Tabs
          style={{ marginLeft: '20px' }}
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={changeToTab}
          orientation={matchesXs ? 'vertical' : 'horizontal'}>
          <Tab
            value="StatusAndAlerts"
            className={classes.tab}
            label="Status and alerts"
          />

          <Tab value="Settings" className={classes.tab} label="Settings" />
        </Tabs>
      </Grid>
    </>
  );
}

import { API } from 'aws-amplify';

/**
 * @method getAllImagesListAPI
 * list all images of camera by providing a cameraId (cameraId needed)
 * @param {String} cameraId the cameraId to be passed
 * @returns {Promise<Array>} the images list array after resolved.
 */
export const getAllImagesListAPI = async (cameraId) => {
  try {
    const imagesData = await API.get(
      'dev-iotvid-app',
      `/images/${cameraId}/list-all`
    );
    return imagesData;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getAllImagesListProc
 * get all processed images for one camera (cameraId needed)
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<Array>} the processed images array after resolved.  List of all
 * parameters for each dynamoDb entry for each snap augmented with signedURL for processed images.  
 * returns a list of image attributes, cameraId, digits, s3Path (original, unrotated), timestampsaved,
 *  and SignedUrl of rotated concatenated image in /proc/{cameraId}/elts/{timestamp}.jpg
 * Currently returning the most recent 900 entries
 */
export const getAllImagesListProc = async (cameraId) => {
  try {
    let imagesData = await API.get(
      'dev-iotvid-app',
      `/images/${cameraId}/list-all-proc`
    );
    //console.log("getAllImagesListProc returns:", imagesData)
    return imagesData;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getMostRecentImgAPI
 * API call for most recent images
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<Array>} the most recent images array after resolved.
 */
export const getMostRecentImgAPI = async (cameraId) => {
  try {
    const mostRecentData = await API.get(
      'dev-iotvid-app',
      `/images/${cameraId}/most-recent`
    );
    return mostRecentData;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getOneImageByTimestamp
 * API call for getting a specific image at userSelectedTimestamp
 * @param {String} cameraId  the cameraId to be passed
 * @param {Date} timestampSaved  the timeStampsaved to help find the specific image. (in unix)
 * @returns {Promise<string>} the one image source string after promise has resolved.
 */
export const getOneImageByTimestamp = async (cameraId, timestampSaved) => {
  try {
    const oneImage = await API.get(
      'dev-iotvid-app',
      `/images/${cameraId}/${timestampSaved}`
    );
    return oneImage;
  } catch (error) {
    throw error;
  }
};

/**
 * @method getOneImageByTimestamp
 * save the user entered digits off in DynamoDb
 * @param {Number} digits  the digits value to be passed
 * @param {String} cameraId the cameraId to be passed
 * @param {Date} timestamp the timestamp in unix (to send to the right path)
 * @returns {Promise<{status: boolean}>} the status if went successfully (true or false)
 */
export const putDigits = async (digits, cameraId, timestamp) => {
  try {
    let apiName = 'dev-iotvid-app';
    let path = `/images/digits/${cameraId}/${timestamp}`; //call update-digits in iotvid-app

    let init = {
      body: { digits: digits },
    };
    console.log('init', init);

    const response = await API.put(apiName, path, init);
    return response;
  } catch (error) {
    throw error;
  }
};

// Currently receiving two different data types from the API.
// EB needs to make final decision on how activation works.ß

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button } from '@material-ui/core';
import ActivateResponse from '../components/ActivateResponse';

// services
import { activateCamera, reassignCamera } from './../services/cameras.services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typography: {
    padding: '10px',
    background: '#f5f5f5',
  },
  textField: {
    padding: '10px',
  },
  button: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Activate = () => {
  const classes = useStyles();
  const [activationCode, setActivationCode] = useState(''); // the user input for activationCode.
  const [activateResponse, setActivateResponse] = useState('');
  const [cameraToReassign, setCameraToReassign] = useState('');
  const [showResult, setShowResult] = useState(false);

  // reassigns camera to currently authenticated user.  called from <ActivateResponse/> component
  const handleReassignCamera = async () => {
    console.log({ cameraToReassign });

    const reassignResult = await reassignCamera(cameraToReassign);
    console.log('response from post/reassign:', reassignResult);
    setActivateResponse(reassignResult);
    setCameraToReassign('');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // prevents form submission from going to another virtual HTML document
    console.log('aCode:', activationCode);

    try {
      const result = await activateCamera(activationCode);
      console.log('response from post/activate:', result);
      setShowResult(true); // on response, show the <ActivateResponse/> component
      // if API returns an object, it's because the camera was already assigned

      // This will need replacing once the API only one data type (needs to be an object)

      if (typeof result === 'object') {
        setCameraToReassign(result.camera);
        setActivateResponse(result.body);
      }
      // if API returns a string, display it
      else {
        setActivateResponse(result);
        setCameraToReassign(''); // resets that variable to make <ActivateResponse/> display properly
      }
    } catch (e) {
      console.log('error setting serial number in Activate.js:', e);
    }
  };

  return (
    <>
      {/* <Paper elevation={3} className={classes.root}> */}
      <div className={classes.root}>
        <Typography variant="h4" className={classes.typography}>
          Enter Activation Code:
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            autoFocus
            placeholder="eg. 54321"
            className={classes.textField}
            variant="outlined"
            size="small"
            onChange={(e) => setActivationCode(e.target.value)}
          />
          <br />
          <br />
          <Button
            variant="contained"
            fullWidth
            type="submit"
            className={classes.button}>
            Submit
          </Button>
        </form>
      </div>
      {/* </Paper> */}
      {showResult && (
        <ActivateResponse
          response={activateResponse}
          camera={cameraToReassign}
          reassignCamera={handleReassignCamera}
          setShowResult={setShowResult}
        />
      )}
    </>
  );
};

export default Activate;

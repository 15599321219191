import React, { useState, useCallback } from 'react';

// components
import PageSection from '../../shared/containers/PageSection';
import IssueFoundSection from './IssueFoundSection';

// utils
import AlertsSection from './AlertsSection';
import AlertDetailsModal from './AlertDetailsModal';

export default function StatusAndAlerts({
  alerts,
  issueFound,
  closeIssueFound,
  handleClearAlerts,
  clearOneAlert,
}) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openDetailsModal = useCallback(
    (alert) => {
      setIsDetailsModalOpen(alert.timestamp);
      setModalData(alert);
    },
    [setIsDetailsModalOpen, setModalData]
  );

  const closeDetailsModal = useCallback(() => {
    setIsDetailsModalOpen(false);
  }, [setIsDetailsModalOpen]);

  const onIssueResolved = useCallback(() => {
    clearOneAlert(modalData);
    closeDetailsModal();
    setModalData(null);
  }, [closeDetailsModal, clearOneAlert, modalData]);

  return (
    <>
      {issueFound && (
        <PageSection>
          <IssueFoundSection
            openDetailsModal={openDetailsModal}
            closeIssueFound={closeIssueFound}
            issueFound={issueFound}
          />
        </PageSection>
      )}

      <PageSection>
        <AlertsSection
          openDetailsModal={openDetailsModal}
          alerts={alerts}
          handleClearAlerts={handleClearAlerts}
        />
      </PageSection>

      <AlertDetailsModal
        label="Gallons"
        onClose={closeDetailsModal}
        imageData={modalData?.recentSnapshot}
        severity={modalData?.severity}
        previousImageData={modalData?.firstSnapshot}
        open={isDetailsModalOpen === modalData?.timestamp}
        message={modalData?.message}
        resolveIssue={onIssueResolved}
      />
    </>
  );
}

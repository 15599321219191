import React from 'react';
import PageWrapper from '../../shared/containers/PageSection';
import { Typography, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

// sections
import CheckboxesSection from './CheckboxesSection';
import HoursSection from './HoursSection';

const useStyles = makeStyles({
  defaultParagraph: {
    fontSize: '14px',
    color: '#363636',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    marginBottom: 0,
  },

  link: {
    color: '#0272BC',
    cursor: 'pointer',
  },

  verticalSpacer: {
    display: 'flex',
    flexGrow: 1,
    padding: '10px',
  },

  separator: {
    backgroundColor: '#DBDBDB',
    height: '1px',
    width: '100%',
  },
});

export default function AlertsSettings() {
  return (
    <PageWrapper>
      {[IntroSection, HoursSection, CheckboxesSection].map(
        (SectionComponent, idx, arr) => (
          <React.Fragment key={idx}>
            <SectionComponent />
            {idx !== arr.length - 1 ? <SectionSeparator /> : null}
          </React.Fragment>
        )
      )}
    </PageWrapper>
  );
}

const SectionSeparator = () => {
  const classes = useStyles();

  return (
    <Grid item container direction="column" style={{ padding: '5px 20px' }}>
      <Grid item className={classes.separator} />
    </Grid>
  );
};

const IntroSection = () => {
  const classes = useStyles();

  return (
    <Grid container style={{ padding: '20px' }}>
      <Typography paragraph className={classes.defaultParagraph} gutterBottom>
        Here you can configure what types of alerts you want to recieve. Each
        type of alert can be send to different channels (Email, Phone, Web
        application). You can change email or mobile phone number in{' '}
        <span className={classes.link}>User settings</span>.
      </Typography>
    </Grid>
  );
};

// CameraDropdown.js holds the list of cameras so the user can select one and set it to state
// JZ - there is a bug that after list of cameras comes back, the first camera (A101) will show in the dropdown
// though that camera is not actually selected.  It should stay with the disabled menu option, but it doesn't.

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CAMERA_ACTIONS } from './../reducers/cameras.reducer';
import { getCameraObject } from './../reducers/cameras.reducer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const { SET_USER_SELECTED_CAMERA } = CAMERA_ACTIONS;

const CameraDropDown = ({ showMore }) => {
  let history = useHistory();
  let { pathname } = useLocation();
  const dispatch = useDispatch();

  const { cameras, userSelectedCamera } = useSelector(
    ({ camerasReducer }) => camerasReducer
  );

  const userSelectedCameraId = userSelectedCamera?.cameraId;

  const classes = useStyles();

  const handleChange = (event) => {
    const selectedCameraId = event.target.value;

    const selectedCameraObject = getCameraObject(cameras, selectedCameraId);

    dispatch({ type: SET_USER_SELECTED_CAMERA, payload: selectedCameraObject });
    // don't push to /device if user is in /reports
    if (pathname === '/reports') return;
    history.push('/device');
  };

  /*
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="label-selected-device">Selected Device</InputLabel>
      <Select id="sel-device" native value={props.userSelectedCamera} onChange={handleChange}>
        <option disabled selected>
          Select Camera
        </option>
        {/* dropdown items mapped from cameras array, then selected camera is stored in state *---/}
        {cameras.map((camera, i) => (
          <option key={i} value={camera.cameraId}>
            {camera.cameraId}
          </option>
        ))}
      </Select>
    </FormControl>
  */

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="device-select-label">Device</InputLabel>
      <Select
        labelId="device-select-label"
        id="sel-device"
        value={userSelectedCameraId || ''}
        onChange={handleChange}
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}>
        {/* dropdown items mapped from cameras array, then selected camera is stored in state */}
        {cameras.map((camera, i) => (
          <MenuItem key={i} value={camera.cameraId}>
            {!showMore ? (
              camera.cameraId
            ) : (
              <>
                {' (' +
                  camera.cameraId +
                  ') ' +
                  "'" +
                  camera.cameraName +
                  "'" +
                  ' located at ' +
                  "'" +
                  camera.cameraLocation +
                  "'"}
              </>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CameraDropDown;

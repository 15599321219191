//from DonutShop.jsx in stripe/prebuilt  revising to make this MaterialUI like

//import React, { useState } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import clsx from 'clsx';
import QuantityRadio from './QuantityRadio';
import CheckoutForm from './stripe/CheckoutForm';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import Collapse from '@material-ui/core/Collapse';
//import IconButton from '@material-ui/core/IconButton';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 345,
    },
    padding: '10px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),

    '&:focus': {
      outline: 'none',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function OrderCard({ numDevices, setNumDevices }) {
  const classes = useStyles();
  //const [expanded, setExpanded] = useState(false);

  //const handleExpandClick = () => {
  //  setExpanded(!expanded);
  //};

  return (
    <Card className={classes.root}>
      {/*<Image src={Logo}  height="54" width="224" alt="MeterVibe"></Image>*/}
      <Typography align="center" style={{color:"#2969B1"}}  variant="h5">
        ALPHA Devices Available
      </Typography>
      <Typography align="center" variant="h6">
        with 12 month Alpha subscription
      </Typography>
      <Typography align="center" variant="subtitle1">
        ---
      </Typography>
      <Typography align="center" variant="subtitle2">
        $198.00 
      </Typography>
      <Typography align="center" variant="subtitle2">MeterVibe device plus one year data subscription
      </Typography>
      <Typography align="center" variant="subtitle1">
        ---
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <QuantityRadio
          quantity={numDevices}
          // set the amount of devices ordering to what the value of the radio button is.
          onChange={(e) => setNumDevices(Number(e.target.value))}
        />
     
      <Typography align="center" variant="body2">
            On purchase: 
            <br/> 1. Your MeterVibe login ID will be the email you enter at time of purchase.
            <br/> 2. Click "PURCHASE" to go to secure external site to complete your purchase.
      </Typography>
      <br/>
      </Grid>

{/*      <CardActions disableSpacing>     */}
        <CheckoutForm quantity={numDevices} />
{/*
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more">
          <ExpandMoreIcon />
        </IconButton>
        */}
 {/*      </CardActions>   */}

      {/*<Collapse in={expanded} timeout="auto" unmountOnExit> */}
        <CardContent>
          <Typography paragraph>FAQ:</Typography>
          <Typography paragraph>
            This purchase is for our introductory MeterVibe Alpha model. Each order contains one device, a USB power block, a USB connector cable, and a 12 month
            subscription.  When placing your order, we ask for your email address which will become your login user name, 
            a phone number, for text alerts if this feature is activated, and contact info to troubleshoot issues, and a ship to address for delivery.
          </Typography>
          <Typography paragraph> 
            The MeterVibe alpha is designed to work inside a building, attached to your meter, and is powered with a standard 110V outlet. 
            The MeterVibe alpha connects to the cloud with available 2.4 Ghz wifi (802.11 b/g/n). 
          </Typography>
          <Typography paragraph>
            This is a new product line and service, so we welcome feedback as we improve our product, and add new features.
          </Typography>
          <Typography paragraph>
            MeterVibe creates a cloud based "Snap" (picture) of your meter.
            These pictures are, with a delay, turned into meter readings. 
            We will track your reading history, and provide you feedback with alerts.
            The visual snapshot of your most
            recent meter reading will be available after a sucessful
            snapshot is taken.
          </Typography>
          <Typography>
            Order now, and enjoy your MeterVibe Alpha edition. 
          </Typography>
        </CardContent>
      {/*</Collapse>*/}
      <CheckoutForm quantity={numDevices} />
    </Card>
  );
}

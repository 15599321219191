import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import config from './config';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider as ReduxStoreProvider } from 'react-redux';
import configureStore from './store'; // index.js

const reduxStore = configureStore();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: 'dev-iotvid-app',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'dev-iotvid-ml',
        endpoint: config.apiGateway.URL_ML,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <ReduxStoreProvider store={reduxStore}>
      <App />
    </ReduxStoreProvider>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();

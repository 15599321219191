import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    // color passed as props, if there is a color prop show the color, else default is blue.
    color: ({ color }) => color ?? '#2969B1',
    fontSize: ({ fontSize }) => fontSize && fontSize,
    fontWeight: ({ fontWeight }) => fontWeight && fontWeight,
    cursor: ({ cursor }) => cursor && cursor,
  },
});

export const PublicLandingText = ({
  color,
  fontSize,
  fontWeight,
  cursor,
  children,
  ...rest
}) => {
  const classes = useStyles({ color, fontSize, fontWeight, cursor });

  return (
    <Typography {...rest} className={classes.root}>
      {children}
    </Typography>
  );
};

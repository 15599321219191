import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MeterVibeButtonSecondary from '../../shared/buttons/MeterVibeButtonSecondary';
import MeterVibeButtonPrimary from '../../shared/buttons/MeterVibeButtonPrimary';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DraggableModal,
} from '../../shared/modal/ModalComponents';
import moment from 'moment';
import 'moment-timezone';
import {
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

const useStyles = makeStyles({
  imageSectionRoot: {
    overflow: 'hidden',
    padding: '20px',
  },

  breakdownSectionRoot: {
    overflow: 'hidden',
    padding: '20px',
  },

  altText: {
    color: '#363636',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
  },

  snapshotImg: {
    width: '100%',
  },

  digitsText: {
    fontSize: '16px',
    color: '#363636',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
    fontWeight: 700,
    marginTop: '10px',
  },

  breakdownTableHead: {
    '& > th': {
      fontWeight: 700,
      color: '#242424',
      padding: '5px 10px',
    },
  },

  breakdownTableRow: {
    ' & > td': {
      color: '#363636',
      padding: '15px 10px',
    },
  },

  buttonsContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  closeBtn: {
    width: '50%',
    '&:focus': {
      outline: 'none',
    },
  },

  resolveBtn: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
});

const SEVERITY_TITLES = {
  info: 'Info',
  danger: 'Potential issue found',
  warning: 'Potential issue found',
  success: 'Success',
};

const SEVERITY_RATINGS = {
  info: 0,
  success: 0,
  warning: 1,
  danger: 2,
};

function AlertDetailsModal({
  open,
  onClose,
  imageData,
  previousImageData,
  label,
  message,
  severity,
  resolveIssue,
}) {
  const currentDigits = imageData?.digits;
  const previousDigits = previousImageData?.digits;

  const classes = useStyles();

  // danger || warning = "Issue Details" : "Info || Success Details"
  const modalTitle =
    SEVERITY_RATINGS[severity] >= 1
      ? 'Issue Details'
      : `${SEVERITY_TITLES[severity]} Details`;

  return (
    <DraggableModal onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>{modalTitle}</DialogTitle>

      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          // width: '700px',
          overflowWrap: 'break-word',
        }}>
        <Grid item container style={{ padding: '20px' }} direction="column">
          <Typography variant="h5" component="h1" gutterBottom>
            {SEVERITY_TITLES[severity]}
          </Typography>
          <Typography paragraph>{message}</Typography>
        </Grid>

        {/* if the severity is warning or danger, show the images, else don't */}
        {SEVERITY_RATINGS[severity] >= 1 && (
          <>
            {previousDigits && (
              <ImageSection
                category="Prior"
                digits={previousDigits}
                label={label}
                imageSource={previousImageData.signedUrl}
                timeStampSaved={previousImageData.timestampSaved}
              />
            )}
            {currentDigits && (
              <ImageSection
                category="Recent"
                digits={currentDigits}
                label={label}
                imageSource={imageData.signedUrl}
                timeStampSaved={imageData.timestampSaved}
              />
            )}

            <BreakdownSection
              label={label}
              digits={currentDigits}
              timestamp={imageData.timestampSaved}
            />
          </>
        )}
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        <MeterVibeButtonSecondary
          className={classes.closeBtn}
          onClick={onClose}
          text="Close"
        />

        <MeterVibeButtonPrimary
          onClick={resolveIssue}
          className={classes.resolveBtn}
          text="The issue is resolved"
        />
      </DialogActions>
    </DraggableModal>
  );
}

//  a section with the image, timestamp and the digits
const ImageSection = ({ imageSource, timeStampSaved, digits, category }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.imageSectionRoot}
      justifyContent="center">
      {/* recent snapshot: 10-20-2021 */}
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.altText} gutterBottom>
          {category} snapshot:{' '}
          {moment(timeStampSaved).format('hh:mmA MM/DD/yyyy')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <img
          className={classes.snapshotImg}
          src={imageSource}
          alt="last snapshot"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h2" className={classes.digitsText}>
          {digits}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BreakdownSection = ({ label, timestamp, digits }) => {
  const classes = useStyles();
  const formattedTime = moment(timestamp).format('hh:mmA MM/DD/yyyy');

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.breakdownSectionRoot}
      justifyContent="center">
      <Table>
        <TableHead>
          <TableRow className={classes.breakdownTableHead}>
            <TableCell>Breakdown per minute</TableCell>
            {[1, 2].map((_emptyCell, key) => (
              <TableCell key={key} />
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow className={classes.breakdownTableRow}>
            {[formattedTime, digits, `0 ${label}`].map((cellText, key) => (
              <TableCell key={key}>{cellText}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default React.memo(AlertDetailsModal);

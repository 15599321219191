export const AUTH_ACTIONS = {
  SET_AUTH_STATE: 'SET_AUTH_STATE',
};

const { SET_AUTH_STATE } = AUTH_ACTIONS;

const initialState = null;

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH_STATE: {
      return payload;
    }

    default:
      return state;
  }
}

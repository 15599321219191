export const API_ACTIONS = {
  SET_AWAITING_API: 'SET_AWAITING_API',
};

const { SET_AWAITING_API } = API_ACTIONS;

const initialState = {
  awaitingAPI: true,
};

/*
  This reducer is for general state,
  ex: loading state, misc booleans, preferences, etc.
*/

export default function generalReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_AWAITING_API: {
      return {
        ...state,
        awaitingAPI: payload,
      };
    }

    default:
      return state;
  }
}

// this will be the landing page for unauthenticated users

import React from 'react';
import { PublicLandingText as Text } from './shared/typography/PublicLandingText';

const PublicLandingPaymentFailure = () => (
  <>
    <title>Something went wrong</title>
    <Text component="h1" fontSize="2.5rem">
      Something went wrong
    </Text>
    <p>
      Something went wrong with your credit card charge, try again or email
      <a href="mailto:orders@metervibe.com">&nbsp; support@MeterVibe.com</a>.
      for further assistance
    </p>
  </>
);

export default PublicLandingPaymentFailure;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
  Grid,
  makeStyles,
  Typography,
  // Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import MeterVibeButtonPrimary from './../shared/buttons/MeterVibeButtonPrimary';
import MeterVibeButtonSecondary from './../shared/buttons/MeterVibeButtonSecondary';

// services
import { deleteTimestamp } from './../../services/cameras.services';

// utils
import { REPORTS_ACTIONS } from './../../reducers/reports.reducer';
import moment from 'moment';
import { addRealMonth } from '../../utils/Reports.utils';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    // all children (buttons) inside button group
    '& > *': {
      fontSize: '12px !important',
      fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','), // fallbacks
      backgroundColor: '#F5F5F5',
      color: '#000',

      // have to do it this way to override Mui defaults.
      borderRight: '1px solid #3D93CC !important',
      borderLeft: '1px solid #3D93CC !important',
      borderTop: '1px solid #3D93CC !important',
      borderBottom: '1px solid #3D93CC !important',
      transition: 'all 250ms ease-in-out',

      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        background: '#fff',
        color: '#3D93CC',
      },
      '&.Mui-selected': {
        // selected button
        background: '#3D93CC',
        color: '#fff',

        '&:hover': {
          background: '#3D93CC',
          color: '#fff',
        },
      },
    },
  },
  sectionTitle: {
    userSelect: 'none',
  },
  updateInfoButton: {
    backgroundColor: 'rgb(2,114,188)',
    background:
      'linear-gradient(90deg, rgba(2,114,188,1) 0%, rgba(1,83,136,1) 100%)',
    '&:focus': {
      outline: 'none',
    },
  },
  titleHidden: {
    visibility: 'hidden',
    userSelect: 'none',
  },
}));

const {
  TOGGLE_SELECT_MODE_ENABLED,
  CLEAR_ALL_SELECTED_ROWS,
  SELECT_ALL_ROWS,
  DELETE_SELECTED_ROWS,
  SET_START_DATE,
  SET_END_DATE,
  SET_PERIOD_TYPE,
  RESET_TIME,
} = REPORTS_ACTIONS;

export default function ReportsFilters({ cameraId }) {
  const [selectButtonsDisabled, setSelectButtonsDisabled] = useState(false);

  const [{ pageState, isSelectModeEnabled, selectedRows }, { admin }] =
    useSelector(({ reportsReducer, userReducer }) => [
      reportsReducer,
      userReducer,
    ]);

  const dispatch = useDispatch();

  const toggleSelectMode = useCallback(() => {
    dispatch({ type: TOGGLE_SELECT_MODE_ENABLED });
  }, [dispatch]);

  const onDeleteClick = async () => {
    setSelectButtonsDisabled(true);

    for await (const timestamp of selectedRows) {
      const result = await deleteTimestamp(cameraId, timestamp);

      if (result.status === true) {
        setTimeout(() => {
          dispatch({ type: DELETE_SELECTED_ROWS }); // delete rows on front end and render new state in UI
        }, 20);
      }
    }

    setSelectButtonsDisabled(false);
  };

  const selectAllItems = useCallback(() => {
    dispatch({ type: SELECT_ALL_ROWS });
  }, [dispatch]);

  const unSelectAllItems = useCallback(() => {
    dispatch({ type: CLEAR_ALL_SELECTED_ROWS });
  }, [dispatch]);

  // don't render if  in readings tab and is not admin (admin can see select buttons in readings page)
  if (pageState.readingsTabSelected && !admin) return null;

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      style={{ padding: '20px' }}>
      {/* first row */}

      <Grid
        item
        container
        xs={12}
        direction="row"
        alignItems="center"
        // spacing={2}
        justifyContent="space-between">
        <Grid
          item
          container
          direction="row"
          xs={pageState.usageOverviewSelectedTab ? 8 : 6}
          spacing={2}>
          {/* don't show date filters if in readings tab */}
          {!pageState.readingsTabSelected && <DateFilters />}
          {/* only show period view buttons when there is a chart. */}
          {pageState.showChart && <PeriodTypeButtons />}
        </Grid>

        {/* enable/disable select mode in readings page */}
        <SelectModeButtons
          render={pageState.readingsTabSelected && admin}
          selectAllItems={selectAllItems}
          unSelectAllItems={unSelectAllItems}
          onDeleteClick={onDeleteClick}
          toggleSelectMode={toggleSelectMode}
          isSelectModeEnabled={isSelectModeEnabled}
          selectButtonsDisabled={selectButtonsDisabled}
        />
      </Grid>
    </Grid>
  );
}

const DateFilters = () => {
  const classes = useStyles();
  const { startDate, endDate, chartData } = useSelector(
    ({ reportsReducer }) => reportsReducer
  );
  const dispatch = useDispatch();

  const minimumDate = moment(chartData[1][0]).format('YYYY-MM-DD') ?? moment();

  return (
    <Grid item>
      <Typography
        variant="body2"
        component="h5"
        gutterBottom
        className={classes.sectionTitle}>
        Select time period
      </Typography>
      <Grid
        item
        container
        direction="row"
        spacing={1}
        alignItems="center"
        wrap="wrap">
        <Grid item xs={12} sm={10} md={6}>
          <input
            value={startDate}
            type="date"
            min={minimumDate}
            onChange={(event) =>
              dispatch({ type: SET_START_DATE, payload: event.target.value })
            }
            style={{ width: '100%', height: '36px' }}
          />
        </Grid>
        <Grid item xs={12} sm={10} md={6}>
          <input
            type="date"
            value={endDate}
            min={minimumDate}
            onChange={(event) =>
              dispatch({ type: SET_END_DATE, payload: event.target.value })
            }
            style={{ width: '100%', height: '36px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SelectModeButtons = ({
  render,
  selectAllItems,
  unSelectAllItems,
  onDeleteClick,
  toggleSelectMode,
  isSelectModeEnabled,
  selectButtonsDisabled,
}) => {
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  if (!render) return null;
  return (
    <Grid item>
      {isSelectModeEnabled ? (
        <>
          <MeterVibeButtonSecondary
            text="Exit Select Mode"
            fontWeight={400}
            textTransform="uppercase"
            disabled={selectButtonsDisabled}
            onClick={toggleSelectMode}
          />
          &nbsp;
          <MeterVibeButtonPrimary
            text={allItemsSelected ? 'UnSelect All' : 'Select All'}
            fontWeight={400}
            textTransform="uppercase"
            disabled={selectButtonsDisabled}
            onClick={() => {
              if (allItemsSelected) {
                unSelectAllItems();
                setAllItemsSelected(false);
              } else {
                selectAllItems();
                setAllItemsSelected(true);
              }
            }}
          />
          &nbsp;
          <MeterVibeButtonPrimary
            onClick={onDeleteClick}
            text="Delete selected"
            disabled={selectButtonsDisabled}
            fontWeight={400}
            textTransform="uppercase"
          />
        </>
      ) : (
        <MeterVibeButtonPrimary
          text="Enter Select Mode"
          fontWeight={400}
          disabled={selectButtonsDisabled}
          textTransform="uppercase"
          onClick={toggleSelectMode}
        />
      )}
    </Grid>
  );
};

const PeriodTypeButtons = () => {
  const { currentPeriodType, startDate } = useSelector(
    ({ reportsReducer }) => reportsReducer
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid item>
      <Typography
        variant="body2"
        component="h5"
        gutterBottom
        className={classes.sectionTitle}>
        Period view
      </Typography>

      <ToggleButtonGroup
        className={classes.buttonGroup}
        name="currentPeriodType"
        value={currentPeriodType}
        onChange={(_e, newValue) => {
          if (newValue === 'all') {
            dispatch({
              type: SET_END_DATE,
              payload: addRealMonth(moment(startDate)).format('YYYY-MM-DD'),
            });
          }

          if (newValue === 'reset') {
            return dispatch({ type: RESET_TIME });
          }

          dispatch({ type: SET_PERIOD_TYPE, payload: newValue });
        }}
        exclusive
        orientation={matchesXs ? 'vertical' : 'horizontal'}>
        <ToggleButton
          className="ToggleButtonStyle"
          style={{ width: '80px', height: '36px' }}
          value="all">
          All
        </ToggleButton>
        <ToggleButton
          className="ToggleButtonStyle"
          style={{ width: '80px', height: '36px' }}
          value="day">
          Daily
        </ToggleButton>
        <ToggleButton
          className="ToggleButtonStyle"
          style={{ width: '80px', height: '36px' }}
          value="week">
          Weekly
        </ToggleButton>
        <ToggleButton
          className="ToggleButtonStyle"
          style={{ width: '80px', height: '36px' }}
          value="month">
          Monthly
        </ToggleButton>

        <ToggleButton
          className="ToggleButtonStyle"
          style={{ width: '80px', height: '36px' }}
          value="reset">
          Reset
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
};

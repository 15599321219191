import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    // padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

export default function PageSection({
  children,
  marginBottom,
  gridItemStyles,
}) {
  const classes = useStyles();
  return (
    <Container
      maxWidth="lg"
      className={classes.container}
      style={{ marginBottom }}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={gridItemStyles}>
          <Paper className={classes.paper}> {children}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

// ActivateResponse.js renders after a user types in their activation code on Activate.js
// Depending on EB plan for activation this may not get used.

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
  },
  buttonDiv: {
    padding: '15px',
    flexDirection: 'row',
    marginTop: '15px',
  },
  button: {
    padding: '10px',
    marginRight: '10px',
  },
}));

//component renders on receipt of API return from update-camera-activate
const ActivateResponse = ({
  response,
  camera,
  reassignCamera,
  setShowResult,
}) => {
  const classes = useStyles();
  // if the camera is already registered, ask user if they'd like to reassign camera
  if (camera) {
    return (
      <>
        {/* <Toolbar /> */}
        {/* <Paper className={classes.root} elevation={3}> */}
        <div className={classes.root}>
          <Typography variant="h6">
            Camera {camera} is currently assigned to another user. Would you
            like to reassign it to your account?
          </Typography>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              onClick={reassignCamera}>
              Yes, Reassign
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              onClick={() => {
                setShowResult(false);
              }}>
              No, Do Not Reassign
            </Button>
          </div>
        </div>
        {/* </Paper> */}
      </>
    );
  }

  // otherwise, return the string from the back end
  return (
    <>
      <Toolbar />
      <Typography className={classes.root}>{response}</Typography>
    </>
  );
};

export default ActivateResponse;

import React from 'react';
import MeterVibeButton from './MeterVibeButton';

export default function MeterVibeButtonSecondary({
  onClick,
  text,
  textTransform = 'none',
  fontWeight = 700,
  ...rest
}) {
  return (
    <MeterVibeButton
      onClick={onClick}
      text={text}
      color="#0272BC"
      textTransform={textTransform}
      fontWeight={fontWeight}
      backgroundColor="#fff"
      border="1px solid #0272BC"
      variant="contained"
      {...rest}
    />
  );
}

// NotFound.js renders at any unnamed path 

import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
  },
  typography: {
    padding: "10px"
  }
});

const NotFound = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        
          <Typography className={classes.typography} variant="h4">Sorry, page not found!</Typography>
          <br />
          <Typography className={classes.typography} variant="h5">
            Please choose an action from the menu to the left.
          </Typography>
     
      </div>
    </>
  );
};

export default NotFound;

// Header component sits at the top of App.js ast all times, holding the logo, user email, and AmplifySignOut button

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Hidden,
  IconButton,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Logo from '../assets/images/LogoTopLeft.png';
import LoginScreen from '../containers/LoginScreen';
import MeterVibeButton from './shared/buttons/MeterVibeButton';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-scroll';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { PublicLandingText as Text } from './shared/typography/PublicLandingText';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { USER_ACTIONS } from './../reducers/user.reducer';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#0272BC',
    zIndex: theme.zIndex.drawer + 1, // sets the zIndex of the AppBar as +1 to the drawer so the AppBar will always sit on top of the drawer
  },
  logo: {
    flexGrow: 5, // similar to the grid system, these properties make the logo take up as much space as possible relative to the email and signout button
    cursor: 'pointer',
  },
  userEmail: {
    flexGrow: 1,
  },
  signInButton: {
    flexGrow: 1,
  },
  buyNowButton: {
    flexGrow: 3,
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  navButton: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 'clamp(12px,3vw,16px)',
    },
  },
  offset: theme.mixins.toolbar,
  closeIcon: {
    color: '#0272BC',

    '&:focus': {
      outline: 'none',
    },
  },
  infoIcon: {
    color: '#0272BC',
  },
}));

// hides navbar when user scrolls down or clicks buy now (to avoid navbar hiding stuff).
const HideOnScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const SignInModal = ({ open, handleClose }) => (
  <>
    <Dialog
      // fullWidth
      onClose={handleClose}
      open={open}
      maxWidth="md">
      <LoginScreen allowSignUp={false} />
    </Dialog>
  </>
);

const { SET_USER_EMAIL } = USER_ACTIONS;

export default function PublicLandingHeader() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const dispatch = useDispatch();

  const userEmail = useSelector(({ userReducer }) => userReducer.userEmail);

  const [showAlert, setShowAlert] = useState(() => {
    const storedState = localStorage.getItem('showAlert');

    if (storedState) {
      return storedState === 'true' ? true : false;
    }
    return true;
  });

  const theme = useTheme();
  const history = useHistory();

  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleCloseAlert = () => {
    localStorage.setItem('showAlert', 'false');
    setShowAlert(false);
  };

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      if (data) {
        // change email when pathname changes (for this navbar only to fix email still showing when signing out)
        dispatch({ type: SET_USER_EMAIL, payload: data.attributes.email });
      } else {
        dispatch({
          type: SET_USER_EMAIL,
          payload: '',
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className={classes.logo} onClick={() => history.push('/')}>
              <img src={Logo} alt="MeterVibe" height="54" width="131" />
            </div>
            {/* <Hidden> MUI component hides its contents on xs screens and below*/}

            <Hidden>
              <div className={classes.buyNowButton}>
                {/* react-scroll link, smooth scrolls to location in page */}
                <Link
                  activeClass="active"
                  to="landing-page-purchase-section"
                  spy={true}
                  smooth={true}
                  duration={1000}>
                  <MeterVibeButton
                    text="BUY NOW"
                    // don't show icon if screen is XS, takes too much space.
                    Icon={matchesXs ? null : CartIcon}
                    className={classes.navButton}
                  />
                </Link>
              </div>
            </Hidden>
            <Hidden smDown>
              <Typography className={classes.userEmail}>
                {/*  if is signed in, show email, else show not signed in */}
                {Auth.currentAuthenticatedUser() ? userEmail : 'Not signed in'}
              </Typography>
            </Hidden>

            <div className={classes.signInButton}>
              <MeterVibeButton
                text="SIGN IN"
                className={classes.navButton}
                onClick={() => {
                  // if is signed in, go to the app.
                  Auth.currentUserInfo().then((data) => {
                    if (data) {
                      return history.push('/dashboard');
                    }
                    // else open the sign in modal
                    setIsSignInOpen(true);
                  });
                }}
              />
            </div>
          </Toolbar>

          {showAlert && (
            <Toolbar
              style={{
                backgroundColor: 'white',
                padding: matchesSm && '10px 10px',
              }}>
              <Grid
                container
                justifyContent="space-between"
                direction="row"
                alignItems="center">
                <Grid
                  item
                  container
                  alignItems={matchesSm ? 'flex-start' : 'center'}
                  direction="row"
                  wrap="nowrap"
                  xs={8}
                  md={9}
                  lg={11}>
                  <Hidden xsDown>
                    <Box marginRight={1}>
                      <InfoIcon fontSize="large" className={classes.infoIcon} />
                    </Box>
                  </Hidden>
                  <Text fontSize="16">
                    Selling "Alpha" devices now! Be an early customer and help
                    us perfect the product!
                  </Text>
                </Grid>

                <Grid item sm={1}>
                  <IconButton
                    onClick={handleCloseAlert}
                    className={classes.closeIcon}>
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </AppBar>
      </HideOnScroll>

      {/* adding offset to remove the problem of hidden elements with position: fixed;
      https://material-ui.com/components/app-bar/#fixed-placement */}
      {/* offset here */}
      <div className={classes.offset} />

      <SignInModal
        open={isSignInOpen}
        classes={classes}
        handleClose={() => setIsSignInOpen(false)}
      />
    </>
  );
}

import React, { useState, useEffect } from 'react';

import {
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  separator: {
    backgroundColor: '#DBDBDB',
    height: '1px',
    width: '100%',
  },
});

export default function CheckboxesSection() {
  const classes = useStyles();

  // this state is for the very left side of each row, it decideds if to enable the right side.
  const [enabledCheckboxes, setEnabledCheckboxes] = useState({
    potentialLeakageAlerts: false,
    dailyWaterUsageAlerts: [false, '15% more than average'],
    dailyWaterUsageReport: false,
    monthlyWaterUsageReport: false,
  });

  const initialCheckboxesState = {
    onEmail: false,
    onMobilePhone: false,
    inWebApplication: false,
  };

  // these states are for the right side of each row
  const [sendPotentialLeakageAlerts, setSendPotentialLeakageAlerts] = useState(
    initialCheckboxesState
  );
  const [sendAlertDaily, setSendAlertDaily] = useState(initialCheckboxesState);
  const [sendDailyWaterUsageReport, setSendDailyWaterUsageReport] = useState(
    initialCheckboxesState
  );
  const [sendMonthlyWaterUsageReport, setSendMonthlyWaterUsageReport] =
    useState(initialCheckboxesState);

  const toggleEnabledCheckboxes = (e) => {
    const { name, value } = e.target;

    // the left side of daily water usage has 2 things, so it's an array in the state, 0 is a boolean and 1 is the avg value
    if (name === 'dailyWaterUsageAlerts.bool') {
      setEnabledCheckboxes((prevState) => ({
        ...prevState,
        dailyWaterUsageAlerts: [
          !prevState.dailyWaterUsageAlerts[0],
          prevState.dailyWaterUsageAlerts[1],
        ],
      }));

      return;
    }

    if (name === 'dailyWaterUsageAlerts.average') {
      setEnabledCheckboxes((prevState) => ({
        ...prevState,
        dailyWaterUsageAlerts: [prevState.dailyWaterUsageAlerts[0], value],
      }));

      return;
    }

    // toggle the boolean
    setEnabledCheckboxes((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleSubmit = async () => {
    let reqBody = {
      potentialLeakageAlerts: {
        // do the same thing for the other sections
        send: enabledCheckboxes.potentialLeakageAlerts, // back-end should prioritize send, if other values are true but send is false, just don't send
        ...sendPotentialLeakageAlerts, // onEmail, onMobilePhone, inWebApplication
      },

      dailyAlerts: {
        send: enabledCheckboxes.dailyWaterUsageAlerts[0],
        average: enabledCheckboxes.dailyWaterUsageAlerts[1],
        ...sendAlertDaily, // onEmail, onMobilePhone, inWebApplication
      },
    };

    console.log({ reqBody });
    return;
  };

  useEffect(() => {
    window._testSubmit = () => handleSubmit(); // test handleSubmit
  });

  return (
    <Grid container direction="column" style={{ padding: '10px 20px' }}>
      {/* potential leakage alerts */}
      <CheckboxRow
        state={sendPotentialLeakageAlerts}
        setState={setSendPotentialLeakageAlerts}
        enabledCheckboxes={enabledCheckboxes}
        stateName="potentialLeakageAlerts"
        onChange={toggleEnabledCheckboxes}
        label="Send potential leakage alerts"
      />

      <div className={classes.separator} />

      {/* Daily Water Alerts */}
      <DailyWaterAlertsRow
        enabledCheckboxes={enabledCheckboxes}
        onChange={toggleEnabledCheckboxes}
        sendAlertDaily={sendAlertDaily}
        setSendAlertDaily={setSendAlertDaily}
      />
      <div className={classes.separator} />
      {/* End of daily water alerts */}

      {/* Send daily water usage report */}
      <CheckboxRow
        marginTop={'10px'}
        state={sendDailyWaterUsageReport}
        setState={setSendDailyWaterUsageReport}
        enabledCheckboxes={enabledCheckboxes}
        stateName="dailyWaterUsageReport"
        onChange={toggleEnabledCheckboxes}
        label="Send daily water usage report"
      />

      <div className={classes.separator} />
      {/* End of daily usage report */}

      {/* Send monthly water usage report */}
      <CheckboxRow
        marginTop={'10px'}
        state={sendMonthlyWaterUsageReport}
        setState={setSendMonthlyWaterUsageReport}
        enabledCheckboxes={enabledCheckboxes}
        stateName="monthlyWaterUsageReport"
        onChange={toggleEnabledCheckboxes}
        label="Send monthly water usage report"
      />
      {/* end of Send monthly water usage report */}
    </Grid>
  );
}

const checkboxAliases = {
  onEmail: 'On email',
  onMobilePhone: 'On mobile phone',
  inWebApplication: 'In Web Application',
};

// the checkbox group at the right side of every row
const CheckboxGroup = ({ state, setState, disabled }) => (
  <FormGroup row style={{ justifyContent: 'flex-end' }}>
    {['onEmail', 'onMobilePhone', 'inWebApplication'].map((name, key) => {
      const labelText = checkboxAliases[name];

      return (
        <FormControlLabel
          key={key}
          name={name}
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              onChange={() =>
                setState((prevState) => ({
                  ...prevState,
                  [name]: !prevState[name],
                }))
              }
              checked={state[name]}
            />
          }
          label={labelText}
        />
      );
    })}
  </FormGroup>
);

const CheckboxRow = ({
  state,
  setState,
  stateName,
  onChange,
  enabledCheckboxes,
  label,
  marginTop,
}) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    style={{ marginTop: marginTop ?? 'inherit' }}
    justifyContent="space-between">
    <FormGroup>
      <FormControlLabel
        name={stateName}
        control={
          <Checkbox
            color="primary"
            onChange={onChange}
            checked={enabledCheckboxes[stateName]}
          />
        }
        label={label}
      />
    </FormGroup>

    {/* CheckboxGroup contains the 'On email', 'On Mobile Phone', 'In Web Application' checkboxes  */}
    <CheckboxGroup
      state={state}
      setState={setState}
      disabled={!enabledCheckboxes[stateName]}
    />
  </Grid>
);

const DailyWaterAlertsRow = ({
  enabledCheckboxes,
  onChange,
  sendAlertDaily,
  setSendAlertDaily,
}) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    style={{ marginTop: '10px' }}>
    <Grid item xs={7} container alignItems="center">
      <Grid item>
        <FormControlLabel
          name="dailyWaterUsageAlerts.bool"
          control={
            <Checkbox
              color="primary"
              onChange={onChange}
              checked={enabledCheckboxes.dailyWaterUsageAlerts[0]}
            />
          }
          label="Send alert if daily water usage is"
        />
      </Grid>

      <Grid item>
        <Select
          name="dailyWaterUsageAlerts.average"
          value={enabledCheckboxes.dailyWaterUsageAlerts[1]}
          onChange={onChange}
          disabled={!enabledCheckboxes.dailyWaterUsageAlerts[0]}>
          {['15% more than average', '35% more than average'].map(
            (value, key) => (
              <MenuItem value={value} key={key}>
                {value}
              </MenuItem>
            )
          )}
        </Select>
      </Grid>
    </Grid>

    <Grid item container xs={5} justifyContent="flex-end">
      <CheckboxGroup
        state={sendAlertDaily}
        setState={setSendAlertDaily}
        disabled={!enabledCheckboxes.dailyWaterUsageAlerts[0]}
      />
    </Grid>
  </Grid>
);

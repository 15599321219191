// Renders on Dashboard when user has no cameras registered

import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Button";

const NoCameras = () => {
  return (
    <Card>
      <Typography>
        You have no cameras! Visit the setup page to get started.
      </Typography>
      <Link component="button" href="/setup">
       Setup Page
      </Link>
    </Card>
  );
};

export default NoCameras;

/* this is for when we get units label from api
  they come as "CUBIC FEET", should be "Cubic Feet"
   and "gallons" should be "Gallons"  */

/**
 * @method toTitleCase
 * @param {string} string
 * @returns {String} title-cased string
 */
export const toTitleCase = (string) =>
  string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

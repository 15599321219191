import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function Slideshow({
  images,
  slideProps,
  containerWidth,
  imgWidth,
  imgHeight,
  ...rest
}) {
  // accepts an array of images, no objects, just strings with values of path.
  return (
    <div
      style={{
        overflow: 'hidden',
        position: 'relative',
        width: containerWidth,
      }}>
      <Slide {...slideProps}>
        {images.map((imgSrc, key) => (
          <div key={key}>
            <div
              style={{
                alignItems: 'center',
                justify: 'center',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: '100% 90%',
                height: imgHeight,
                width: imgWidth,
                backgroundRepeat: 'no-repeat',
              }}
              {...rest}
            />
          </div>
        ))}
      </Slide>
    </div>
  );
}

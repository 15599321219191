import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTable, usePagination } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';

// components
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import ImageDetailsModal from '../../components/modals/camera_modals/ImageDetailsModal';

// utils/services
import { putDigits } from './../../services/images.services';
import moment from 'moment';
import 'moment-timezone';
import { REPORTS_ACTIONS } from './../../reducers/reports.reducer';

// icons
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tableContainer: {
    maxHeight: 500,
  },
  tableFooterRow: {
    display: 'flex',
  },
  paginationSpacer: {
    flex: '1 1 100%',
    [theme.breakpoints.down('md')]: {
      flex: '0 0',
    },
  },
  paginationActions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      flexShrink: 1, // direction buttons in a column in small screen
    },
  },
}));

const Linebreak = ({ value }) => {
  // add in the style to \n containing string to have multiple lines in cell
  return <span className="display-linebreak">{value}</span>;
};

// Create an editable cell renderer
//https://stackoverflow.com/questions/57419197/react-table-with-hooks-looses-focus-on-input-inside-table
// this component is the cell for editing the digits (admin only)
const EditDigitsCell = memo(
  ({
    value: initialValue,
    row,
    column,
    handleCellInputChange, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = async () => {
      await putDigits(
        value,
        row.original.cameraId,
        row.original.timestampSaved
      ); // update data in db when input is blurred

      handleCellInputChange(row, column, value); // rerender the page with the new state.
    };

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  }
);

const { ROW_SELECTED, CLEAR_ALL_SELECTED_ROWS, SET_READINGS_DATA } =
  REPORTS_ACTIONS;

// TABLE
export default function ReportsReadingsTable() {
  const classes = useStyles();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [
    {
      imagesList,
      startDate,
      endDate,
      selectedRows,
      isSelectModeEnabled,
      readingsData,
      pageState,
      reportInfo,
      isLoading,
      userSelectedCamera,
    },
    { admin },
  ] = useSelector(({ reportsReducer, userReducer }) => [
    reportsReducer,
    userReducer,
  ]);

  useEffect(() => {
    // clear all selected rows when select mode is disabled (kinda like how gmail clears all selected rows when disabling).
    if (!isSelectModeEnabled) {
      dispatch({ type: CLEAR_ALL_SELECTED_ROWS });
    }
  }, [isSelectModeEnabled, dispatch]);

  const showImageModal = useCallback(
    (timestampSaved) => setIsImageModalOpen(timestampSaved),
    [setIsImageModalOpen]
  );
  const closeImageModal = useCallback(
    () => setIsImageModalOpen(false),
    [setIsImageModalOpen]
  );

  // When our cell renderer calls handleCellInputChange, we'll use
  // the rowIndex, columnID and new value to update the
  // original data
  const handleCellInputChange = useCallback(
    (row, column, value) => {
      const newReadingsData = [...readingsData].map((item, index) => {
        if (index === row.index) {
          return {
            ...readingsData[row.index],
            [column.id]: value,
          };
        }
        return item;
      });

      dispatch({ type: SET_READINGS_DATA, payload: newReadingsData });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readingsData]
  );

  useEffect(() => {
    // sorted imagesList that is filterable by date for readings table.

    const filteredReadingsData = imagesList
      .filter(({ timestampSaved }) => {
        let dateToCheck = moment(timestampSaved).format('YYYY-MM-DD');
        return moment(dateToCheck).isBetween(
          startDate,
          endDate,
          null, // can be year, month .... the granularity of your comaprison
          '[]' // inclusive in range
        );
      })
      .sort((a, b) => {
        // latest date first.
        // Sort the dates in descending order:
        return b.timestampSaved - a.timestampSaved;
      });

    dispatch({ type: SET_READINGS_DATA, payload: filteredReadingsData });

    // do it everytime these vars change.
  }, [startDate, endDate, imagesList, pageState, userSelectedCamera]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRow = useCallback(
    (rowInfo) => {
      if (!isSelectModeEnabled) return;

      dispatch({ type: ROW_SELECTED, payload: rowInfo });
    },
    [isSelectModeEnabled, dispatch]
  );

  const rowFn = (rowInfo) => {
    return {
      onClick: (e, handleOriginal) => {
        console.log('It was in this row:', rowInfo);

        if (admin) {
          // only select row if user is an admin
          handleSelectRow(rowInfo);
        }
        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.

        // if (handleOriginal) {
        //   handleOriginal();
        // }
      },
    };
  };

  const columns = useMemo(
    () => [
      //  {
      //    Header: "Id",
      //    accessor: "cameraId", // String-based value accessors!
      //    width: 70
      //  },
      {
        Header: 'Date',
        width: 200,
        id: 'time',
        accessor: ({ timestampSaved }) =>
          moment(timestampSaved).format('hh:mm A MM/DD/YYYY'), //this gets the date formated

        Cell: (row) => <Linebreak value={row.value} />, //format as 2 lines using \n
      },
      {
        Header: 'Image',
        width: 280,
        Cell: ({ row }) => {
          let reading = (
            Number(row.original.digits) * Number(reportInfo.reportMult)
          ).toFixed(Math.max(0, -Math.log10(reportInfo.reportMult))); //set number of decimal places

          reading = Number(reading).toLocaleString(); // add commas with to localeString()

          return (
            <>
              <Tooltip arrow title="View more" placement="top">
                <div>
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => showImageModal(row.original.timestampSaved)}
                    height={40}
                    alt="SignedUrl"
                    src={row.original.SignedUrl}
                  />
                </div>
              </Tooltip>
              <ImageDetailsModal
                open={isImageModalOpen === row.original.timestampSaved}
                onClose={closeImageModal}
                reading={reading}
                label={reportInfo.reportLabel}
                title={`Snapshot: ${moment(row.original.timestampSaved).format(
                  'hh:mm A MM/DD/yyyy'
                )}`}
                imageData={{
                  lastSnapShot: row.original.SignedUrl,
                }}
              />
            </>
          );
        },
        id: 'Image',
      },
      {
        Header: 'Digits', //props => <span>Friend Age</span>, // Custom header components!
        width: 100,
        accessor: 'digits',
        Cell: ({ cell, handleCellInputChange, data }) => {
          // makes this editable (renderEditable)
          const { row, column } = cell;
          const cellValue = data[row?.index][column?.id] || ''; // or could use undefined

          return admin ? (
            <EditDigitsCell
              value={cellValue}
              row={row}
              column={column}
              cell={cell}
              handleCellInputChange={handleCellInputChange}
            />
          ) : (
            <span>{cellValue}</span>
          );
        }, //allow user to edit this cell
      },
      {
        // Gallons
        Header: `${reportInfo.reportLabel}`, // Gallons
        width: 100,
        id: 'reading',
        //accessor: d => new Date(d.timestampSaved).toDateString()
        //"timestampSaved",
        accessor: (d) => {
          // d.digits comes back as a string in response...
          let gallons = (
            Number(d.digits) * Number(reportInfo.reportMult)
          ).toFixed(Math.max(0, -Math.log10(reportInfo.reportMult))); //set number of decimal places

          return Number(gallons).toLocaleString(); // add commas with to localeString()
        },

        //Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
      },
      {
        Header: 'Delta',
        width: 70,
        id: 'delta',
        //accessor: d => new Date(d.timestampSaved).toDateString()
        //"timestampSaved",
        accessor: (d) => {
          return `${(d.deltaDigits * reportInfo.reportMult)
            .toFixed(Math.max(0, -Math.log10(reportInfo.reportMult))) //set number of decimal places
            .toLocaleString()}`;
        },
        //Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
      },
      {
        Header: 'Mins',
        width: 70,
        id: 'mins',
        //accessor: d => new Date(d.timestampSaved).toDateString()
        //"timestampSaved",
        accessor: (d) =>
          `${(d.deltaTimeMS / 1000 / 60).toFixed(1).toLocaleString()}`,

        //Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
      },
      {
        Header: 'Per Hour',
        width: 70,
        id: 'flow',
        //accessor: d =>
        //  `${((60 * d.deltaDigits) / d.deltaTimeMS).toLocaleString()}`,
        Cell: ({ row }) => {
          return (
            <span className="number">
              {((row.values.delta * 60) / row.values.mins).toFixed(2)}
            </span>
          ); // Custom cell components!
        },
      },
    ],
    // check for these dependencies changing to rerender the data.
    // eslint-disable-next-line
    [
      reportInfo,
      admin,
      closeImageModal,
      isImageModalOpen,
      showImageModal,
      readingsData,
      userSelectedCamera,
    ]
  );

  const {
    gotoPage,
    setPageSize,
    // canPreviousPage,
    // canNextPage,
    pageOptions,
    state: { pageIndex, pageSize },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: rows, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    prepareRow,
  } = useTable(
    {
      columns,
      data: readingsData,
      handleCellInputChange,
      autoResetPage: false, // When making changes to the external data you want to disable automatic resets to the state of the table
      initialState: { pageIndex: 0, pageSize: 100 },
    },
    usePagination
  ); // react-table hooks

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              // table headers
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps()}
                      className={classes.tableHeader}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              // table rows
              const isSelected = selectedRows.includes(
                row.original.timestampSaved
              );

              const selectedRowColorPrimary = '#357DAD';
              const selectedRowColorSecondary = '#FFF';

              return (
                <TableRow {...row.getRowProps()} {...rowFn(row)} hover key={i}>
                  {row.cells.map((cell, i) => {
                    return (
                      // cell of row.
                      <TableCell
                        // styles if selected or not
                        style={{
                          background: isSelected
                            ? selectedRowColorPrimary
                            : 'inherit',
                          color: isSelected
                            ? selectedRowColorSecondary
                            : 'inherit',
                          cursor: isSelectModeEnabled ? 'pointer' : 'default',
                        }}
                        key={i}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Table>
        <TableFooter>
          <TableRow className={classes.tableFooterRow}>
            {/* PAGINATION */}

            {/* Material UI TablePagination component */}
            {!isLoading && (
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                colSpan={0}
                className={classes.pagination}
                count={pageOptions.length}
                rowsPerPage={pageSize}
                onPageChange={gotoPage}
                page={pageIndex}
                labelDisplayedRows={() =>
                  `${pageIndex + 1}-${pageOptions.length} of ${
                    pageOptions.length
                  }`
                }
                classes={{ spacer: classes.paginationSpacer }}
                onRowsPerPageChange={(e) => setPageSize(Number(e.target.value))}
                ActionsComponent={(props) => (
                  <TableActions pageOptions={pageOptions} {...props} />
                )}
              />
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
}

const TableActions = (props) => {
  const { /*count, */ page, /*rowsPerPage*/ onPageChange, pageOptions } = props;
  const classes = useStyles();

  const canGoNext = page < pageOptions.length - 1;
  const canGoBack = page > 0;

  const handleFirstPageButtonClick = () => {
    onPageChange(0);
  };

  const handleBackButtonClick = () => {
    // if (!canPreviousPage) return;
    if (!canGoBack) return;
    const previousPage = page - 1;
    onPageChange(previousPage);
  };

  const handleNextButtonClick = () => {
    // if (!canNextPage) return;
    if (!canGoNext) return;

    const nextPage = page + 1;
    onPageChange(nextPage);
  };

  const handleLastPageButtonClick = () => {
    // onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    onPageChange(pageOptions.length - 1);
  };

  return (
    <div className={classes.paginationActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        disabled={!canGoNext}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        disabled={!canGoNext}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

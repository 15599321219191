import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// components
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import MeterVibeButtonPrimary from '../components/shared/buttons/MeterVibeButtonPrimary';

export default function UserSettings() {
  const { user } = useSelector(({ userReducer }) => userReducer);

  if (!user?.id) return null; // or loading

  return (
    <Grid
      style={{
        padding: '20px',
        marginTop: '20px',
      }}
      container
      direction="row"
      justifyContent="space-evenly"
      spacing={1}
      alignItems="flex-start">
      <PersonalDetails />

      <Grid container item direction="column" spacing={1} xs={6}>
        <ChangePassword />

        <DefaultPreferences />
      </Grid>
    </Grid>
  );
}

const PersonalDetails = () => {
  const { userEmail } = useSelector(({ userReducer }) => userReducer);

  const [personalDetails, setPersonalDetails] = useState({
    // name: user.name, // doesn't exist
    name: '',
    email: '',
    phoneNumber: '',
  });

  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setPersonalDetails((prevState) => ({
      ...prevState,
      email: userEmail,
    }));
    // set the email to what is the userEmail when mounted

    return () => {
      setPersonalDetails((prevState) => ({
        ...prevState,
        email: userEmail,
      }));
    };
  }, [userEmail]);

  const onSavePersonalDetails = useCallback(() => {
    alert(JSON.stringify(personalDetails));
  }, [personalDetails]);

  return (
    <Grid container item direction="column" xs={6}>
      <Paper>
        <Grid
          item
          container
          direction="column"
          className={classes.sectionItems}>
          <Typography component="h1" className={classes.sectionTitle}>
            Personal details
          </Typography>

          <FormGroup style={{ marginTop: '30px' }}>
            <FormControl style={{ marginBottom: '20px' }}>
              <TextField
                onChange={handleChange}
                value={personalDetails.name}
                name="name"
                label="Your name"
                variant="outlined"
              />
            </FormControl>

            <FormControl style={{ marginBottom: '20px' }}>
              <TextField
                value={personalDetails.email}
                onChange={handleChange}
                name="email"
                label="Enter your email"
                variant="outlined"
              />
            </FormControl>

            <FormControl style={{ marginBottom: '10px' }}>
              <TextField
                value={personalDetails.phoneNumber}
                onChange={handleChange}
                type="tel"
                label="Mobile phone number"
                name="phoneNumber"
                variant="outlined"
              />
              <FormHelperText style={{ marginTop: '10px' }}>
                Will be used to send alerts on your mobile phone. You can
                customize what you want to receive in Alerts settings.
              </FormHelperText>
            </FormControl>

            <FormControl className={classes.saveButton}>
              <MeterVibeButtonPrimary
                text="Save changes"
                onClick={onSavePersonalDetails}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Paper>
    </Grid>
  );
};

const ChangePassword = () => {
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSaveNewPassword = useCallback(() => {
    alert(JSON.stringify(passwordData));
  }, [passwordData]);

  return (
    <Grid item>
      <Paper>
        <Grid item className={classes.sectionItems}>
          <Typography component="h1" className={classes.sectionTitle}>
            Change password
          </Typography>

          <FormGroup style={{ marginTop: '30px' }}>
            <FormControl style={{ marginBottom: '20px' }}>
              <TextField
                onChange={handleChange}
                value={passwordData.oldPassword}
                name="oldPassword"
                label="Old password"
                variant="outlined"
              />
            </FormControl>

            <FormControl style={{ marginBottom: '10px' }}>
              <TextField
                onChange={handleChange}
                value={passwordData.newPassword}
                label="New password"
                name="newPassword"
                variant="outlined"
              />
            </FormControl>

            <FormControl className={classes.saveButton}>
              <MeterVibeButtonPrimary
                text="Save new password"
                onClick={onSaveNewPassword}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Paper>
    </Grid>
  );
};

const DefaultPreferences = () => {
  const classes = useStyles();

  const [defaultPreferences, setDefaultPreferences] = useState({
    showDataType: 'Gallons',
    gallonPrice: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDefaultPreferences((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSaveDefaultPreferences = useCallback(() => {
    alert(JSON.stringify(defaultPreferences));
  }, [defaultPreferences]);

  return (
    <Grid item>
      <Paper>
        <Grid item className={classes.sectionItems}>
          <Typography component="h1" className={classes.sectionTitle}>
            Default preferences
          </Typography>

          <FormGroup row style={{ marginTop: '20px' }}>
            <Box style={{ minWidth: '120px' }}>
              <InputLabel htmlFor="showDataType" className={classes.inputLabel}>
                Show data in
              </InputLabel>

              <Select
                variant="outlined"
                value={defaultPreferences.showDataType}
                onChange={handleChange}
                name="showDataType"
                label="Show data in">
                <MenuItem value="Gallons">Gallons</MenuItem>
                <MenuItem value="Cubic Feet">Cubic Feet</MenuItem>
              </Select>
            </Box>

            <Box style={{ minWidth: '120px' }}>
              <InputLabel className={classes.inputLabel} htmlFor="gallonPrice">
                Gallon Price
              </InputLabel>

              <TextField
                variant="outlined"
                type="number"
                name="gallonPrice"
                // label="Gallon price"
                onChange={handleChange}
              />
            </Box>
          </FormGroup>

          <Box className={classes.saveButton} style={{ marginTop: '30px' }}>
            <MeterVibeButtonPrimary
              text="Save changes"
              onClick={onSaveDefaultPreferences}
            />
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  sectionItems: {
    padding: '30px',
  },

  sectionTitle: {
    fontWeight: 700,
    color: '#01070F',
    fontSize: '18px',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },

  inputLabel: {
    color: '#363636',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    fontSize: '14px',
  },

  saveButton: {
    maxWidth: '40%',
    marginTop: '10px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}));

// Admin.js will contain config options for users with admin privileges
// currently serving only as a placeholder

//1/25/21  testing here to see about amazonTurk doing hits screens to annotate images
//login to eric@uspestprotect.com (username: ericProtect)
// goto : requester.mturk.com  or https://requester.mturk.com/create/projects/1726062
// saved the current code in /test crowd/rotationLine.html

import React from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';  //want to bring in admin variable to on allow admin's access

import { AmplifySignUp } from '@aws-amplify/ui-react';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '2px',
  },
  typography: {
    padding: '10px',
    background: '#f5f5f5',
  },
});

/*

const Admin = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>

          <Typography className={classes.typography} variant="h4">Admin Page</Typography>
          <br />
          <Typography className={classes.typography} variant="h5">
            Here we will have config options for admins.
          </Typography>

      </div>
    </>
  );
};

*/

const Admin = () => {
  
  const { admin } = useSelector(({ userReducer }) => 
                      userReducer);

  //console.log("admin:", admin);
    
  const cognitoIdentityId = async () => {
    return await Auth.currentCredentials();
   };
     const apiButton = async () => {
     const id = (await cognitoIdentityId()).identityId;
     console.log('CognitoIdentityId: ', id);
   };


  const classes = useStyles();
  return admin ? (  //only allow admin==true to use this component
    <>
    <div className={classes.root}>

      <Typography className={classes.typography} variant="h4">Admin Page</Typography>
      <br />
      <Typography className={classes.typography} variant="h5">
        Here we will have config options for admins.
      </Typography>
      
      <AmplifySignUp
            headerText="Create MeterVibe user account"
            slot="sign-up"
            usernameAlias="email"
            hideSignIn
            formFields={[
              {
                type: 'email',
                label: 'Username - an email address ',
                placeholder: 'youremail@domain.com',
                required: true,
              },
              {
                type: 'password',
                label: 'Enter a password (at least 8 chars)',
                placeholder: 'Password',
                required: true,
              },
              {
                type: 'phone_number',
                label: 'Phone number (for alerts)',
                placeholder: '(555) 555-1212',
                required: false,
              },
           ]}>
           {/*do not want "sign-in" here, from: https://github.com/aws-amplify/amplify-js/issues/6089  */}
           <div slot="secondary-footer-content"></div>  
          </AmplifySignUp>

      <Button
        variant="outlined"
        fullWidth
        onClick={() => apiButton()}>
        Console.log Cognito Id
      </Button>

      </div>
    </>
  ) : ( "This user is not ADMIN!"  //got here because admin==false
  );
};

export default Admin;

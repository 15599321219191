import React from 'react';
import { Chart } from 'react-google-charts';
import { Grid } from '@material-ui/core';

export default function ReportsChart({ data, chartType, render, unitsLabel }) {
  return (
    <Grid item container direction="column">
      <div
        className="Panel"
        style={{
          width: '100%',
          height: '287px',
          padding: '30px',
        }}>
        {/* ****************** Bar Chart ****************** */}
        {render && (
          <Chart
            chartType={chartType}
            legendToggle
            width="100%"
            height="240px"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              colors: ['#3d93cc'],
              hAxis: {
                title: 'Date/Time',
                format: 'MM yyyy',
              },
              vAxis: {
                title: unitsLabel,
              },
              chart: {},
            }}
          />
        )}
      </div>
    </Grid>
  );
}

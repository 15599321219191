import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    '&:focus': {
      outline: 'none',
    },
  },
});

const MeterVibeButton = ({
  Icon,
  text,
  onClick,
  className,
  backgroundColor,
  textTransform,
  fontWeight,
  color,
  border,
  background,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={className ?? classes.root}
      color="primary"
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor ?? '#015388',
        background: background ? background : backgroundColor ?? '#015388',
        textTransform: textTransform ?? 'upper-case',
        fontWeight: fontWeight ?? 'inherit',
        color: color ?? '#fff',
        border: border ?? 'inherit',
      }}
      {...rest}>
      {text}
      {Icon && (
        <>
          &nbsp;
          <Icon />
        </>
      )}
    </Button>
  );
};

export default MeterVibeButton;

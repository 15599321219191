import React, { useState, useEffect, useCallback } from 'react';

// components
import AlertsNavbar from '../components/alerts_components/AlertsNavbar';
import StatusAndAlerts from '../components/alerts_components/StatusAndAlerts/StatusAndAlerts';
import AlertsSettings from '../components/alerts_components/AlertsSettings/AlertsSettings';

import { mockAlerts } from '../mocks/alerts.mock';

const SEVERITY_RATINGS = {
  info: 0,
  success: 0,
  warning: 1,
  danger: 2,
};

const toggleAlertCleared = (alert) => {
  return {
    ...alert,
    isCleared: !alert.isCleared,
  };
};

const checkNewIssueFound = (alerts) => {
  const newIssueFound = alerts.find(
    ({ severity }) => SEVERITY_RATINGS[severity] >= 1 // warning || danger
  );

  return newIssueFound ?? false;
};

export default function Alerts() {
  const [activeTab, setActiveTab] = useState('StatusAndAlerts');
  const [issueFound, setIssueFound] = useState(null);
  const [allVisibleAlerts, setAllVisibleAlerts] = useState([]);

  useEffect(() => {
    // API.get('/user/allAlerts')
    let responseData = {
      alerts: mockAlerts,
    };

    const { alerts } = responseData;

    const unclearedAlerts = alerts.filter((alert) => !alert.isCleared);

    setAllVisibleAlerts(unclearedAlerts);
  }, []);

  const changeToTab = useCallback(
    (_e, newTab) => {
      setActiveTab(newTab);
    },
    [setActiveTab]
  );

  const closeIssueFound = useCallback(() => {
    // API.post("/alerts/issues/issueid")
    setIssueFound(false);
  }, [setIssueFound]);

  const handleClearAlerts = useCallback(() => {
    // API.post("/alerts/clearAll")
    setAllVisibleAlerts([]);
  }, [setAllVisibleAlerts]);

  // this runs everytime an alert is cleared.
  // toggleAlertCleared will change the isCleared value (so if it's false it'll be true)
  const clearOneAlert = useCallback(
    (alert) => {
      // API.post(alerts/clear/alert.timestamp)

      // toggleAlertCleared will change the isCleared value (so if it's false it'll be true)
      const updatedAlert = toggleAlertCleared(alert);

      // map though the state and if timestamp is even that means thats the alert we want to update
      const updatedAlerts = [...allVisibleAlerts].map((mappedAlert) =>
        mappedAlert.timestamp === alert.timestamp ? updatedAlert : mappedAlert
      );

      // get the alerts that aren't cleared
      const unclearedAlerts = updatedAlerts.filter((alert) => !alert.isCleared);

      setAllVisibleAlerts(unclearedAlerts);
    },
    [setAllVisibleAlerts, allVisibleAlerts]
  );

  useEffect(() => {
    // check if there's a new issue found everytime alerts change
    const newIssueFound = checkNewIssueFound(allVisibleAlerts);
    setIssueFound(newIssueFound);
  }, [allVisibleAlerts]);

  return (
    <>
      <AlertsNavbar activeTab={activeTab} changeToTab={changeToTab} />

      {activeTab === 'StatusAndAlerts' ? (
        <StatusAndAlerts
          closeIssueFound={closeIssueFound}
          issueFound={issueFound}
          alerts={allVisibleAlerts}
          handleClearAlerts={handleClearAlerts}
          clearOneAlert={clearOneAlert}
        />
      ) : (
        <AlertsSettings />
      )}
    </>
  );
}

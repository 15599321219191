import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MeterVibeButton from '../../shared/buttons/MeterVibeButton';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DraggableModal,
} from '../../shared/modal/ModalComponents';
import moment from 'moment';
import 'moment-timezone';
import {
  makeStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

const useStyles = makeStyles({
  subheading: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#7A7A7A',
  },

  imageSectionRoot: {
    overflow: 'visible',
    padding: '20px',
  },

  breakdownSectionRoot: {
    overflow: 'visible',
    padding: '20px',
  },

  altText: {
    color: '#363636',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
  },

  snapshotImg: {
    width: '100%',
  },

  digitsText: {
    fontSize: '16px',
    color: '#363636',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
    fontWeight: 700,
    marginTop: '10px',
  },

  breakdownTableHead: {
    '& > th': {
      fontWeight: 700,
      color: '#242424',
      padding: '5px 10px',
    },
  },

  breakdownTableRow: {
    ' & > td': {
      color: '#363636',
      padding: '15px 10px',
    },
  },

  buttonsContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

function MeterReadingsModal({
  open,
  onClose,
  deviceDetails,
  imageData,
  previousImageData,
  meterReadingsMultiplied,
  label,
  unitCostUSD,
}) {
  const {
    current: currentDigits,
    previous: previousDigits,
    difference: differenceDigits,
  } = meterReadingsMultiplied;
    //console.log("meterReadingsMultiplied", meterReadingsMultiplied);

  const classes = useStyles();

  const mostRecentTime = moment(imageData?.timestampSaved);
  const prevTime = moment(previousImageData?.timestampSaved);

  /* By default, moment#diff will truncate the result to zero decimal places, returning an integer.
   If you want a floating point number, pass true as the third argument */
  // 2 decimal places with toFixed(2)
  const hoursDiff = mostRecentTime.diff(prevTime, 'hours', true).toFixed(2);

  // rate = GallonsDiff  /  hoursDiff = (Gallons / hour)
  const digitsDiff = differenceDigits.number;
  const rateNumber = Number(digitsDiff / hoursDiff).toLocaleString();
  const rateText = `${rateNumber} ${label} / hour`;

  const { cameraLocation, cameraId } = deviceDetails;

  return (
    <DraggableModal onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>
        <span>Meter Readings (More Detail)</span>
        <br />
        <span className={classes.subheading}>
          Device code: {cameraId}, {cameraLocation}
        </span>
      </DialogTitle>

      <DialogContent
        dividers
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '500px',
          overflowWrap: 'break-word',
        }}>
        {previousDigits?.formatted !== 'NaN' && (
          <ImageSection
            category="Prior"
            digits={previousDigits.formatted}
            label={label}
            imageSource={previousImageData.lastSnapShot}
            timeStampSaved={previousImageData.timestampSaved}
          />
        )}

        {currentDigits?.formatted !== 'NaN' && (
          <ImageSection
            category="Recent"
            digits={currentDigits.formatted}
            label={label}
            imageSource={imageData.lastSnapShot}
            timeStampSaved={imageData.timestampSaved}
          />
        )}

        {differenceDigits?.formatted !== 'NaN' && (
          <BreakdownSection
            label={label}
            rateText={rateText}
            hoursDiff={hoursDiff}
            digits={differenceDigits.formatted}
            unitCostUSD={unitCostUSD}
          />
        )}

        {previousDigits?.formatted === 'NaN' &&
          currentDigits?.formatted === 'NaN' && (
            <Typography variant="h6">No Readings</Typography>
          )}
      </DialogContent>

      <DialogActions className={classes.buttonsContainer}>
        <MeterVibeButton
          onClick={onClose}
          text="Close"
          color="#0272BC"
          textTransform="none"
          fontWeight={700}
          backgroundColor="#fff"
          border="1px solid #0272BC"
          variant="contained"
        />
      </DialogActions>
    </DraggableModal>
  );
}

export default React.memo(MeterReadingsModal);

//  a section with the image, timestamp and the digits
const ImageSection = ({ imageSource, timeStampSaved, digits, category }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.imageSectionRoot}
      justifyContent="center">
      {/* recent snapshot: 10-20-2021 */}
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.altText} gutterBottom>
          {category} snapshot:{' '}
          {moment(timeStampSaved).format('hh:mmA MM/DD/yyyy')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <img
          className={classes.snapshotImg}
          src={imageSource}
          alt="last snapshot"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h2" className={classes.digitsText}>
          {digits}
        </Typography>
      </Grid>
    </Grid>
  );
};

// the section at the bottom with the gallons and hours difference, and rate
const BreakdownSection = ({
  label,
  hoursDiff,
  rateText,
  digits,
  unitCostUSD,
}) => {
  const classes = useStyles();

  /* That is on Modal :  Prior Reading : 12:05 am 10/7/2021 286,065.45 <cr>
  Gallons  Recent Readings: 10:31 am 10/7/2021 286,165.45 Gallons
  <cr> Used: Gallons: 100 Time:10.43 minutes Rate: 9.6 Gallons / minute
  */

  /* <Typography variant="h6" component="h1" gutterBottom>
        Used: {label}: {digits}, Time: {hoursDiff} hours, Rate: {rateText}
      </Typography> */

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.breakdownSectionRoot}
      justifyContent="center">
      <Table>
        <TableHead>
          <TableRow className={classes.breakdownTableHead}>
            {['Used', 'Time', 'Rate'].map((cellText, key) => (
              <TableCell key={key}>{cellText}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow className={classes.breakdownTableRow}>
            {[
              `${digits} ${label} (${unitCostUSD})`,
              `${hoursDiff} hours`,
              rateText,
            ].map((cellText, key) => (
              <TableCell key={key}>{cellText}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

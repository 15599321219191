import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import ComputerScreenFrame from '../assets/images/computer_screen_frame.png';
import MeterVibeAppImg1 from '../assets/images/metervibe_app_desktop_1.png';
import MeterVibeAppImg2 from '../assets/images/metervibe_app_desktop_2.png';
import MeterVibeAppImg3 from '../assets/images/metervibe_app_desktop_3.png';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    width: '314px',
  },
  image: {
    maxWidth: '100%',
    width: 'auto',
    height: '166px',
    verticalAlign: 'middle',
    border: '0',
  },
  computerScreen: {
    position: 'absolute',
    height: '100%',
    width: '85%',
    left: '25px',
    top: '13px',
    overflow: 'hidden',
  },
}));
export default function PublicLandingComputerScreen() {
  const slideImages = [MeterVibeAppImg1, MeterVibeAppImg2, MeterVibeAppImg3];
  const classes = useStyles();

  return (
    <Grid item container className={classes.root}>
      <Grid item className={classes.computerScreen}>
        <Slide easing="ease" arrows={false}>
          {slideImages.map((imgSrc, key) => (
            <img
              key={key}
              src={imgSrc}
              className={classes.image}
              alt="computer screen displaying the MeterVibe app"
            />
          ))}
        </Slide>
      </Grid>

      <Grid item>
        <img
          width="100%"
          src={ComputerScreenFrame}
          alt="MeterVibe app displayed in a computer screen"
        />
      </Grid>
    </Grid>
  );
}

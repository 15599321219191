import mockSnapshotSRC from '../assets/mock_images/cam32_concat.jpg';

// this is just for creating mock dates
const SECONDS_IN_DAY = 60 * 60 * 24; // not in MS
const ONE_DAY_IN_MS = 1000 * SECONDS_IN_DAY; // one day in milliseconds
const ONE_HOUR_IN_MS = 1000 * 60 * 60; // one hour in milliseconds

const mockSnapshotRecent = {
  signedUrl: mockSnapshotSRC,
  digits: '0,000,292.88',
  timestampSaved: Date.now() - ONE_HOUR_IN_MS,
};

const mockSnapshotPrior = {
  signedUrl: mockSnapshotSRC,
  digits: '140,149,222.88',
  timestampSaved: Date.now() - ONE_HOUR_IN_MS * 4,
};

export const mockAlerts = [
  {
    type: 'Leakage',
    message:
      'Potential leakage: Water have been running for 3 hours. You have used 240 gallons of water',
    timestamp: Date.now(),
    isCleared: false,
    severity: 'danger', // severity is used for the color of circles at the AlertRow component (inside AlertsSection.js)

    // snapshots for details modal
    firstSnapshot: mockSnapshotPrior,
    recentSnapshot: mockSnapshotRecent,
  },
  {
    type: 'Leakage',

    message: 'You used 45% more water today compared to your average daily use',
    timestamp: Date.now() - ONE_HOUR_IN_MS,
    isCleared: false,
    severity: 'warning',

    firstSnapshot: mockSnapshotPrior,
    recentSnapshot: mockSnapshotRecent,
  },
  {
    type: 'Leakage',

    message: 'You used 15% more water today compared to your average daily use',
    timestamp: Date.now() - ONE_DAY_IN_MS,
    isCleared: false,
    severity: 'info',

    firstSnapshot: mockSnapshotPrior,
    recentSnapshot: mockSnapshotRecent,
  },
  {
    type: 'Leakage',

    message: 'New device (code 235235252) was successfuly activated',
    timestamp: Date.now() - ONE_DAY_IN_MS * 2,
    isCleared: false,
    severity: 'success',

    firstSnapshot: mockSnapshotPrior,
    recentSnapshot: mockSnapshotRecent,
  },
];

// Dashboard.js shows cards for all active cameras associated with the user

import React from 'react';
import { makeStyles } from '@material-ui/core';
import CameraCard from '../components/CameraCard';
import NoCameras from '../components/NoCameras';
import { useHistory } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { CAMERA_ACTIONS } from './../reducers/cameras.reducer';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  typography: {
    padding: '10px',
    background: '#f5f5f5',
  },
  imageDiv: {
    background: 'black',
    overflow: 'hidden',
    margin: 'auto',
  },
  camerasList: {
    maxHeight: 600,
    overflowY: 'auto', // scrollable list

    // reset ul styling
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& > li:not(:last-child)': {
      // distance between cards
      marginBottom: '3px',
    },
  },
});

const { SET_USER_SELECTED_CAMERA } = CAMERA_ACTIONS;

//create a list of camera cards with a key on each one
const ListCameraCards = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const cameras = useSelector(({ camerasReducer }) => camerasReducer.cameras);

  const setUserSelectedCamera = (camera) =>
    dispatch({ type: SET_USER_SELECTED_CAMERA, payload: camera });

  const CARDS = cameras.map((camera, i) => (
    <li key={i.toString()}>
      <CameraCard
        camera={camera}
        onViewDetails={() => {
          // view details link, select the camera and push to '/device',
          setUserSelectedCamera(camera);
          history.push('/device');
        }}
        onViewReports={() => {
          // view reports link, change camera and push to reports
          setUserSelectedCamera(camera);
          history.push('/reports');
        }}
      />
    </li>
  ));
  return <ul className={classes.camerasList}>{CARDS}</ul>;
};

// readability: when using multiple components in the same file, use export default function to indiciate that this is the main component
export default function Dashboard() {
  const [cameras, { awaitingAPI }] = useSelector(
    ({ camerasReducer, generalReducer }) => [
      camerasReducer.cameras,
      generalReducer,
    ]
  );

  const classes = useStyles();

  // only renders before API return
  // Can replace this with a <Waiting /> component eventually
  if (awaitingAPI) {
    return (
      <>
        <h2>Getting device info...</h2>
      </>
    );
  }

  // JZ - all this EB code was attached to a button that brings back the CognitoId
  // const cognitoIdentityId = async () => {
  //   return await Auth.currentCredentials();
  // };

  // ========================================================================

  /* const apiButton = async () => {
     const id = (await cognitoIdentityId()).identityId;
     console.log('CognitoIdentityId: ', id);
   };*/

  /*  this code was in the return below to add a button to display the logged in user's id
      <Button
        variant="outlined"
        fullWidth
        onClick={() => apiButton()}
      >
        Console.log Cognito Id
      </Button>
    */
  // Dashboard.js returns a map of the cameras associated with the logged in user

  return (
    <div className={classes.root}>
      {cameras.length > 0 ? (
        <ListCameraCards cameras={cameras} />
      ) : (
        <NoCameras /> // only if no cameras return
      )}
    </div>
  );
}

import moment from 'moment';

export const REPORTS_ACTIONS = {
  ROW_SELECTED: 'ROW_SELECTED',
  CLEAR_ALL_SELECTED_ROWS: 'CLEAR_ALL_SELECTED_ROWS',
  SET_PAGE_STATE: 'SET_PAGE_STATE',
  TOGGLE_SELECT_MODE_ENABLED: 'TOGGLE_SELECT_MODE_ENABLED',
  SET_BAR_CHART_DATA: 'SET_BAR_CHART_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_READINGS_DATA: 'SET_READINGS_DATA',
  DELETE_SELECTED_ROWS: 'DELETE_SELECTED_ROWS',
  SELECT_ALL_ROWS: 'SELECT_ALL_ROWS',
  SET_CHART_DATA: 'SET_CHART_DATA',
  SET_CAMERA_DATA: 'SET_CAMERA_DATA',
  SET_IMAGES_LIST: 'SET_IMAGES_LIST',
  SET_REPORT_INFO: 'SET_REPORT_INFO',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_PERIOD_TYPE: 'SET_PERIOD_TYPE',
  RESET_TIME: 'RESET_TIME',
};

const {
  ROW_SELECTED,
  SET_PAGE_STATE,
  CLEAR_ALL_SELECTED_ROWS,
  TOGGLE_SELECT_MODE_ENABLED,
  SET_BAR_CHART_DATA,
  SET_IS_LOADING,
  DELETE_SELECTED_ROWS,
  SELECT_ALL_ROWS,
  SET_READINGS_DATA, // readings table data
  SET_CHART_DATA,
  SET_CAMERA_DATA,
  SET_IMAGES_LIST,
  SET_REPORT_INFO,
  SET_START_DATE,
  SET_END_DATE,
  SET_PERIOD_TYPE,
  RESET_TIME,
} = REPORTS_ACTIONS;

const initialState = {
  isLoading: true,
  imagesList: [],

  barChartData: [],
  readingsData: [],
  chartData: [],

  cameraData: {
    cameraId: '',
    cameraName: '',
    cameraLocation: '',
  },

  pageState: {
    // Usage Overview tab
    showFilter: true,
    showChart: true,
    showDetailCards: true,

    // Selected tab
    usageOverviewSelectedTab: true,
    readingsTabSelected: false,
    perAppliancesTabSelected: false,
    customReportsTabSelected: false,
  },
  activeTab: 0, // the number for the Mui component to know which tab to give the selected style (readings tab, usage overview tab).

  // selecting rows at readings table,
  isSelectModeEnabled: false, // the state boolean for being able to select a table row
  selectedRows: [], // the selected table rows in the readings page.

  reportInfo: {
    reportCount: 0,
    reportLabel: 'unitsLabel',
    reportMult: 1,
  },

  startDate: null,
  endDate: moment().format('YYYY-MM-DD'),

  currentPeriodType: 'month', // month,week, all buttons
};

export default function reportsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case CLEAR_ALL_SELECTED_ROWS: {
      return {
        ...state,
        selectedRows: [],
      };
    }

    case ROW_SELECTED: {
      let newSelectedRowsState;

      // useTable gives us the index of the table row.

      if (state.selectedRows.includes(payload.original.timestampSaved)) {
        // if the row is already selected and user clicks on it, that means user wants to remove/unselect the row
        newSelectedRowsState = [...state.selectedRows].filter(
          (timestampSaved) => timestampSaved !== payload.original.timestampSaved
        );
      } else {
        // push newly selected row
        newSelectedRowsState = [
          ...state.selectedRows,
          payload.original.timestampSaved,
        ];
      }

      return {
        ...state,
        selectedRows: newSelectedRowsState,
      };
    }

    // for displaying certain components.
    case SET_PAGE_STATE: {
      switch (payload) {
        case 'UsageOverview':
          return {
            ...state,
            activeTab: 0,
            pageState: {
              // set content
              showFilter: true,
              showChart: true,
              showDetailCards: true,
              // set tab appearance
              usageOverviewSelectedTab: true,
              readingsTabSelected: false,
            },
          };

        case 'Readings':
          const newSelectModeEnabledState =
            state.isSelectModeEnabled && payload !== 'Readings'
              ? false
              : state.isSelectModeEnabled; // disable select mode when moving to other page

          return {
            ...state,
            isSelectModeEnabled: newSelectModeEnabledState,
            activeTab: 1, // the number for the Mui component to know which tab to give the selected style.
            pageState: {
              showFilter: true,
              showChart: false,
              showDetailCards: false,
              // set tab appearance
              usageOverviewSelectedTab: false,
              readingsTabSelected: true,
            },
          };

        default:
          return state;
      }
    }

    case TOGGLE_SELECT_MODE_ENABLED: {
      return {
        ...state,
        isSelectModeEnabled: !state.isSelectModeEnabled,
      };
    }

    case SET_BAR_CHART_DATA: {
      return {
        ...state,
        barChartData: payload,
      };
    }

    case SET_READINGS_DATA: {
      return {
        ...state,
        readingsData: payload,
      };
    }

    case SET_CHART_DATA: {
      return {
        ...state,
        chartData: payload,
      };
    }

    case SET_CAMERA_DATA: {
      const { cameraId, cameraName, cameraLocation } = payload;

      return {
        ...state,
        cameraData: {
          cameraId,
          cameraName,
          cameraLocation,
        },
      };
    }
    case SELECT_ALL_ROWS: {
      // delete readings data of selected rows.
      return {
        ...state,
        selectedRows: [
          ...new Set(
            state.readingsData.map(({ timestampSaved }) => timestampSaved)
          ),
        ],
      };
    }

    case DELETE_SELECTED_ROWS: {
      // delete readings data of selected rows.
      return {
        ...state,
        readingsData: state.readingsData.filter(
          ({ timestampSaved }) => !state.selectedRows.includes(timestampSaved)
        ),

        selectedRows: [],
      };
    }

    case SET_IMAGES_LIST: {
      // remove all the elements from the list that don't have digits.
      // const hasDigitsList = payload.filter(({ digits }) => Boolean(digits));

      return {
        ...state,
        imagesList: payload,
      };
    }

    case SET_REPORT_INFO: {
      return {
        ...state,
        reportInfo: payload,
      };
    }

    case SET_START_DATE: {
      return {
        ...state,
        startDate: payload,
      };
    }

    case SET_END_DATE: {
      return {
        ...state,
        endDate: payload,
      };
    }

    case SET_PERIOD_TYPE: {
      return {
        ...state,
        currentPeriodType: payload,
      };
    }

    case RESET_TIME: {
      // reset the time filters to initial state when page loaded
      return {
        ...state,
        startDate: moment(state.chartData[1][0]).format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        currentPeriodType: 'month',
      };
    }

    default:
      return state;
  }
}

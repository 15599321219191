import styled from '@emotion/styled';

const Row = styled.div`
  width: 475px;
  margin: 30px auto;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
  background-color: #7795f8;
  position: relative;

  /* I need to do this so the purchase card doesn't break the site on low media queries. */
  @media screen and (max-width: 768px) {
    width: ${({ responsive }) => responsive && '345px'};
  }
`;

export default Row;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';

import Routes from './Routes';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTopOnMount from './components/shared/helper_components/ScrollToTopOnMount';

// reducer actions
import { USER_ACTIONS } from './reducers/user.reducer';
import { AUTH_ACTIONS } from './reducers/auth.reducer';
import { CAMERA_ACTIONS } from './reducers/cameras.reducer';
import { API_ACTIONS } from './reducers/general.reducer';

// services
import { apiCameras } from './services/cameras.services';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh', // doing this so MUI paper reaches from top to bottom and doesn't get cut off
  },
});

const { SET_USER, SET_USER_EMAIL, SET_ADMIN } = USER_ACTIONS;
const { SET_AUTH_STATE } = AUTH_ACTIONS;
const { SET_CAMERAS, SET_USER_SELECTED_CAMERA } = CAMERA_ACTIONS;
const { SET_AWAITING_API } = API_ACTIONS;

export default function App() {
  const history = useHistory();

  const dispatch = useDispatch();

  const classes = useStyles();

  //add in Hub listener to dispatch on Auth events
  //this way we can clear out user data on logout
  //and set up user data on login
  useEffect(() => {
    //run this after the auth user login event to set the list of devices
    //set this users Admin level
    //set the currently selected device
    const getCameras = async () => {
      try {
        const apiCamerasResponse = await apiCameras();
        console.log('apiCamerasResponse:', apiCamerasResponse);
        // setAdmin in App.js to whatever value comes back from API
        dispatch({ type: SET_ADMIN, payload: apiCamerasResponse.admin });
        // sort the cameras and set them to state
        const sortedCameras = apiCamerasResponse.data.sort((a, b) =>
          a.cameraId.localeCompare(b.cameraId)
        );

        dispatch({ type: SET_CAMERAS, payload: sortedCameras });

        // console.log('sortedCameras:', sortedCameras);

        dispatch({
          type: SET_USER_SELECTED_CAMERA,
          payload: sortedCameras[0],
        });
        // console.log('userSelectedCamera:', userSelectedCamera);
        // allow Dashboard.js to map the cameras
        dispatch({ type: SET_AWAITING_API, payload: false });
      } catch (e) {
        console.log(e);
      }
    };
    const authListener = (data) => {
      //callback function dispatched by auth publish when authentication event
      switch (data.payload.event) {
        case 'signIn':
          console.log('user signed in');
          console.log('data:', data);
          console.log('email:', data.payload.data.attributes.email);

          dispatch({ type: SET_USER, payload: data.payload.data });

          dispatch({
            type: SET_USER_EMAIL,
            payload: data.payload.data.attributes.email,
          });

          getCameras();

          history.push('/dashboard'); // push the user to the app after signing in
          break;
        case 'signUp':
          console.log('user signed up');
          break;
        case 'signOut':
          console.log('user signed out');
          console.log('data:', data);
          console.log('email:', data.payload.data.attributes.email);
          history.push('/'); //logging out, so go to / on login.
          break;
        case 'signIn_failure':
          console.log('user sign in failed');
          break;
        case 'configured':
          console.log('the Auth module is configured');
          break;
        default: //get rid of compiler warning of expected default case
      }
    };

    //on page reload, there is no signIn or Signout, but the state is reset
    //const userInfo = await Auth.currentUserInfo();
    Auth.currentUserInfo().then((data) => {
      console.log('auth current credentials:', data);
      if (data) {
        // when this is run at start, there is no currentUserInfo, so data.attributs.email undefined and errored
        dispatch({ type: SET_USER, payload: data });

        dispatch({
          type: SET_USER_EMAIL,
          payload: data.attributes.email,
        });
      }
    });
    getCameras();

    //create a listener that runs on auth changes
    Hub.listen('auth', authListener); //subscribe to the auth events
    return () => {
      Hub.remove('auth', authListener); //useEffect cleanup, remove the listener
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this useEffect handles authentication changes and the history array
  useEffect(() => {
    //return onAuthUIStateChange((nextAuthState, authData) => {
    onAuthUIStateChange((nextAuthState, authData) => {
      dispatch({ type: SET_AUTH_STATE, payload: nextAuthState });
      dispatch({
        type: SET_USER,
        payload: authData,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only re-run the effect if user or history changes

  return (
    <>
      <ScrollToTopOnMount />
      <CssBaseline />
      <div className={classes.root}>
        <Routes />
      </div>
    </>
  );
}

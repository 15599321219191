import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
//import { Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Slider } from '@material-ui/core';

// services
//import { postSnap, postSnap2 } from './../services/snap.services';
import { postSnap } from './../services/snap.services';
import { beginCalibration } from '../services/turk.services';
import {
  getCameraParams,
  updateCameraFlash,
} from './../services/cameras.services';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const Snap = ({
  imageURL,
  setSnapped,
  // userSelectedTimestamp, //  unused prop
}) => {
  // const userSelectedTimestamp = props.userSelectedTimestamp;  //passing this in for Begin Calibration handle on good image to use for calibration
  const classes = useStyles();

  const userSelectedCamera = useSelector(
    ({ camerasReducer }) => camerasReducer.userSelectedCamera
  );

  // flash values, range: 0..100, used for construction of params object to send to snap-image lambda

  const [flash1, setFlash1] = useState(50);
  const [flash2, setFlash2] = useState(40);
  const [flash3, setFlash3] = useState(10);
  

  // handleFlash function for toggling flash booleans on checkbox selection
  const handleChangeFlash1 = async (e, value) => {
    //console.log("in handleChangeFlash, flash1, value:",  value)
    setFlash1(value);
      };

  const handleChangeFlash2 = async (e, value) => {
    setFlash2(value);
      };

  const handleChangeFlash3 = async (e, value) => {
    setFlash3(value);
      };
  
  // save off flash1, flash2, flash3 to dynamoDb for this camera, intensity level from 0..100
  const saveFlash = async () => {
    
    // posting flash1 and flash2 and saving in dynamo db everytime checkbox has been checked.
    // first convert the boolean (for checkbox) values to 0(false, off), or 1 (true, on/flash)
    //const flash1Number = Number(flash1)  //0 or 1
    //const flash2Number = Number(flash2)
    console.log("saving flash settings to dynamodb, flash1, flash2, flash3:", flash1, flash2, flash3);
    
    await updateCameraFlash(userSelectedCamera?.cameraId, 
      { "flash1":flash1, "flash2":flash2, "flash3":flash3 });

  }

  // constructs params object based on flash selections and posts to API
  const snapImage = async () => {
    let params = {
      body: {
        cameraId: userSelectedCamera.cameraId,
        // flash values range 0..100, 0 off, 100 full brighness
        flash1: userSelectedCamera.flash1 || 0,
        flash2: userSelectedCamera.flash2 || 0,
        flash3: userSelectedCamera.flash3 || 0,
        framesize: userSelectedCamera.framesize || 8,  //8 is VGA 640 x 480 (sensor.h framesize_t)
        vflip: userSelectedCamera.vFlip,
        hflip: userSelectedCamera.hFlip,
        timestamp: new Date().getTime(),
        winX: userSelectedCamera.subXOrig,
        winY: userSelectedCamera.subYOrig,
        winWidth: userSelectedCamera.subWidth,
        winHeight: userSelectedCamera.subHeight,
      },  
    };

    params.body.flash1 = flash1;
    params.body.flash2 = flash2;
    params.body.flash3 = flash3;
    console.log("calling postSnap with params after setting the flash's:", userSelectedCamera.cameraId, params);
    await postSnap(userSelectedCamera.cameraId, params);

    setSnapped(true); // sets snapped boolean in parent to trigger useEffect
  };

  useEffect(() => {
    const fetchFlashState = async () => {
      if (!userSelectedCamera?.cameraId) return;

      // getting flash1 and flash2 from api, value integer 0..100
      let { flash1 = 0, flash2 = 0, flash3 =0 } = await getCameraParams(
        userSelectedCamera?.cameraId
      );
      //the value should be stored as a number, 0 for no flash, > 0 (usually 1 or 500) for flash
      //if the value is a string, it will be treated as true, even if the string is 'false'
      //but the interface wants a boolean, so for now just convert it to boolean, and when 
      //saving it off, save it off as a 1(true or on), or 0(false or off)
      //flash1 = Boolean(flash1)
      //flash2 = Boolean(flash2)

      // setting it in the state
      setFlash1(flash1);  //value pulled in from dynamodb "flash1"
      setFlash2(flash2);
      setFlash3(flash3);
    };
    fetchFlashState();

    // fetch on mount and when cameraId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedCamera?.cameraId]);

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}
              >
          <FormLabel component="legend" >
            Choose Flash Intensities, each LED Range: 0..100 
            </FormLabel>
          <FormGroup
            style={{ padding: "20px" }}>
          <FormControlLabel
              control={
                <Slider 
                defaultValue={flash1} 
                value={flash1}
                onChange={handleChangeFlash1}
                valueLabelDisplay="on"
                aria-label="Flash1" />
              }
              label="Flash 1 (left)"
            />
            <FormControlLabel
              control={
                <Slider 
                defaultValue={flash2} 
                value={flash2}
                onChange={handleChangeFlash2}
                valueLabelDisplay="on"
                aria-label="Flash2" />
              }
              label="Flash 2 (right)"
            />
            <FormControlLabel
              control={
                <Slider 
                defaultValue={flash3} 
                value={flash3}
                onChange={handleChangeFlash3}
                valueLabelDisplay="on"
                aria-label="Flash3" />
              }
              label="Flash 3 (middle)"
            />
          </FormGroup>
          
        </FormControl>
        <Button 
          style={{maxHeight: '60px'}}
          color="primary"
          variant="outlined" size='large' onClick={saveFlash}>
        Save Flash Settings
        </Button>
      </div>
      <Button variant="outlined" onClick={snapImage}>
        Snap
      </Button>
      <Button
        variant="outlined"
        onClick={() => beginCalibration(imageURL, userSelectedCamera.cameraId)}>
        Begin Calibration
      </Button>
      {/* <Button variant="outlined" onClick={snap2Image}>
        Snap2
      </Button> */}
    </>
  );
};

export default Snap;

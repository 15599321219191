/* eslint-disable */

// MeterVibe Terms of Service

// To edit the Terms of Service please use markdown syntax. For more details visit https://www.markdownguide.org/cheat-sheet/

// to convert doc or docx to markdown, you can use: https://word2md.com/

const LAST_MODIFIED_DATE = 'September 29, 2021';

export default `
*Last modified: ${LAST_MODIFIED_DATE}*

**TERMS OF USE**

These Terms of Service (&quot;Terms&quot;) establish a binding contractual agreement between you (the visitor or user; hereinafter referred to as &quot;you&quot; or &quot;your&quot;) and XRobotix LLC. &quot;XRobotix LLC&quot;, &quot;our&quot;, &quot;us&quot; or &quot;we&quot;) according to which you may visit the XRobotix LLC website at [http://metervibe.com](http://metervibe.com/) (&quot;Website&quot;, &quot;Site&quot; or &quot;Service&quot;) as well as your view and use of any and all hosted software and applications including but not limited to the interactive drawing application, plug-ins, modules and hosting services (the &quot;Hosted Services&quot;; the Website together with the Hosted Services may hereinafter be collectively referred to the &quot;Services&quot;). The Website is owned and operated by XRobotix LLC and all Services are the intellectual property of XRobotix LLC.

**Description of Service**

XRobotix LLC located at Suite 100, 189 US 9, Englishtown NJ creates a platform through the Website for selling IOT devices and subscription service for that device, MeterVibe.

The Website is offered subject to your acceptance without modification of all of these Terms and all other operating rules, policies (including, without limitation, XRobotix LLC Privacy Policy) and procedures that may be published from time to time on this Website or on or through the Hosted Services by us.

Please read these Terms carefully before accessing or using the Services. By accessing or using any of the Services, you expressly agree to become bound by the Terms of Use of these Terms. If you do not agree to all the Terms of Use, then you may not access the Website or use any Hosted Services. If these Terms of Use are considered an offer by XRobotix LLC, acceptance is expressly limited to these Terms.

**1. Your XRobotix LLC Account.**

If you create an account on the Website or as required to access and use the Hosted Services, you are responsible for maintaining the security of your account and its content, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the Services. You must not describe or assign content to your account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and XRobotix LLC may change or remove any content or images that it considers inappropriate or unlawful, or otherwise likely to cause XRobotix LLC liability. You must immediately notify XRobotix LLC of any unauthorized uses of your account any other breaches of security. XRobotix LLC will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.

**2. Content**

Without limiting any of those representations or warranties, XRobotix LLC has the right (though not the obligation) to, in XRobotix LLC sole discretion (i) refuse or remove any content that, in XRobotix LLC reasonable opinion, violates any XRobotix LLC&#39;s policy or is in any way harmful or objectionable, or (ii) terminate or deny access to and use of the Services to any individual or entity for any reason, in XRobotix LLC sole discretion.

**3. Intellectual Property**

Unless otherwise indicated, the Service is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the &quot;Content&quot;) and the trademarks, service marks, and logos contained therein (the &quot;Marks&quot;) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States of America, foreign jurisdictions, and international conventions. The Content and the Marks are provided on the Site &quot;AS IS&quot; for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.

Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content, and the Marks.

**4. Limitation of Liability**

To the extent permitted under applicable law, under no circumstances shall we, our officers, directors, employees, parents, affiliates, successors, assigns, or licensors be liable to you or any other third party for any indirect, special, incidental, or consequential, exemplary or punitive damages of any type including, without limitation, damages for loss of goodwill, service interruption, computer failure or malfunction, loss of business profits, loss of data or business information, loss of additional software or computer configurations or costs of procurement of substitute goods or services, damages arising in connection with any use of the website or any and all other commercial damages or losses, arising out of or in connection with these terms. Notwithstanding anything to the contrary contained herein, in no event shall our total liability (including our officers, directors, employees, parents, and affiliates) for any claim arising out of or related to these terms, to the fullest extent possible under applicable law, exceed the amount paid if any, by you for the use of the services

**5. Notice**

We may provide notice to you by means of e-mail, a general notice on the site, or by other reliable method to the address you have provided to us.

**6. Indemnification**

You agree to indemnify, defend, and hold harmless us, our officers, directors, employees, agents, licensors and suppliers (collectively the &quot;Service Providers&quot;) from and against all losses, expenses, damages and costs, including reasonable attorneys&#39; fees, resulting from any violation of these Terms of Use or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the site using your Internet account.

**7. Third-Party Links**

In an attempt to provide increased value to our visitors, we may link to sites operated by third parties. However, even if the third party is affiliated with us, we have no control over these linked sites, all of which have separate privacy and data collection practices, independent of us. These

**8. Responsibility of Website Visitors.**

XRobotix LLC has not reviewed, and cannot review, all of the material, including computer software, posted to the site, and cannot therefore be responsible for that material&#39;s content, use or effects. By operating the site, XRobotix LLC does not represent or imply that it endorses the material there posted, or that it believes such material to be accurate, useful or non- harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. The Website may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The site may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third parties, or the downloading, copying or use of which is subject to additional Terms of Use, stated or unstated. We disclaim any and all responsibility for any harm resulting from the use by visitors of the site, or from any downloading by those visitors of content posted thereon.

**9. Content Posted on Other Sites.**

We have not reviewed, and cannot review, all of the material, including computer software, made available through the sites and webpages to which we link, and that link to us. XRobotix LLC does not have any control over those non XRobotix LLC sites and webpages, and is not responsible for their contents or their use. By linking to a non-XRobotix LLC site or webpage, XRobotix LLC does not represent or imply that it endorses such website or webpage. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. XRobotix LLC disclaims any and all responsibility for any harm resulting from your use of non- XRobotix LLC sites and webpages.

**10. Changes.**

XRobotix LLC reserves the right, at its sole discretion, to modify or replace any part of these Terms. It is your responsibility to check these Terms periodically for changes. Your continued use of or access to the Website or Hosted Services following the posting of any changes to these Terms constitutes express acceptance of those changes. XRobotix LLC may also, in the future, offer new services and/or features through the site (including, the release of new tools and resources). Such new features and/or services shall be subject to these Terms.

**11. Termination.**

We may terminate your access to all or any part of the site or your access to any of the Hosted Services at any time, with or without cause, effective immediately. If you wish to terminate your agreement with us or your XRobotix LLC account (if you have one), you may simply discontinue using the Services. Notwithstanding the foregoing, if you have a paid Services account, such account can only be terminated by us if you materially breach these Terms and fail to cure such breach within thirty (30) days from XRobotix LLC notice to you thereof; provided that, we can terminate the Services immediately as part of a general shut down of our service. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

**12. Disclaimer of Warranties.**

The Services are provided &quot;as is&quot;. XRobotix LLC and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither we nor its suppliers and licensors, makes any warranty that the Services will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.

**13. General Representation and Warranty.**

You represent and warrant that (i) your use of the Services will be in strict accordance with the Privacy Policy, with these Terms and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the United States of America or the country in which you reside) and (ii) your use of the Website or Hosted Services will not infringe or misappropriate the intellectual property rights of any third party.

**14. Miscellaneous.**

These Terms constitute the entire agreement between XRobotix LLC and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of XRobotix LLC, or by the posting by XRobotix LLC of a revised version of these Terms. Except to the extent applicable law, if any, provides otherwise, these Terms, any access to or use of the Services will be governed by the laws of the United States of America, excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the judicial courts of the United States of America.
  `;

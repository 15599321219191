export const CAMERA_ACTIONS = {
  SET_USER_SELECTED_CAMERA: 'SET_USER_SELECTED_CAMERA',
  SET_CAMERAS: 'SET_CAMERAS',
};

const { SET_USER_SELECTED_CAMERA, SET_CAMERAS } = CAMERA_ACTIONS;

const initialState = {
  cameras: [],
  userSelectedCamera: null,
};

export default function camerasReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CAMERAS: {
      return {
        ...state,
        cameras: payload,
      };
    }

    case SET_USER_SELECTED_CAMERA: {
      return {
        ...state,
        userSelectedCamera: payload,
      };
    }

    default:
      return state;
  }
}

// returns the cameraObject by passing selectedCameraId (for CameraDropdown.js)
export const getCameraObject = (cameras, selectedCameraId) =>
  cameras.find(({ cameraId }) => cameraId === selectedCameraId);

// this is the amplify supplied auth system screens for logging in to (currently cognito)
// and for resetting password, creating new user account
//this file modifies the text of the amplify supplied screens, and places the modal menus in the center of the grid

import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';

/**
 * @method LoginScreen
 * present a login screen to the user, allow password reset, and optionally account creation
 * @param {boolean} props.allowSignUp  if true, then user can create a new user account
 * @returns JSX Grid component
 */
export default function LoginScreen (props) {
  /* the allowSignUp prop is always flase for Alpha release, as we do customer signup as Admin, might allow users to do this later */
  const [allowSignUp] = useState(props.allowSignUp);  //[allowSignUp, setSignUp]
  //  need to customize the AWS container so minHeight isn't 100vh (makes modal stretch really tall...)
  return (
    <Grid>
      <AmplifyAuthenticator usernameAlias="email">
        {!allowSignUp &&  //not allowing user to create a new MeterVibe account
          <AmplifySignIn
            headerText="Sign in to MeterVibe"
            slot="sign-in" hideSignUp></AmplifySignIn>
        }
        {allowSignUp &&  //enter these items if allowing new account creation
        <>
          <AmplifySignIn
            headerText="Sign in to MeterVibe"
            slot="sign-in">
          </AmplifySignIn>
          <AmplifySignUp
            headerText="Create MeterVibe user account"
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: 'email',
                label: 'Username - an email address ',
                placeholder: 'youremail@domain.com',
                required: true,
              },
              {
                type: 'password',
                label: 'Enter a password (at least 8 chars)',
                placeholder: 'Password',
                required: true,
              },
              {
                type: 'phone_number',
                label: 'Phone number (for alerts)',
                placeholder: '(555) 555-1212',
                required: false,
              },
           ]}>
          </AmplifySignUp>
        </>
        }
      </AmplifyAuthenticator>
    </Grid>
  );
};

//export default LoginScreen;

import React from 'react';

const WavyBackground = ({ height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 1440 ${height ?? '250'}`}>
    <path
      fill="#2969B1"
      fillOpacity="1"
      d="M0,64L60,80C120,96,240,128,360,122.7C480,117,600,75,720,74.7C840,75,960,117,1080,117.3C1200,117,1320,75,1380,53.3L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
  </svg>
);

export default WavyBackground;

/* eslint-disable */

// MeterVibe Privacy Policy

// To edit the Privacy Policy  please use markdown syntax. For more details visit https://www.markdownguide.org/cheat-sheet/

// to convert doc or docx to markdown, you can use: https://word2md.com/

const LAST_MODIFIED_DATE = 'Oct. 15, 2021';

export default `
*Last modified: ${LAST_MODIFIED_DATE}*


**PRIVACY POLICY**

This Privacy Policy describes our policies on the collection, use, and disclosure of information about you in connection with your use of our services (the &quot;Service&quot;). The term &quot;MeterVibe&quot; or &quot;us&quot; or &quot;we&quot; or &quot;our&quot; refers to XRobotix LLC which operates [http://metervibe.com](http://metervibe.com/) website (the &quot;Service&quot;).

When you use the Service, you consent to our collection, use, and disclosure of information about you as described in this Privacy Policy.

This privacy policy sets out how MeterVibe and our website, [http://metervibe.com](http://metervibe.com/) uses and protects any information that you give MeterVibe while using this website. MeterVibe is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement. MeterVibe may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This page was last updated on (insert date of website once complete).

**WHAT WE COLLECT**

We may collect the following information from you when you:

- When registering we may collect your name and contact information including: email address, mailing address, phone number, credit card information, demographic information such as postcode, preferences and interests. However, You may visit our site anonymously.
- Information about gift recipients to enable us fulfill the gift purchase.
- other information relevant to customer surveys and/or offers.

**HOW WE USE INFORMATION WE COLLECT**

We may use the information we collect from you when you register, purchase products, enter a contest or promotion, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:

- provide the Service&#39;s functionality,
- fulfill your requests, improve the Service&#39;s quality, engage in research and analysis relating to the Service, personalize your experience,
- quickly process your transactions,
- personalize your site experience and to allow us to deliver the type of content and product offerings in which you are most interested,
- display relevant advertising, market the Service,
- track usage of the Service, provide feedback to third party businesses that are listed on the Service,
- provide customer support, message you, back up our systems,
- If you have opted-in to receive our e-mail newsletter, we may send you periodic e-mails. If you would no longer like to receive promotional e-mail from us, please refer to the &quot;How can you opt-out, remove or modify information you have provided to us?&quot; section below. If you have not opted-in to receive e-mail newsletters, you will not receive these e-mails. Visitors who register or participate in other site features such as marketing programs and &#39;members-only&#39; content will be given a choice whether they would like to be on our e-mail list and receive e-mail communications from us.
- allow for disaster recovery, enhance the security of the Service, and
- comply with legal obligations.

Even when we do not retain such information, it still must be transmitted to our servers initially and stored long enough to process.

**SECURITY**

We use various safeguards to protect the personal information submitted to us, both during transmission and after we receive it. However, no method of transmission over the Internet or via mobile device, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.

**LINKS TO OTHER WEBSITES**

In an attempt to provide you with increased value, we may include third party links on our site. These linked sites contain independent and separate privacy policies that we do not have control over. Therefore, we cannot be responsible for the protection and privacy of any information which you provide while visiting such other sites and such sites are not governed by this privacy statement.

We therefore have no liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these linked sites. You should exercise caution and look at the privacy statement applicable to the website in question (including if a specific link does not work).

**CONTROLLING YOUR PERSONAL INFORMATION**

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we notify you in advance, except as described below. The term &quot;outside parties&quot; does not include MeterVibe. It also does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others&#39; rights, property, or safety.

However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.

How can you opt-out, remove or modify information you have provided to us?

To modify your e-mail subscriptions, please let us know by modifying your preferences in the &quot;My Account&quot; section. Please note that due to email production schedules you may receive any emails already in production.

**DATA RETENTION AND ACCOUNT TERMINATION**

You may also opt to delete your account. We will remove certain public content from view and/or dissociate them from your account profile, but we may retain information about you for the purposes authorized under this Privacy Policy unless prohibited by law. For example, we may retain information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with legal obligations. We may also maintain residual copies of your personal information in our backup systems. Please note that businesses cannot remove their business pages, ratings, or reviews by closing their accounts.

**CONTACT INFORMATION**

You may contact us concerning our Privacy Policy, or write to us at the following address:

Suite 100, 189 US 9, Englishtown NJ 07726

**MODIFICATIONS TO THIS PRIVACY POLICY**

We may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will govern our collection, use, and disclosure of information about you. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice on the Service prior to or on the effective date of the changes. By continuing to access or use the Service after those changes become effective, you acknowledge the revised Privacy Policy.

**ONLINE POLICY ONLY**

This online privacy policy applies only to information collected through our website and not to information collected offline.
  `;

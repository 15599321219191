import { API } from 'aws-amplify';

/**
 * @method getReportOnlyParams
 * get report params for one camera (cameraId needed)
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<{multiplier: number, units: string, costPerUnit: number}>} 
 *                   { multiplier: 0.001, units: 'CUBIC FEET', costPerUnit: 0.00748 }
 *          the reportParams object after promised resolved.
 */
 export const getReportOnlyParams = async (cameraId) => {
  try {
    let reportOnlyParams = await API.get(
      'dev-iotvid-app',
      `/report-only-params/${cameraId}`
    );
    //console.log("getReportOnlyParams:", reportOnlyParams);
    return reportOnlyParams;
  } catch (error) {
    throw error;
  }
};



/**
 * @method getReportParams
 * deprecated : get all params for one camera (cameraId needed)
 * @param {String} cameraId  the cameraId to be passed
 * @returns {Promise<{{eltList: [ [Array], [Array], [Array] ], cameraNotes: 'M5 ...', cameraSetup: 
 *                    {...,flash2: true,...},cameraLocation: 'Fr...',multiplier: 0.001,...}>} 
 *                     the full tree of parameters object after promised resolved.
 */
/* export const getReportParams = async (cameraId) => {
  try {
    let reportsParams = await API.get(
      'dev-iotvid-app',
      `/reports/${cameraId}/params`
    );
    return reportsParams;
  } catch (error) {
    throw error;
  }
}; */

// Header component sits at the top of App.js ast all times, holding the logo, user email, and AmplifySignOut button

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Hidden } from '@material-ui/core';
import Logo from '../assets/images/LogoTopLeft.png';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#0272BC',
    zIndex: theme.zIndex.drawer + 1, // sets the zIndex of the AppBar as +1 to the drawer so the AppBar will always sit on top of the drawer
  },
  logo: {
    flexGrow: 10, // similar to the grid system, these properties make the logo take up as much space as possible relative to the email and signout button
  },
  userEmail: {
    flexGrow: 1,
  },
  signOutButton: {
    flexGrow: 1,
  },
}));

// this header is inside MeterVibeAppLayout.js'
const Header = ({ userEmail }) => {
  const classes = useStyles();
  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <div className={classes.logo}>
            <img src={Logo} alt="MeterVibe" height="54" width="224" />
          </div>
          {/* <Hidden> MUI component hides its contents on xs screens and below*/}
          <Hidden xsDown>
            <Typography className={classes.userEmail}>{userEmail}</Typography>
          </Hidden>
          {/* TODO: we need to make the signout button also push to / (right now pathname is the same when you leave it) */}
          <div className={classes.signOutButton}>
            <AmplifySignOut />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;

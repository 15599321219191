import { combineReducers } from 'redux';

import userReducer from './user.reducer';
import authReducer from './auth.reducer';
import camerasReducer from './cameras.reducer';
import generalReducer from './general.reducer';
import reportsReducer from './reports.reducer';

// this reducer combines all other specific reducers.
export default combineReducers({
  userReducer,
  authReducer,
  camerasReducer,
  generalReducer,
  reportsReducer,
});

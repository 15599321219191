import React from 'react';
import { Grid, Box } from '@material-ui/core/';
import { PublicLandingText as Text } from './shared/typography/PublicLandingText';
import { Link } from 'react-router-dom';
import WavyBackground from './shared/fun_backgrounds/WavyBackground';

const PRIMARY_COLOR = '#2969B1';

export default function PublicLandingFooter({ classes }) {
  return (
    <>
      <WavyBackground height="120" />
      <footer
        className={classes.pageSection}
        style={{
          backgroundColor: PRIMARY_COLOR,
          paddingTop: '20px',
          paddingBottom: '20px',
        }}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.innerColumn}
          alignItems="flex-start"
          direction="row">
          {/* footer left */}
          <Grid item container sm={5}>
            <Grid item>
              <Text component="h2" fontSize="16px" color="white">
                &copy; 2021 MeterVibe&trade;
              </Text>
            </Grid>
          </Grid>

          <Grid item sm={2} />

          {/* footer right */}
          <Grid item container sm={5} direction="column" alignItems="flex-end">
            <Grid item>
              <Text fontSize="16px" color="white">
                support@metervibe.com
              </Text>
            </Grid>

            <Box marginTop={2} />
            <Grid item container justifyContent="flex-end">
              <Box marginRight={2}>
                <Link to="/privacy_policy">
                  {/* will link to privacy policy page */}
                  <Text
                    cursor="pointer"
                    fontSize="16px"
                    color="white"
                    fontWeight={700}>
                    Privacy Policy
                  </Text>
                </Link>
              </Box>
              <Box>
                {/* will link to terms of service page */}
                <Link to="/terms_of_service">
                  <Text
                    cursor="pointer"
                    fontSize="16px"
                    color="white"
                    fontWeight={700}>
                    Terms of Service
                  </Text>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

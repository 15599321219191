import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PublicLandingHeader from './PublicLandingHeader';
import PublicLandingFooter from './PublicLandingFooter';
import { PublicLandingText as PageTitle } from './shared/typography/PublicLandingText';
import MeterVibeButton from './shared/buttons/MeterVibeButton';

// markdown
import markdown from '../markdown/PrivacyPolicyMarkdown';
import ReactMarkdown from 'react-markdown';

const COLORS = {
  DK_TEXT: '#015388',
  DEFAULT_TEXT: '#0272bc',
  LIGHT_TEXT: '#3d93cc',
};

const useStyles = makeStyles((theme) => ({
  pageContent: {
    flexGrow: 1,
  },

  toolbarDistance: theme.mixins.toolbar, // create distance from toolbar.

  innerColumn: {
    width: '98%',
    maxWidth: '1100px',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: '10px',
  },

  markdown: {
    flexGrow: 1,
    // minHeight: '100vh',
    maxWidth: '100%',
    color: COLORS.DK_TEXT,
    '& h1': {
      fontSize: '48px',
      fontWeight: 700,
    },
    '& h2': {
      fontSize: '32px',
      fontWeight: 700,
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '25px',
    },
    '& h4': {
      fontSize: '20px',
      fontWeight: 700,
      letterSpacing: '1.6pt',
    },
    '& a, a:active, a:visited': {
      color: COLORS.LIGHT_TEXT,
    },
  },
}));

export default function TermsOfService() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <PublicLandingHeader />
      <Grid container direction="column" justifyContent="center">
        <div className={classes.pageContent}>
          <div className={classes.toolbarDistance} />
          <div className={classes.innerColumn}>
            <PageTitle
              component="h1"
              color={COLORS.DK_TEXT}
              gutterBottom
              align="center"
              fontSize={matchesSm ? '25px' : '50px'}
              fontWeight={700}>
              MeterVibe Privacy Policy
            </PageTitle>
            <ReactMarkdown className={classes.markdown} children={markdown} />
          </div>

          <Grid item container justifyContent="center">
            <Box my={4}>
              <MeterVibeButton
                text="Go Back"
                variant="contained"
                onClick={() => history.goBack()}
              />
            </Box>
          </Grid>
        </div>
        <Box className={classes.pageContent} marginTop={4} />
        <PublicLandingFooter classes={classes} />
      </Grid>
    </>
  );
}

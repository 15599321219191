// this holds information about each camera from list-owned-cameras-v2
// the date of the lastSaved image is not showing properly here.
// I think that's because the lastSaved timestamp in the list-owned-cameras-v2 object
// and the timestamps in the list-all API do not match.  The ImagesDropdown component renders
// the proper dates.  Seems like the lastSaved property is not being updated in the db on each snap.
// I will leave that line of code in so you can see it on the page.  - JZ

// React doesn't like something to do with the new camera card map (check console)

import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import MeterVibeButton from './shared/buttons/MeterVibeButton';
import Moment from 'react-moment';
import {
  Box,
  useTheme,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Tooltip,
  LinearProgress,
  IconButton,
} from '@material-ui/core';

import LeakageDetailsModal from './modals/camera_modals/LeakageDetailsModal';
import ImageDetailsModal from './modals/camera_modals/ImageDetailsModal';
import MeterReadingsModal from './modals/camera_modals/MeterReadingsModal';
import EditCameraModal from './modals/camera_modals/EditCameraModal';

// utils
import moment from 'moment';
import 'moment-timezone';
import { toTitleCase } from '../utils/toTitleCase';

// icons
import WaterDropIcon from '@material-ui/icons/InvertColors';
import WaterIcon from '@material-ui/icons/Opacity';
import EyeIcon from '@material-ui/icons/VisibilityRounded';
import ThumbsUpIcon from '@material-ui/icons/ThumbUpRounded';
import EditIcon from '@material-ui/icons/Edit';

// services
import { getAllImagesListProc } from './../services/images.services';
import { getReportOnlyParams } from '../services/reports.services';
import { updateCameraCostPerUnit, getCameraParams } from '../services/cameras.services';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 6,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
  cardHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardHeaderTitle: {
    fontWeight: 700,
    color: '#01070F',
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '30px',
  },
  cardHeaderSubHeader: {
    fontWeight: 700,
    color: '#7A7A7A',
    fontSize: '14px',
    textAlign: 'left',
  },
  cardFooter: {
    borderTop: '1px solid #DBDBDB',
    backgroundColor: '#FAFAFA',
    padding: '20px',
  },
  actionsButton: {
    '&:focus': {
      outline: 'none',
    },
  },
  cardMiddleContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageListContainer: {
    maxWidth: '200px',
  },
  separator: {
    width: 25,
    padding: '10px',
    height: '100px',
    background: '#999',
  },
  deviceInfo: {
    borderLeft: '1px solid #DBDBDB',
  },
  statusHeaderRoot: {
    width: '100%',
    borderBottom: '1px solid #DBDBDB',
    textAlign: 'left',
    padding: '0 0 0 16px',

    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid #DBDBDB',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  deviceStatusHeader: {
    fontWeight: 700,
    color: '#01070F',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
    fontSize: '12px',
  },
  boldBlueText: {
    color: '#0272BC',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
  lastSavedDate: {
    background: '#E8F5E9',
    color: '#2E7D32',
    padding: '5px',
    border: '1px solid #A5D6A7',
    fontWeight: 700,
    fontSize: '12px',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
  iconCategoryText: {
    color: '#7A7A7A',
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'left',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
  iconStatusText: {
    color: '#363636',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'left',
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
  },
  iconGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconCircle: {
    background: '#F5F5F5',
    borderRadius: '50%',
    border: '1px solid #F5F5F5',
    width: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
  },
  iconTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const IconCircle = ({ icon: Icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconCircle}>
      <Icon
        fontSize="small"
        style={{
          color: '#0272BC',
        }}
      />
    </div>
  );
};

const IconGroup = ({ icon, categoryText, statusText }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconGroup}>
      <IconCircle icon={icon} />

      {categoryText && statusText && (
        <div className={classes.iconTextContainer}>
          <Box marginLeft={1}>
            <Typography className={classes.iconCategoryText} gutterBottom>
              {categoryText}
            </Typography>
            <Typography className={classes.iconStatusText}>
              {statusText}
            </Typography>
          </Box>
        </div>
      )}
    </div>
  );
};

//also include if at midnight
const checkIsAfterMidnight = (date) => {
  const time = moment(date, 'h:mm A');
  const midnight = moment('12:00am', 'h:mm A');

  const isAfterMidnight = time.isSameOrAfter(midnight);

  //console.log("is after midnight?", date, isAfterMidnight);
  return true;  //isAfterMidnight; // returns true or false
};

// function to convert 13 digit epoch into local time string of:
// hh:mm:ss am/pm mm/dd/yyyy  ex: 8:00:05 AM 10/12/2022
const epochToLocalTimeDateString = (epoch13digit) => {
  const d= new Date(parseInt(epoch13digit));  //data from dynamoDb is string
  //console.log("input typeof, and new Date:", (typeof epoch13digit), d);
  const formattedEpoch = d.toLocaleTimeString() + " " + d.toLocaleDateString();
  //console.log("epochToLocalTimeDateString>> epoch13digit:, formattedEpoch:", epoch13digit, formattedEpoch);
  return formattedEpoch;
};

const calculateMeterReadingMultiplied = (digits, reportMult) => {
  // take digits and calculate to meter reading
  // from how it's calculated in readings table

  const result = (Number(digits) * Number(reportMult)).toFixed(
    Math.max(0, -Math.log10(reportMult))
  ); //set number of decimal places

  return Number(result);
};

const getPreviousImageData = (currentReading, data) => {
  const currentReadingTimestamp = moment(currentReading?.timestampSaved);

  const previousReadings = data.filter((el) => {
    const isAfterMidnight = checkIsAfterMidnight(el?.timestampSaved);
    // check if difference in hours  is greater than 1 hour ago from the current reading,
    // check if the time is after midnight
    return (
      currentReadingTimestamp.diff(el?.timestampSaved, 'hours') > 1 &&
      isAfterMidnight
    );
  });

  const previousReading = previousReadings[0]; // array already sorted by time, so 0 index is the most recent previous

  // console.log(
  //   'curr:',
  //   currentReading.timestampSaved,
  //   currentReading.cameraId,
  //   'prev:',
  //   previousReading.timestampSaved,
  //   previousReading.cameraId
  // );

  return previousReading;
};

const CameraCard = ({ camera, onViewDetails, onViewReports }) => {
  const id = camera.cameraId;
  const location = camera.cameraLocation;

  const classes = useStyles();
  const [cameraData, setCameraData] = useState({}); //also retreive the properties of this camera
  const [imageData, setImageData] = useState({}); // one concatenated image
  const [previousImageData, setPreviousImageData] = useState({}); //
  const [reportInfo, setReportInfo] = useState({
    reportLabel: 'Gallons',
    reportMult: 0.01,
  }); // reportMult goes here

  const [unitsData, setUnitsData] = useState({
    costPerUnit: 0.01,
    unitCostUSD: '$0.01',
  });

  const [meterReadingsMultiplied, setMeterReadingsMultiplied] = useState({
    current: 0,
    previous: 0,
    difference: 0,
  });

  const isLeaking = false; // update to useState once we have something on back-end to tell us
  // const [isLeaking, setIsLeaking] = useState(false); // will return true if leakage, else not, need data from back-end.
  const [isLeakageModalOpen, setIsLeakageModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isReadingsModalOpen, setIsReadingsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isCameraDataLoaded, setCameraIsDataLoaded] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const getImageData = async () => {
      const responseData = await getAllImagesListProc(id);

      let currentReading = responseData.filter((el) => {
        return checkIsAfterMidnight(el?.timestampSaved);
      })[0];

      const previousReading = getPreviousImageData(
        currentReading,
        responseData
      );

      setImageData({
        cameraId: currentReading?.cameraId,
        timestampSaved: currentReading?.timestampSaved,
        lastSnapShot: currentReading?.SignedUrl,
        digits: currentReading?.digits,
      }); // get only first 5 images of deice

      setPreviousImageData({
        cameraId: previousReading?.cameraId,
        timestampSaved: previousReading?.timestampSaved,
        lastSnapShot: previousReading?.SignedUrl,
        digits: previousReading?.digits,
      });

      setIsDataLoaded(true);
    };

    const getReportInfo = async () => {
      const params = await getReportOnlyParams(id);

      const label = params?.units ?? 'Gallons(default)'; // doesn't exist in A101.
      const mult = params?.multiplier ?? 0.01; // doesn't exist in A101.

      setReportInfo({
        reportLabel: toTitleCase(label),
        reportMult: mult,
      });

      const { costPerUnit = 1 } = params;

      setUnitsData({
        costPerUnit,
        unitCostUSD: `$${costPerUnit.toFixed(3)}`,
      });
    };

    const getCameraData = async () => {
      const cameraData = await getCameraParams(id);

      setCameraData({  //get the properties for this camera from dynamoDB
        cameraId: cameraData?.cameraId,
        active: cameraData?.active,
        cameraLocation: cameraData?.cameraLocation,
        cameraName: cameraData?.cameraName,
        cameraNotes: cameraData?.cameraNotes,
        multiplier: cameraData?.multiplier,
        rotation: cameraData?.rot4,
        lastSavedTimestamp: cameraData?.lastSavedTimestamp,
        units: cameraData?.units,
      }); 
      setCameraIsDataLoaded(true);
    }

    getImageData();
    getReportInfo();
    getCameraData();
    console.log()
  }, [id]);  //react update when id (camera) changes

  useEffect(() => {
    let currentDigits = Number(imageData?.digits) ?? NaN;
    let previousDigits = Number(previousImageData?.digits) ?? NaN;
    // let initialDigitsDifference = currentDigits - previousDigits;

    const currentDigitsMultiplied = calculateMeterReadingMultiplied(
      currentDigits,
      reportInfo.reportMult
    );

    const previousDigitsMultiplied = calculateMeterReadingMultiplied(
      previousDigits,
      reportInfo.reportMult
    );

    // const differenceDigitsMultiplied = calculateMeterReadingMultiplied(
    //   initialDigitsDifference,
    //   reportInfo.reportMult
    // );

    const digitsDifference = Number(
      currentDigitsMultiplied - previousDigitsMultiplied
    );

    setMeterReadingsMultiplied({
      current: {
        number: currentDigitsMultiplied, // number without commas
        formatted: currentDigitsMultiplied.toLocaleString(), // number with commas
      },
      previous: {
        number: previousDigitsMultiplied,
        formatted: previousDigitsMultiplied.toLocaleString(),
      },
      difference: {
        number: digitsDifference,
        formatted: digitsDifference.toLocaleString(),
      },
    });
    // set meterReading when reportInfo.reportMult and imageData.digits changes (should be after api call)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInfo.reportMult, imageData?.digits, previousImageData?.digits]);

  // id = props.id

  const showLeakageModal = useCallback(
    () => setIsLeakageModalOpen(id),
    [setIsLeakageModalOpen, id]
  );
  const closeLeakageModal = useCallback(
    () => setIsLeakageModalOpen(false),
    [setIsLeakageModalOpen]
  );

  const showImageModal = useCallback(
    () => setIsImageModalOpen(id),
    [setIsImageModalOpen, id]
  );
  const closeImageModal = useCallback(
    () => setIsImageModalOpen(false),
    [setIsImageModalOpen]
  );

  const showReadingsModal = useCallback(
    () => setIsReadingsModalOpen(id),
    [id, setIsReadingsModalOpen]
  );
  const closeReadingsModal = useCallback(
    () => setIsReadingsModalOpen(false),
    [setIsReadingsModalOpen]
  );

  const showEditModal = useCallback(
    () => setIsEditModalOpen(id),
    [id, setIsEditModalOpen]
  );
  const closeEditModal = useCallback(
    () => setIsEditModalOpen(false),
    [setIsEditModalOpen]
  );

  const onSave = useCallback(
    async (userInput) => {
      const updatedCostPerUnit = await updateCameraCostPerUnit(id, userInput);

      setUnitsData((prevState) => ({
        ...prevState,
        costPerUnit: updatedCostPerUnit,
        unitCostUSD: `$${updatedCostPerUnit.toFixed(3)}`,
      }));

      setIsEditModalOpen(false);
    },
    [id]
  );

  //if last timestamp valid : subheader={dayjs(lastSaved).format("dddd, MMM DD, YYYY, hh:mm:ss A")}
  return (
    <>
      <Card className={classes.root}>
        <Grid
          container
          direction="row"
          wrap="wrap"
          justifyContent="space-between">
          {/* CARD LEFT */}
          <Grid item container direction="column" xs={12} md={8}>
            <CardContent>
              <div className={classes.cardHeaderLeft}>
                <CardHeader
                  title={location}
                  subheader={`Device code: ${id}`}
                  classes={{
                    root: classes.cardHeaderRoot,
                    title: classes.cardHeaderTitle,
                    subheader: classes.cardHeaderSubHeader,
                  }}
                />

                <div className={classes.cardHeaderRight}>
                  {isDataLoaded && isCameraDataLoaded && (
                    <Tooltip arrow placement="top" title="Edit">
                      <IconButton
                        onClick={showEditModal}
                        style={{ outline: 'none' }}>
                        <EditIcon
                          style={{ color: '#0272BC' }}
                          fontSize="large"
                          color="primary"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>

              {(isDataLoaded && isCameraDataLoaded) ? (
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  xs={12}
                  justifyContent="space-between"
                  style={{ marginLeft: '10px', padding: '5px' }}>
                  <Tooltip arrow title="View more" placement="top">
                    <Box
                      onClick={showReadingsModal}
                      style={{ cursor: 'pointer' }}>
                      <IconGroup
                        icon={WaterIcon}
                        categoryText={`Since ${moment(
                          previousImageData?.timestampSaved
                        ).format('hh:mm A MM/DD/yyyy')}`}
                        statusText={`${
                          // show no readings if meterReadingMultiplied return NaN
                          meterReadingsMultiplied.difference?.formatted ===
                          'NaN'
                            ? 'No readings'
                            : `${meterReadingsMultiplied.difference?.formatted} ${reportInfo.reportLabel} (${unitsData.unitCostUSD})`
                        }`}
                      />
                    </Box>
                  </Tooltip>
                  {/*
              <IconGroup
                icon={WaterIcon}
                categoryText="Water Status"
                statusText="Not running"
              /> */}

                  <Tooltip arrow title="View more" placement="top">
                    <Box
                      onClick={showLeakageModal}
                      style={{ cursor: 'pointer' }}>
                      <IconGroup
                        icon={ThumbsUpIcon}
                        categoryText="Leakage"
                        statusText={!isLeaking ? 'No flow detected' : 'issues'}
                      />
                    </Box>
                  </Tooltip>

                  {/* eye icon with images list */}

                  <Tooltip arrow title="View more" placement="top-start">
                    <div
                      onClick={showImageModal}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <IconGroup
                        icon={EyeIcon}
                        // Don't show digits if isNaN
                      />

                      <Box marginRight={1} />
                      <Grid item>
                        <div className={classes.imageListContainer}>
                          <img
                            src={imageData.lastSnapShot}
                            alt={imageData.cameraId}
                            width="100%"
                          />
                        </div>
                      </Grid>
                    </div>
                  </Tooltip>
                </Grid>
              ) : (
                <>
                  <LinearProgress />
                </>
              )}
            </CardContent>
          </Grid>

          {/* CARD RIGHT */}
          <Grid
            item
            container
            direction="row"
            xs={12}
            md={4}
            className={classes.deviceInfo}>
            <CardHeader
              classes={{
                root: classes.statusHeaderRoot,
                title: classes.deviceStatusHeader,
              }}
              title="Device status"
            />

            <Grid
              item
              container
              direction="row"
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: '16px' }}
              wrap="wrap">
              <Grid
                item
                xs={6}
                justifyContent={matchesSm ? 'center' : 'flex-start'}
                container
                direction="row"
                alignItems="center">
                <WaterDropIcon style={{ color: '#0272BC' }} />
                &nbsp;Latest picture at:
              </Grid>

              <Grid item container direction="row" alignItems="center" xs={6}>
                <Grid item className={classes.lastSavedDate}>
                    {epochToLocalTimeDateString (cameraData?.lastSavedTimestamp)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <CardActions classes={{ root: classes.cardFooter }}>
          <MeterVibeButton
            text="View details"
            textTransform="none"
            fontWeight={700}
            background="linear-gradient(90deg, rgba(2,114,188,1) 0%, rgba(1,83,136,1) 100%)"
            border="1px solid #0272BC"
            className={classes.actionsButton}
            onClick={onViewDetails}
          />

          <MeterVibeButton
            text="View reports"
            textTransform="none"
            fontWeight={700}
            backgroundColor="#fff"
            color="#015388"
            border="1px solid #0272BC"
            className={classes.actionsButton}
            onClick={onViewReports}
          />
        </CardActions>
      </Card>

      {isDataLoaded && isCameraDataLoaded && (
        <>
          <LeakageDetailsModal
            open={isLeakageModalOpen === id}
            onClose={closeLeakageModal}
            isLeaking={isLeaking}
          />

          <ImageDetailsModal
            open={isImageModalOpen === id}
            onClose={closeImageModal}
            imageData={imageData}
            label={reportInfo.reportLabel}
            mostRecentReading={meterReadingsMultiplied?.current?.formatted}
          />

          <EditCameraModal
            open={isEditModalOpen === id}
            onClose={closeEditModal}
            onSave={onSave}
            cameraData={{
              ...unitsData,
              ...reportInfo,
            }}
          />

          <MeterReadingsModal
            deviceDetails={camera}
            open={isReadingsModalOpen === id}
            onClose={closeReadingsModal}
            imageData={imageData}
            previousImageData={previousImageData}
            meterReadingsMultiplied={meterReadingsMultiplied}
            label={reportInfo.reportLabel}
            unitCostUSD={unitsData.unitCostUSD}
          />
        </>
      )}
    </>
  );
};

export default CameraCard;

export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "iotvid-images-dev"
  },
  //adding a second api gateway with lambda's from iotvid-improc service
  // in apigateway -> APIs -> API:dev-iotvid-app -> Settings -> Derault Endpoint, 
  // copy that default execute-api endpoint name and append /dev.
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://t2ydu8fzid.execute-api.us-east-1.amazonaws.com/dev",
    // URL_IMPROC: "https://r94pkske1e.execute-api.us-east-1.amazonaws.com/dev"
    URL_ML: "https://kwxghgs1yc.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_LlwfyXdYh",
    APP_CLIENT_ID: "1jlth39gv9h205vs3coluid435",
    IDENTITY_POOL_ID: "us-east-1:50d17485-815f-40ba-866c-c95fc994cdef"
  }
};

import React from 'react';

// components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Moment from 'react-moment';

import makeStyles from '@material-ui/core/styles/makeStyles';

const AlertsSection = ({ alerts, handleClearAlerts, openDetailsModal }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.alertsSectionHeader}>
        <Typography component="h1">Alerts</Typography>
      </Grid>

      <div className={classes.verticalSpacer} />

      {alerts.length ? (
        alerts.map((alert, key) => (
          // AlertRow defined in line 57
          <AlertRow
            key={key}
            alert={alert}
            openDetailsModal={openDetailsModal}
          />
        ))
      ) : (
        <Typography style={{ marginLeft: '20px' }} component="h1" variant="h5">
          No Alerts
        </Typography>
      )}

      <div className={classes.verticalSpacer} />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.alertsSectionFooter}
        component="footer">
        {alerts.length ? (
          <Typography
            component="button"
            onClick={handleClearAlerts}
            className={classes.alertsClearAllButton}>
            Clear all
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

const AlertRow = ({ alert, openDetailsModal }) => {
  const { severity, message, timestamp } = alert;

  const classes = useStyles({ alertSeverity: severity });

  return (
    <Grid container>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.alertRowItemsContainer}>
        <Grid item container direction="row" alignItems="center" xs={10}>
          <Grid item className={classes.alertRowSeverityCircle} />

          <Typography className={classes.alertRowMessage}>{message}</Typography>

          <Typography
            component="button"
            onClick={() => openDetailsModal(alert)}
            className={classes.viewDetailsButton}>
            View details
          </Typography>
        </Grid>

        <Grid item container direction="row" alignItems="center" xs={2}>
          <Moment
            className={classes.alertRowTimestamp}
            format="hh:mm A MM/DD/yyyy">
            {timestamp}
          </Moment>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ALERT_SEVERITIES = {
  info: '#3D93CC',
  success: '#5FB200',
  warning: '#FF9F00',
  danger: '#D0021B',
};

const buttonStyles = {
  border: 0,
  padding: 0,
  transition: 'all 250ms ease-in-out',
  background: 'none',
  cursor: 'pointer',
  color: '#0272BC',

  '&:hover': {
    transform: 'scale(1.01)',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:active': {
    color: '#00224B',
  },
};

const useStyles = makeStyles({
  alertsSectionHeader: {
    borderBottom: '1px solid #DBDBDB',
    padding: '10px 16px',

    '& > h1': {
      color: '#01070F',
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
    },
  },

  verticalSpacer: {
    display: 'flex',
    padding: '10px',
    flexGrow: 1,
  },

  alertsSectionFooter: {
    padding: '10px',
    backgroundColor: '#FAFAFA',
  },

  alertsClearAllButton: {
    ...buttonStyles,
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: ['Montserrat', 'Roboto', 'Sans-Serif'].join(','),
    border: 0,
    padding: 0,
    transition: 'all 250ms ease-in-out',
  },

  alertRowItemsContainer: {
    backgroundColor: '#FAFAFA',
    margin: '10px 20px',
    padding: '10px',
  },

  alertRowSeverityCircle: {
    backgroundColor: ({ alertSeverity }) => ALERT_SEVERITIES[alertSeverity],
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginRight: '10px',
  },

  alertRowMessage: {
    fontSize: '12px',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    color: '#363636',
    padding: 0,
    marginRight: '10px',
  },

  alertRowTimestamp: {
    fontSize: '12px',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
    color: '#363636',
  },

  viewDetailsButton: {
    ...buttonStyles,
    fontSize: '12px',
    fontFamily: ['Public Sans', 'Roboto', 'Sans-Serif'].join(','),
  },
});

export default React.memo(AlertsSection);
